import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    SafeAreaView,
    Animated,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const ChildcareScreen = props => {
    const childcareData = useSelector(state => state.potential.childcare);
    const childcareAmountData = useSelector(state => state.potential.childcareAmount);
    const [childcare, setChildcare] = useState(childcareData);
    const [childcareAmount, setChildcareAmount] = useState(childcareAmountData);
    const [childcareAmountValid, setChildcareAmountValid] = useState(childcareAmountData ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (childcare === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [childcare])

    const dispatch = useDispatch();

    const inputChangeHandler = async (id, value, valid) => {
        setChildcareAmount(value);
        setChildcareAmountValid(valid);
        dispatch(potentialActions.setChildcareAmount(value));
    };

    const selectionHandler = async (answer) => {
        setChildcare(answer);
        dispatch(potentialActions.setChildcare(answer));
        if (childcareData === '') {
            if (answer === false) {
                props.navigation.navigate('Result');
            }
        }
    };

    const doneHandler = () => {
        if (childcare === false || childcareAmountValid) {
            props.navigation.navigate('Result');
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <KeyboardAvoidingView behavior='position'>
                    <DismissKeyboard>
                        <View style={styles.questionContainer}>
                            <DefaultText style={styles.questionText}>Did you pay childcare for your child/children?</DefaultText>
                            <View style={styles.answerContainer}>
                                <View style={styles.yesNoContainer}>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandler(false)}
                                        style={childcare === false ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={childcare === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            No
                                </DefaultText>
                                    </TouchableItem>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandler(true)}
                                        style={childcare === true ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={childcare === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Yes
                            </DefaultText>
                                    </TouchableItem>
                                </View>
                                {childcare === true && (
                                    <Animated.View
                                        style={[
                                            styles.inputContainer,
                                            {
                                                opacity: fadeAnim // Bind opacity to animated value
                                            }
                                        ]}>
                                        <DefaultInputMaskedMoney
                                            focus={!(childcareAmountData > 0)}
                                            id='childcare'
                                            title="Childcare amount:"
                                            errorMessage="Please enter a valid amount"
                                            placeHolder="10'000"
                                            keyboardType='numeric'
                                            onInputChange={inputChangeHandler}
                                            initialValue={childcareAmount}
                                            initiallyValid={childcareAmountValid}
                                            decimal
                                            required
                                        />
                                    </Animated.View>
                                )}
                            </View>

                            <NavContainer
                                backShow={true}
                                nextShow={(childcare === false || childcareAmountValid === true)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                        </View>
                    </DismissKeyboard>
                </KeyboardAvoidingView>
            </ScrollView>
        </SafeAreaView>
    );
};

export const childcareScreenOptions = navigationData => {
    return {
        title: "Childcare",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1,
        marginTop: 20
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default ChildcareScreen;