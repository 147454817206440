import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    SafeAreaView,
    ActivityIndicator,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const MaritalSituationConfirmationScreen = props => {
    const maritalStatusData = useSelector(state => state.potential.maritalStatus);
    const partnerAbroadData = useSelector(state => state.potential.partnerAbroad);
    const partnerTaxStatusData = useSelector(state => state.potential.partnerTaxStatus);
    const [maritalStatus, setMaritalStatus] = useState(maritalStatusData);
    const [partnerAbroad, setPartnerAbroad] = useState(partnerAbroadData);
    const [partnerTaxStatus, setPartnerTaxStatus] = useState(partnerTaxStatusData);

    useEffect(() => {
        setMaritalStatus(maritalStatusData);
        setPartnerAbroad(partnerAbroadData);
        setPartnerTaxStatus(partnerTaxStatusData);
    }, [maritalStatusData])

    const dispatch = useDispatch();

    const maritalStatusSelectionHandler = async (answer) => {
        setMaritalStatus(answer);
        dispatch(potentialActions.setMaritalStatus(answer));
    };

    const partnerAbroadSelectionHandler = async (answer) => {
        setPartnerAbroad(answer);
        dispatch(potentialActions.setPartnerAbroad(answer));
    };

    const partnerTaxStatusSelectionHandler = async (answer) => {
        setPartnerTaxStatus(answer);
        dispatch(potentialActions.setPartnerTaxStatus(answer));
    };

    const sView = useRef();

    useEffect(() => {
        if (maritalStatus !== '')
        sView.current.scrollToEnd({ animated: true });
    })

    const doneHandler = () => {    
        if (partnerTaxStatus === 'fileTax' && maritalStatus === 'Married' && !partnerAbroad) {
            props.navigation.navigate('Result');
        } else {
            props.navigation.navigate('Children');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <KeyboardAvoidingView
                behavior='position'
                keyboardVerticalOffset={50}
            >
                <DismissKeyboard
                    >
                    <View style={styles.questionContainer}>
                        {maritalStatus === 'Single' ? 
                        <DefaultText style={styles.questionText}>Please select your marital status:</DefaultText>
                        :
                        <DefaultText style={styles.questionText}>Please select your marital status:</DefaultText>
                        }
                        <View style={styles.maritalStatusAnswerContainer}>
                            <View style={styles.answerContainerRow}>
                                <TouchableItem
                                    onSelectItem={maritalStatusSelectionHandler.bind(this, 'Single')}
                                    style={maritalStatus === 'Single' ? styles.activeAnswerShort : styles.passiveAnswerShort}
                                >
                                    <DefaultText style={maritalStatus === 'Single' ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Single
                            </DefaultText>
                                </TouchableItem>

                                <TouchableItem
                                    onSelectItem={maritalStatusSelectionHandler.bind(this, 'Married')}
                                    style={maritalStatus === 'Married' ? styles.activeAnswerShort : styles.passiveAnswerShort}
                                >
                                    <DefaultText
                                        style={maritalStatus === 'Married' ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Married
                            </DefaultText>
                                </TouchableItem>
                            </View>
                            <View style={styles.answerContainerRow}>
                                <TouchableItem
                                    onSelectItem={maritalStatusSelectionHandler.bind(this, 'Divorced')}
                                    style={maritalStatus === 'Divorced' ? styles.activeAnswerShort : styles.passiveAnswerShort}
                                >
                                    <DefaultText
                                        style={maritalStatus === 'Divorced' ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Divorced
                            </DefaultText>
                                </TouchableItem>

                                <TouchableItem
                                    onSelectItem={maritalStatusSelectionHandler.bind(this, 'Widowed')}
                                    style={maritalStatus === 'Widowed' ? styles.activeAnswerShort : styles.passiveAnswerShort}
                                >
                                    <DefaultText
                                        style={maritalStatus === 'Widowed' ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Widowed
                            </DefaultText>
                                </TouchableItem>
                            </View>
                        </View>

                        {maritalStatus === 'Married' && (
                            <View>
                                <View style={styles.yesNoContainer}>
                                    <TouchableItem
                                        onSelectItem={() => partnerAbroadSelectionHandler(false)}
                                        style={partnerAbroad === false ? styles.activeAnswerLong : styles.passiveAnswerLong}
                                    >
                                        <DefaultText
                                            style={partnerAbroad === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            My Partner lives in Switzerland
                                            </DefaultText>
                                    </TouchableItem>
                                    <TouchableItem
                                        onSelectItem={() => partnerAbroadSelectionHandler(true)}
                                        style={partnerAbroad === true ? styles.activeAnswerLong : styles.passiveAnswerLong}
                                    >
                                        <DefaultText
                                            style={partnerAbroad === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            My Partner lives abroad (not in CH)
                                        </DefaultText>
                                    </TouchableItem>
                                </View>

                                {partnerAbroad === false &&  (
                                    <View style={styles.yesNoContainer}>
                                        <TouchableItem
                                            onSelectItem={() => partnerTaxStatusSelectionHandler('fileTax')}
                                            style={partnerTaxStatus === 'fileTax' ? styles.activeAnswerLong : styles.passiveAnswerLong}
                                        >
                                            <DefaultText
                                                style={partnerTaxStatus === 'fileTax' ? styles.activeAnswerText : styles.passiveAnswerText}
                                            >
                                                My Partner is Swiss or C permit
                                            </DefaultText>
                                        </TouchableItem>
                                        <TouchableItem
                                            onSelectItem={() => partnerTaxStatusSelectionHandler('notFileTax')}
                                            style={partnerTaxStatus === 'notFileTax' ? styles.activeAnswerLong : styles.passiveAnswerLong}
                                        >
                                            <DefaultText
                                                style={partnerTaxStatus === 'notFileTax' ? styles.activeAnswerText : styles.passiveAnswerText}
                                            >
                                                My Partner is B or L permit
                                        </DefaultText>
                                        </TouchableItem>
                                    </View>
                                )}
                            </View>
                        )}

                            <NavContainer
                                backShow={true}
                                nextShow={((maritalStatus !== 'Married' && maritalStatus!== '') || 
                                (maritalStatus === 'Married' && partnerAbroad) ||
                                (maritalStatus === 'Married' && !partnerAbroad && partnerTaxStatus !== ''))}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
        </SafeAreaView>
    );
};

export const maritalSituationConfirmationScreenOptions = navigationData => {
    return {
        title: "Marital Situation",
        headerLeft: () => 
                null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        justifyContent: 'center'
    },    
    inputContainer: {
        flex: 1
    },
    maritalStatusAnswerContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 10,
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        marginBottom: 20,
        padding: 10
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 200,
        padding: 10,
    },
    passiveAnswerShort: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        // minWidth: 140,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    activeAnswerShort: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        // minWidth: 150,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },

    passiveAnswerLong: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    activeAnswerLong: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },

    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default MaritalSituationConfirmationScreen;