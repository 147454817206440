import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    KeyboardAvoidingView,
    Dimensions,
    SafeAreaView,
    Platform,
    Animated
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import DismissKeyboard from '../../../components/DismissKeyboard';
import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import NavContainer from '../../../components/NavContainer';

import * as potentialActions from '../../../store/actions/potential';

const PartnerIncomeTaxedAtSourceScreen = props => {
    const partnerHasIncomeData = useSelector(state => state.potential.partnerHasIncome);
    const partnerCashFromTasIncomesData = useSelector(state => state.potential.partnerCashFromTasIncomes);
    const userCashFromTasIncomesData = useSelector(state => state.potential.cashFromTasIncomes);
    const partnerHasVarianceData = useSelector(state => state.potential.partnerHasVariance);
    const [partnerHasVariance, setPartnerHasVariance] = useState(partnerHasVarianceData);
    const [partnerHasIncome, setPartnerHasIncome] = useState(partnerHasIncomeData);
    const [partnerCashFromTasIncomes, setPartnerCashFromTasIncomes] = useState(partnerCashFromTasIncomesData);
    const [partnerCashFromTasIncomesValid, setPartnerCashFromTasIncomesValid] = useState(partnerCashFromTasIncomesData ? true : false);
    const [totalAmount, setTotalAmount] = useState(0);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (partnerHasIncome === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [partnerHasIncome])

    const dispatch = useDispatch();

    const partnerHasVarianceSelectionHandler = async (answer) => {
        setPartnerHasVariance(answer);
        dispatch(potentialActions.setPartnerHasVariance(answer));
    };
    
    const partnerHasIncomeSelectionHandler = async (answer) => {
        setPartnerHasIncome(answer);
        dispatch(potentialActions.setPartnerHasIncome(answer));
    };

    const inputChangeHandler = async (id, value, valid) => {
        setPartnerCashFromTasIncomes(value);
        setPartnerCashFromTasIncomesValid(valid);          
        setTotalAmount(Number(userCashFromTasIncomesData) + Number(value));
        dispatch(potentialActions.setPartnerCashFromTasIncomes(value));
    };

    const doneHandler = () => {
        if (partnerCashFromTasIncomes > 8000 || totalAmount > 8000) {
            props.navigation.navigate('Result');
        } else {
            props.navigation.navigate('OtherIncome');
        }
    };

    // const cashOptions = cashOptionsText;

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <KeyboardAvoidingView
                    behavior='position'
                    keyboardVerticalOffset={50}
                >
                    <DismissKeyboard>
                        <View style={styles.questionContainer}>
                            <View style={styles.questionSubContainer}>
                                <DefaultText style={styles.questionText}>Did your partner receive employment income in Switzerland?</DefaultText>
                                <DefaultText style={styles.note}>This is income your partner paid tax at source on: employment and daily allowances paid through your employer or unemployment.</DefaultText>
                            </View>
                            
                            <View style={styles.yesNoContainer}>
                                <TouchableItem
                                    onSelectItem={() => partnerHasIncomeSelectionHandler(true)}
                                    style={partnerHasIncome === true ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={partnerHasIncome === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        My partner had employment related income
                                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    onSelectItem={() => partnerHasIncomeSelectionHandler(false)}
                                    style={partnerHasIncome === false ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={partnerHasIncome === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        My partner had no employment related income
                                        </DefaultText>
                                </TouchableItem>
                            </View>

                            {partnerHasIncome === true && (
                                <Animated.View
                                    style={[
                                        styles.inputContainer,
                                        {
                                            opacity: fadeAnim
                                        }
                                    ]}>

                                <View style={styles.yesNoContainer}>
                                <TouchableItem
                                    onSelectItem={() => partnerHasVarianceSelectionHandler(true)}
                                    style={partnerHasVariance === true ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={partnerHasVariance === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Yes, some month were significantly higher than others
                                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    onSelectItem={() => partnerHasVarianceSelectionHandler(false)}
                                    style={partnerHasVariance === false ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={partnerHasVariance === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        No, my partner's salary was more or less the same over the months
                                        </DefaultText>
                                </TouchableItem>
                            </View>


                                    <DefaultInputMaskedMoney
                                        focus={!(partnerCashFromTasIncomes > 0)}
                                        id='cashFromTas'
                                        title="What is the average MONTHLY cash amount received (after deduction of all social contributions):"
                                        errorMessage="Please enter a valid amount"
                                        placeHolder="6'500"
                                        keyboardType='numeric'
                                        onInputChange={inputChangeHandler}
                                        initialValue={partnerCashFromTasIncomes}
                                        initiallyValid={partnerCashFromTasIncomesValid}
                                        decimal
                                        required
                                    />
                                </Animated.View>
                            )}

                            <NavContainer
                                backShow={true}
                                nextShow={partnerHasIncome === false || (partnerCashFromTasIncomes > 0 && partnerHasVariance !== '')}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                        </View>
                    </DismissKeyboard>
                </KeyboardAvoidingView >
            </ScrollView >
        </SafeAreaView>
    );
};

export const partnerIncomeTaxedAtSourceScreenOptions = navigationData => {
    return {
        title: "Partner's Income Taxed At Source",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1
    },
    maritalStatusAnswerContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 10,
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        marginBottom: 20,
        padding: 10
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    questionSubContainer: {
        marginBottom: 40,
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 200,
        padding: 10,
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default PartnerIncomeTaxedAtSourceScreen;