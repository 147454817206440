import { 
    AUTHENTICATE,
    LOGOUT,
    SET_DID_TRY_AL,
} from '../actions/auth';

const initialState = {
    token: null,
    userId: null,
    didTryAutoLogin: false,
    email: ''
};

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case AUTHENTICATE:
            return {
                token: action.token,
                userId: action.userId,
                didTryAutoLogin: true,
                email: action.email,
            };
        case SET_DID_TRY_AL:
            return {
                ...state,
                didTryAutoLogin: action.trueFalse
            };
        case LOGOUT:
            return {
                token: null,
                userId: null,
                didTryAutoLogin: true,
                email: ''
                };
        default:
            return state;
    return state;
    }
};


export default authReducer;