export const cantons = [
        " ",
        "Aargau",
        "Appenzell Ausserrhoden",
        "Appenzell Innerrhoden",
        "Basel-Landschaft",
        "Basel-Stadt",
        "Bern / Berne",
        "Fribourg / Freiburg",
        "Genève",
        "Glarus",
        "Graubünden / Grigioni / Grischun",
        "Jura",
        "Luzern",
        "Neuchâtel",
        "Nidwalden",
        "Obwalden",
        "Schaffhausen",
        "Schwyz",
        "Solothurn",
        "St. Gallen",
        "Thurgau",
        "Ticino",
        "Uri",
        "Valais / Wallis",
        "Vaud",
        "Zug",
        "Zürich"
];

export const availableCantons = [
        "Zürich",
        "Other Swiss Canton"
];
