import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    View,
    StyleSheet,
    ActivityIndicator
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Colors from '../constants/Colors';

import * as authActions from '../store/actions/auth';

const StartScreen = props => {

    const dispatch = useDispatch();

    useEffect(() => {
        const tryLogin = async () => {
            const userData = await AsyncStorage.getItem('userData');
            if(!userData) {
                dispatch(authActions.setDidTryAl(true));
            //    props.navigation.navigate('Auth');
                return; 
            }

            const transformedData = JSON.parse(userData);
            const { token, userId, expiryDate } = transformedData;

            const expirationDate = new Date(expiryDate)

            if( expirationDate <= new Date() || !token || !userId ) {
                dispatch(authActions.setDidTryAl(true));
                return; 
            }

            const expirationTime = expirationDate.getTime() - new Date().getTime;

            // props.navigation.navigate('Menu');
            dispatch(authActions.authenticate(token, userId, expirationTime));
        };


        tryLogin();
    }, []);

    return (
        <View style={styles.screen}>
            <ActivityIndicator size="large" color={Colors.primaryColor} />
        </View>
    );
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
});

export default StartScreen;