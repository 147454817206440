import React, { useState, useEffect } from 'react';
import { StyleSheet, FlatList, View, Image, ScrollView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import HeaderButton from '../../../components/HeaderButton';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import TitleLogo from '../../../components/TitleLogo';

import * as relevancyActions from '../../../store/actions/relevancy';

const IncomeAbroadTypesScreen = props => {
    const incomAbroadTypesData = useSelector(state => state.relevancy.incomeAbroadTypes);
    const [incomAbroadTypes, setIncomAbroadTypes] = useState(incomAbroadTypesData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        let newAnswers = [...incomAbroadTypes];
        if (newAnswers.includes(answer)) {
            newAnswers = newAnswers.filter(a => a !== answer)
        } else {
            newAnswers.push(answer);
        }
        setIncomAbroadTypes(newAnswers);
        await dispatch(relevancyActions.setIncomeAbroadTypes(newAnswers));
    };

    const doneHandler = () => {
            props.navigation.navigate('MaritalStatus');
    };

    useEffect(() => {
        if (incomAbroadTypes.length > 0) {
        props.navigation.setOptions({
            headerRight: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-forward' onPress={doneHandler} />
                </HeaderButtons>
                
        });
    }
    }, [doneHandler]);
    
    return (
        <ScrollView style={styles.screen}>
                <View style={styles.questionContainer}>
                    <DefaultText
                        style={styles.questionText}
                    >
                        What kind of foreign income did you receive?
                        Select all that apply!
            </DefaultText>
                    <View style={styles.answerContainer}>
                        <TouchableItem 
                            onSelectItem={selectionHandler.bind(this, 'Wage')}
                            style={incomAbroadTypes.includes('Wage') ? styles.activeAnswer : styles.passiveAnswer}
                            >
                            <DefaultText
                                style={incomAbroadTypes.includes('Wage') ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Wage
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem 
                            style={incomAbroadTypes.includes('Self-employed income') ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, 'Self-employed income')}>
                            <DefaultText
                                style={incomAbroadTypes.includes('Self-employed income') ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Self-employed income
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem 
                            style={incomAbroadTypes.includes('Dividend and rent') ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, 'Dividend and rent')}>
                            <DefaultText
                                style={incomAbroadTypes.includes('Dividend and rent') ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Dividend and rent
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem 
                            style={incomAbroadTypes.includes('Pension') ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, 'Pension')}>
                            <DefaultText
                                style={incomAbroadTypes.includes('Pension') ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Pension
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem 
                            style={incomAbroadTypes.includes('Unemployment') ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, 'Unemployment')}>
                            <DefaultText
                                style={incomAbroadTypes.includes('Unemployment') ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Unemployment
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem 
                                style={incomAbroadTypes.includes('Social, insurance, motherhood, kids') ? styles.activeAnswer : styles.passiveAnswer}
                                onSelectItem={selectionHandler.bind(this, 'Social, insurance, motherhood, kids')}>
                            <DefaultText
                                style={incomAbroadTypes.includes('Social, insurance, motherhood, kids') ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Social, insurance, motherhood, kids
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem 
                                onSelectItem={selectionHandler.bind(this, 'Other')}
                                style={incomAbroadTypes.includes('Other') ? styles.activeAnswer : styles.passiveAnswer}
                                >
                            <DefaultText
                                style={incomAbroadTypes.includes('Other') ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Other
                            </DefaultText>
                        </TouchableItem>

                    </View>
                </View>
        </ScrollView>
    );
};

export const incomeAbroadTypesScreenOptions = navigationData => {
    return {
        headerLeft: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                        navigationData.navigation.goBack();
                    }} />
                </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
    },
    questionContainer: {
        flex: 1,
        justifyContent: 'center',
        padding: 10
    },  
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: '100%',
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        marginVertical: 10,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        marginVertical: 10,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default IncomeAbroadTypesScreen;