import React from 'react';
import { Image } from 'react-native';

const LogoTitle = props => {
  return (
    <Image
      style={{ width: 30, height: 30 }}
      source={require('../assets/Logo256.png')}
    />
  );
}

export default LogoTitle;
