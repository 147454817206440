import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as potentialActions from '../../../store/actions/potential';

const UserPermitScreen = props => {
    const userPermitData = useSelector(state => state.potential.userPermit);
    const [userPermit, setUserPermit] = useState(userPermitData);

    const dispatch = useDispatch();

    const sView = useRef();

    const selectionHandler = async (answer) => {
        setUserPermit(answer);
        dispatch(potentialActions.setUserPermit(answer));
        if (userPermitData === '') {
            if ( answer === 'BorL' ) {
            props.navigation.navigate('MaritalStatusConfirmation');
        } else {
            props.navigation.navigate('Result');
        }
    }
    };

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    }, [userPermit])

    const doneHandler = () => {
        if ( userPermit === 'BorL' ) {
            props.navigation.navigate('MaritalStatusConfirmation');
        } else {
            props.navigation.navigate('Result');
        }
    };

    useEffect(() => {
        props.navigation.setOptions({
            title: "Your Residence Status",
            headerLeft: () =>
                null
        });
    });


    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>Were you B or L permit the whole year of 2020 ?</DefaultText>
                    <View style={styles.answerContainer}>
                        <TouchableItem
                            style={userPermit === 'CorSwiss' ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, 'CorSwiss')}>
                            <DefaultText style={userPermit === 'CorSwiss' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                No, I became C permit holder or Swiss citizen
                    </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            style={userPermit === 'BorL' ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, 'BorL')}
                        >
                            <DefaultText
                                style={userPermit === 'BorL' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Yes, I was B or L permit throughout 2020
                        </DefaultText>
                        </TouchableItem>
                    </View>

                    <NavContainer
                        backShow={true}
                        nextShow={userPermit !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const userPermitScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    dropDownContainer: {
        marginTop: 20
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        padding: 10
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
        textAlign: 'center'
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
        textAlign: 'center'
    },

});

export default UserPermitScreen;