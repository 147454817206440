import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    KeyboardAvoidingView,
    Platform,
    TouchableWithoutFeedback,
    Keyboard,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Animated,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const WorkTransportScreen = props => {
    const transportTypesData = useSelector(state => state.potential.transportTypes);
    const vehicleAmountData = useSelector(state => state.potential.vehicleAmount);
    const [transportTypes, setTransportTypes] = useState(transportTypesData ? transportTypesData : []);
    const [vehicleAmount, setVehicleAmount] = useState(vehicleAmountData);
    const [vehicleAmountValid, setVehicleAmountValid] = useState(vehicleAmountData ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (transportTypes.includes('vehicle')) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [transportTypes])


    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        let newAnswers = [...transportTypes];
        if (newAnswers.includes(answer)) {
            newAnswers = newAnswers.filter(a => a !== answer)
        } else {
            newAnswers.push(answer);
        }
        setTransportTypes(newAnswers);
        await dispatch(potentialActions.setTransportTypes(newAnswers));
    };

    const sView = useRef();

    useEffect(() => {
        if (Dimensions.get('window').height > 500) {
            sView.current.scrollToEnd({ animated: true });
        }
    })

    const inputChangeHandler = async (id, value, valid) => {
        setVehicleAmount(value);
        setVehicleAmountValid(valid);
        dispatch(potentialActions.setVehicleAmount(value));
    };

    const doneHandler = () => {
        if (!transportTypes.includes('vehicle') || vehicleAmountValid) {
            props.navigation.navigate('WeekStaysForWork');
        }
    };


    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <KeyboardAvoidingView behavior='position'>
                    <DismissKeyboard
                    >
                        <View style={styles.questionContainer} >
                            <DefaultText
                                style={styles.questionText}
                            >
                                How did you go to work?
                                Select all substantial transport means that apply!
                    </DefaultText>
                            <View style={styles.answerContainer}>
                                <TouchableItem
                                    onSelectItem={selectionHandler.bind(this, 'public')}
                                    style={transportTypes.includes('public') ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={transportTypes.includes('public') ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Public transport
                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    style={transportTypes.includes('vehicle') ? styles.activeAnswer : styles.passiveAnswer}
                                    onSelectItem={selectionHandler.bind(this, 'vehicle')}>
                                    <DefaultText
                                        style={transportTypes.includes('vehicle') ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Personal vehicle
                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    style={transportTypes.includes('bike') ? styles.activeAnswer : styles.passiveAnswer}
                                    onSelectItem={selectionHandler.bind(this, 'bike')}>
                                    <DefaultText
                                        style={transportTypes.includes('bike') ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Bike
                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    style={transportTypes.includes('walk') ? styles.activeAnswer : styles.passiveAnswer}
                                    onSelectItem={selectionHandler.bind(this, 'walk')}>
                                    <DefaultText
                                        style={transportTypes.includes('walk') ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Walking
                            </DefaultText>
                                </TouchableItem>
                                {transportTypes.includes('vehicle') && (
                                    <Animated.View
                                        style={[
                                            styles.inputContainer,
                                            {
                                                opacity: fadeAnim // Bind opacity to animated value
                                            }
                                        ]}>
                                        <DefaultInputMaskedMoney
                                            focus={!(vehicleAmountData > 0)}
                                            id='vehicleAmountCost'
                                            title="Vehicle cost amount:"
                                            errorMessage="Please enter a valid amount"
                                            placeHolder="10'000"
                                            keyboardType='numeric'
                                            onInputChange={inputChangeHandler}
                                            initialValue={vehicleAmount}
                                            initiallyValid={vehicleAmountValid}
                                            decimal
                                            required
                                        />
                                    </Animated.View>
                                )}
                            </View>

                            <NavContainer
                                backShow={true}
                                nextShow={(transportTypes.length !== 0 && !transportTypes.includes('vehicle') || vehicleAmountValid === true)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                        </View>
                    </DismissKeyboard>
                </KeyboardAvoidingView>
            </ScrollView>
        </SafeAreaView>
    );
};

export const workTransportScreenOptions = navigationData => {
    return {
        title: "Transport To Work",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: '100%',
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        marginVertical: 10,
        minHeight: 50,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        marginVertical: 10,
        minHeight: 50,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default WorkTransportScreen;