import React, { useState, useReducer, useCallback, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    Platform,
    ScrollView,
    Dimensions,
    ActivityIndicator,
    SafeAreaView,
    Image,
    Alert,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as WebBrowser from 'expo-web-browser';
import axios from 'axios';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import DefaultIcon from '../../../components/DefaultIcon';

import * as potentialActions from '../../../store/actions/potential';
import * as userActions from '../../../store/actions/user';
import * as paymentActions from '../../../store/actions/payment';

const PotentialResultScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [error, setError] = useState();
    const [hasPreOrdered, setHasPreOrdered] = useState();
    const [disclaimerText, setDisclaimerText] = useState();
    const [hasPotential, setHasPotential] = useState();
    const [shouldDeclare, setShouldDeclare] = useState();
    const [shouldDeclareText, setShouldDeclareText] = useState();
    const [isCovered, setIsCovered] = useState();

    const dispatch = useDispatch();

    const userEmail = useSelector(state => state.auth.email);
    const userId = useSelector(state => state.auth.userId);
    const userOrders = useSelector(state => state.user.userOrders);
    const userPaidOrders = userOrders.length > 0 ? userOrders.filter(uo => uo.status === 'paid') : null;
    const userPotentialDone = useSelector(state => state.user.potentialDone);
    const potential = useSelector(state => state.potential.potential);
    const potentialData = useSelector(state => state.potential);
    const disclaimers = useSelector(state => state.potential.disclaimers);
    const sView = useRef();
    const preOrderPrice = useSelector(state => state.payment.preOrderPrice);
    const reductionAmount = useSelector(state => state.payment.reductionAmount);
    const normalPrice = useSelector(state => state.payment.normalPrice);

    useEffect(() => {
        if (!isLoading && potential !== '') {
            sView.current.scrollToEnd({ animated: true });
        }
    }, [isLoading, potential]);

    useEffect(() => {
        if (!userPaidOrders) {
            setHasPreOrdered(false);
        } else if (userPaidOrders.length > 0) {
            setHasPreOrdered(true)
        } else {
            setHasPreOrdered(false)
        }
    }, [userPaidOrders])

    const loadData = useCallback(async () => {
        setError(null);
        setIsRefreshing(true);
        try {
            dispatch(paymentActions.getPreOrderPrice());
            await dispatch(potentialActions.setPotential());
        } catch (err) {
            setError(err.message)
        }
        setIsRefreshing(false)
    }, [dispatch, setIsRefreshing, setError]);

    useEffect(() => {
        setIsLoading(true);
        loadData().then(() => {
            setIsLoading(false);
        })
    }, [dispatch, loadData]);

    useEffect(() => {
        const unsubscribe = props.navigation.addListener('focus', loadData);
        return () => {
            unsubscribe(); //cleans the listener
        };
    }, [loadData]);

    const preOrderHandler = async (type) => {
        setIsLoading(true);
        let descText = 'Your reclaim service pre-order for 2020';
        if (type === 'advice') {
            descText = 'Your advice call with the Taxdone team';
        } else if (type === 'declaration') {
            descText = 'Your situation review call with the Taxdone team';
        } else if (type === 'notCovered') {
            descText = 'Your Swiss tax situation review call with the Taxdone team';
        }

        const res = await axios.post(`https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/checkout`, {
            items: [{ name: 'pre-order', quantity: 1 }],
            platform: Platform.OS,
            email: userEmail,
            user: userId,
            description: descText,
            year: '2020',
            preOrder: true,
            currency: 'chf',
            type: type
        });

        if (Platform.OS === 'web') {

            let result = await WebBrowser.openAuthSessionAsync(`https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/web/checkout/redirect?sessionId=${res.data.sessionId}`);

            if (result.type === 'dismiss') {
                setIsLoading(false);
            }
        } else {
            props.navigation.navigate('Checkout', { sessionId: res.data.sessionId, orderId: res.data.orderId });
            setIsLoading(false);
        }
    };

    useEffect(() => {
        dispatch(userActions.savePotentialData(true, potential));
    }, [potential]);

    const doneHandler = () => {
        if (userPotentialDone === true) {
            props.navigation.navigate('TasOverview');
            dispatch(userActions.setNewUserPotentialInfo(true, potential));
        } else {
            props.navigation.navigate('Loading');
            dispatch(userActions.setNewUserPotentialInfo(true, potential));
        }
    };

    useEffect(() => {
        if (hasPotential) {
            if (disclaimers.includes('SalaryVariance')) {
                setDisclaimerText('This does not account for salary variability. Your potential could be even higher!')
            } 
            // else if (disclaimers.includes('TimeAbroad')) {
            //     setDisclaimerText('This does not account for rate adjustments based on salary received abroad.')
            // } else if (disclaimers.includes('TimeAbroad') && disclaimers.includes('SalaryVariance')) {
            //     setDisclaimerText('This does not account for rate adjustments based on salary received abroad or salary variability. Your potential could be even higher!')
            // } 
            else {
                setDisclaimerText('')
            }
        }
        // if ( disclaimers.includes('shouldDeclare') ) {
        //     disclaimerText = 'You seem to have refund potential. However given your situation you should probably declare to the tax authorities and fill a standard tax declaration. This assumes accurate answers and no error made by your employer regarding taxation rate.';
        // }

    }, [disclaimers, potential])

    const disclaimerDefaultText = 'Please note: This assumes accurate answers and no error made by your employer regarding your taxation rate. The information on this page is meant for information purposes only. No responsibility is taken for the correctness of this information.'

    const cantonNotCoveredText = 'In the meantime, we can still help you with you tax situation as our services cover all of Switzerland !'

    useEffect(() => {
        if ((Math.round(potential / 100, 0) - 1) > 1) {
            setHasPotential(true);
            setShouldDeclareText('You may have refund potential. However given your situation you should probably declare to the tax authorities and fill a standard tax declaration.');
        } else {
            setHasPotential(false);
            setShouldDeclareText('You may have refund potential. However given your situation you should probably declare to the tax authorities and fill a standard tax declaration.');
        }
    }, [potential])

    useEffect(() => {
        if (disclaimers.includes('shouldDeclare')) {
            setShouldDeclare(true);
        } else {
            setShouldDeclare(false);
        }
        if (disclaimers.includes('CantonNotCovered')) {
            setIsCovered(false);
        } else {
            setIsCovered(true);
        }
    }, [disclaimers, potential])

    useEffect(() => {
        if (error) {
            Alert.alert('An issue has occured', error, [{ text: 'ok' }]);
        }
    }, [error]);

    if (isLoading || potential === '') {
        return (
            <View style={styles.loading}>
                <ActivityIndicator size='large' color={Colors.primaryColor} />
            </View>
        );
    }

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.mainContainer}>

                    <TouchableItem
                        style={styles.restartContainer}
                        onSelectItem={() => {
                            props.navigation.navigate('Canton');
                        }}>
                        <DefaultIcon name="ios-arrow-back" size={30} color={Colors.darkBlue} />
                        <DefaultText style={styles.switchText}> Restart assessment</DefaultText>
                    </TouchableItem>

                    {hasPotential && !shouldDeclare && isCovered && (
                        <View style={styles.infoContainer}>
                            <DefaultText style={styles.estimateText}>We estimate a potential tax refund over:</DefaultText>
                            <View style={styles.resultContainer}>
                                <Image
                                    style={{ width: 70, height: 70 }}
                                    source={require('../../../assets/successImage.png')} />
                                <DefaultText style={styles.resultAmount}>{"CHF " + (Math.round(potential / 100, 0) - 1) * 100 + ".-"}</DefaultText>
                            </View>
                            <DefaultText style={styles.explanationText1}>{disclaimerText}</DefaultText>
                            {/* <DefaultText style={styles.explanationText2}>{disclaimerDefaultText}</DefaultText> */}
                            {/* <DefaultText style={styles.explanationText3}>{disclaimerDefaultText}</DefaultText> */}
                        </View>
                    )}

                    {shouldDeclare && isCovered && (
                        <View style={styles.infoContainer}>
                            <DefaultText style={styles.estimateText2}>It seems you should fill in a standard declaration</DefaultText>
                            <DefaultText style={styles.explanationText1}>{shouldDeclareText}</DefaultText>
                            {/* <DefaultText style={styles.explanationText2}>{disclaimerDefaultText}</DefaultText> */}
                            {/* <DefaultText style={styles.explanationText3}>{disclaimerDefaultText}</DefaultText> */}
                        </View>
                    )}

                    {!isCovered && (
                        <View style={styles.infoContainer}>
                            <DefaultText style={styles.estimateText2}>Your canton is not yet covered !</DefaultText>
                            <DefaultText style={styles.explanationText1}>{cantonNotCoveredText}</DefaultText>
                            {/* <DefaultText style={styles.explanationText2}>{disclaimerDefaultText}</DefaultText> */}
                            {/* <DefaultText style={styles.explanationText3}>{disclaimerDefaultText}</DefaultText> */}
                        </View>
                    )}

                    {!shouldDeclare && !hasPotential && isCovered && (
                        <View style={styles.infoContainer}>
                            <DefaultText style={styles.estimateText2}>We did not find significant reclaim potential.</DefaultText>
                            <DefaultText style={styles.explanationText1}>However, the year is not over!</DefaultText>
                            {/* <DefaultText style={styles.explanationText2}>{disclaimerDefaultText}</DefaultText> */}
                            {/* <DefaultText style={styles.explanationText3}>{disclaimerDefaultText}</DefaultText> */}
                        </View>
                    )}

                    {!hasPreOrdered ?
                        <View>
                            {hasPotential && !shouldDeclare && isCovered && (
                                <View>
                                    <TouchableItem
                                        style={styles.actionButton}
                                        onSelectItem={() => { preOrderHandler('preOrder') }}>
                                        <DefaultText
                                            style={styles.actionTitle} >
                                            Pre-order now our refund service for 2021:
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - Stop missing deadlines with our reminders.
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - We assist you in the refund reclaim
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - Get it for CHF {reductionAmount}.- off if you pre-order now.
                                </DefaultText>
                                    </TouchableItem>
                                </View>
                            )}

                            {shouldDeclare && isCovered && (
                                <View>
                                    <TouchableItem
                                        style={styles.actionButton}
                                        onSelectItem={() => { 
                                            preOrderHandler('declaration') }}>
                                        <DefaultText
                                            style={styles.actionTitle} >
                                            Get a call with our expert to discuss your situation:
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - Stop missing deadlines with our reminders.
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - Get it for CHF {reductionAmount}.- off if you pre-order now.
                                </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - Refunded if you complete your declaration with Taxdone !
                                    </DefaultText>
                                    </TouchableItem>
                                </View>
                            )}

                        {!isCovered && (
                        <View>
                        <TouchableItem
                            style={styles.actionButton}
                            onSelectItem={() => { 
                                preOrderHandler('notCovered') }}>
                            <DefaultText
                                style={styles.actionTitle} >
                                Get a call with our expert to discuss your situation:
                        </DefaultText>
                            <DefaultText
                                style={styles.actionArgument} >
                                - Stop missing deadlines with our reminders.
                        </DefaultText>
                            <DefaultText
                                style={styles.actionArgumentBold} >
                                - Get it for CHF {reductionAmount}.- off if you pre-order now.
                    </DefaultText>
                            <DefaultText
                                style={styles.actionArgumentBold} >
                                - Refunded if you complete your declaration with Taxdone !
                        </DefaultText>
                        </TouchableItem>
                    </View>
                    )}

                            {!shouldDeclare && !hasPotential && isCovered && (
                                <View>
                                    <TouchableItem
                                        style={styles.actionButton}
                                        onSelectItem={() => { preOrderHandler('advice') }}>
                                        <DefaultText
                                            style={styles.actionTitle} >
                                            Get a call with our expert to get advice:
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - Stop missing deadlines with our reminders.
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - We tell you how you can save taxes for this year and next
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - Get it for CHF {reductionAmount}.- off if you pre-order now.
                                </DefaultText>
                                    </TouchableItem>
                                </View>
                            )}
                        </View>
                        :
                        <View>

                            {hasPotential && !shouldDeclare && (
                                <View>
                                    <TouchableItem
                                        style={styles.actionButton}
                                        onSelectItem={doneHandler}>
                                        <DefaultText
                                            style={styles.actionTitle} >
                                            Thank you for your pre-order, next steps are:
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - Fill in our form before the 15th of March (we will send you reminders!)
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - We will prepare your reclaim request
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - You will only have to sign and send !
                                </DefaultText>
                                    </TouchableItem>
                                </View>
                            )}

                            {shouldDeclare && (
                                <View>
                                    <TouchableItem
                                        style={styles.actionButton}
                                        onSelectItem={doneHandler}>
                                        <DefaultText
                                            style={styles.actionTitle} >
                                            Thank you for your order, next steps are:
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - We will send you an email shortly
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - You can then book a cal with our team
                                </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - Declare your taxes with Taxdone if necessary !
                                    </DefaultText>
                                    </TouchableItem>
                                </View>
                            )}

                            {!shouldDeclare && !hasPotential && (
                                <View>
                                    <TouchableItem
                                        style={styles.actionButton}
                                        onSelectItem={doneHandler}>
                                        <DefaultText
                                            style={styles.actionTitle} >
                                            Thank you for your order, next steps are:
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - We will send you an email shortly
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgument} >
                                            - You can then book a cal with our team
                                    </DefaultText>
                                        <DefaultText
                                            style={styles.actionArgumentBold} >
                                            - Reclaim taxes for this year or next !
                                </DefaultText>
                                    </TouchableItem>
                                </View>
                            )}

                        </View>

                    }
                    <View>
                        <DefaultText style={styles.explanationText3}>{disclaimerDefaultText}</DefaultText>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};


export const potentialResultScreenOptions = navigationData => {
    return {
        title: "Your Potential",
    }
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        justifyContent: 'center'
    },
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        alignItems: 'center',
    },
    explanationText: {
        fontFamily: 'lato',
        fontSize: Dimensions.get('window').height > 550 ? 14 : 12,
        textAlign: 'center',
        padding: 20,
    },
    explanationText1: {
        fontFamily: 'lato',
        fontSize: Dimensions.get('window').height > 550 ? 14 : 12,
        textAlign: 'center',
        paddingTop: 20,
        paddingHorizontal: 20
    },
    actionTitle: {
        fontFamily: 'lato',
        fontSize: Dimensions.get('window').height > 550 ? 14 : 12,
        textAlign: 'center',
        paddingTop: 20,
        paddingBottom: 10,
        paddingHorizontal: 20,
        color: 'white'
    },
    actionArgument: {
        fontFamily: 'lato',
        fontSize: Dimensions.get('window').height > 550 ? 14 : 12,
        textAlign: 'left',
        paddingTop: 0,
        paddingHorizontal: 20,
        color: 'white'
    },
    actionArgumentBold: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').height > 550 ? 14 : 12,
        textAlign: 'left',
        paddingTop: 0,
        paddingHorizontal: 20,
        color: 'white'
    },
    explanationText3: {
        fontFamily: 'lato',
        fontSize: Dimensions.get('window').height > 550 ? 10 : 8,
        textAlign: 'center',
        paddingTop: 10,
        paddingHorizontal: 20
    },
    explanationTextMiddle: {
        fontFamily: 'lato',
        fontSize: Dimensions.get('window').height > 550 ? 14 : 12,
        textAlign: 'center',
        paddingHorizontal: 20
    },
    explanationText2: {
        fontFamily: 'lato',
        fontSize: Dimensions.get('window').height > 550 ? 14 : 12,
        textAlign: 'center',
        paddingBottom: 20,
        paddingHorizontal: 20
    },
    estimateText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'left',
        padding: 10,
        marginBottom: 10
    },
    estimateText2: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'left',
        padding: 10,
        // marginBottom: 10
    },
    explanationContainer: {

    },
    mainContainer: {
        minHeight: Dimensions.get('window').height > 750 ? Dimensions.get('window').height * 0.72 : Dimensions.get('window').height > 550 ? Dimensions.get('window').height * 0.79 : Dimensions.get('window').height * 0.83,
        justifyContent: 'space-between',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    mainContainerPreOrdered: {
        minHeight: Dimensions.get('window').height > 750 ? Dimensions.get('window').height * 0.73 : Dimensions.get('window').height > 550 ? Dimensions.get('window').height * 0.79 : Dimensions.get('window').height * 0.83,
        justifyContent: 'space-between'
    },
    resultContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 250,
        maxHeight: 250,
    },
    preOrderContainer: {
        minHeight: 100,
        maxHeight: 100,
        marginBottom: Dimensions.get('window').height > 550 ? 10 : 0,
        justifyContent: 'flex-end',
    },
    overviewContainer: {
        minHeight: 100,
        maxHeight: 100,
        marginBottom: 10,
        justifyContent: 'flex-end',
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginBottom: 20,
    },
    actionButton: {
        backgroundColor: Colors.darkGreen,
        borderWidth: 0,
        borderColor: Colors.darkGreen,
        marginHorizontal: 10,
        borderRadius: 10,
        paddingBottom: 20,
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        maxHeight: 45,
        marginHorizontal: 30,
        marginBottom: Platform.OS === 'android' ? 10 : 50,
    },
    restartContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 10,
        maxHeight: 50,
        flexDirection: 'row',
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 18
    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
    buttonOk: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        width: 200,
        justifyContent: 'center'
    },
    resultAmount: {
        fontFamily: 'lato-bold',
        fontSize: 30,
        textAlign: 'center',
        paddingHorizontal: 10,
    },
});

export default PotentialResultScreen;