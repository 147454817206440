import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Dimensions, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const PermitScreen = props => {
    const permitData = useSelector(state => state.relevancy.permit);
    const [permit, setPermit] = useState(permitData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setPermit(answer);
        dispatch(relevancyActions.setPermit(answer));
        if (permitData === '') {
            if (answer === 'BorL') {
                props.navigation.navigate('Over120');
            } else {
                props.navigation.navigate('CheckTaxdoneWebsite');
            }
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (permit === 'BorL') {
            props.navigation.navigate('Over120');
        } else {
            props.navigation.navigate('CheckTaxdoneWebsite');
        }
    };


    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>Were you B or L permit the whole year of 2020?</DefaultText>
                    <View style={styles.answerContainer}>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'BorL')}
                            style={permit === 'BorL' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={permit === 'BorL' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                I was B or L permit throughout the year
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'partC')}
                            style={permit === 'partC' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={permit === 'partC' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                I became C permit holder or Swiss citizen
                            </DefaultText>
                        </TouchableItem>
                    </View>

                    <NavContainer
                        backShow={true}
                        nextShow={permit !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const permitScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: Platform.OS === 'web' ? 600 : 0,
        maxWidth: 800
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 140,
        maxHeight: 140,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        maxHeight: 50,
        minWidth: 140,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        maxHeight: 50,
        minWidth: 150,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width > 350 ? 14 : 12,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width > 350 ? 14 : 12,
        color: Colors.darkGreen,
    },
});

export default PermitScreen;