import React from 'react';
import {
    View,
    StyleSheet
} from 'react-native';

import NextButton from './NextButton';
import BackButton from './BackButton';

import Colors from '../constants/Colors';

const NavContainer = props => {

    const { backShow, nextShow, nextNav, backNav } = props;

    return (
        <View style={styles.container}>
            {backShow && (
                <BackButton
                    onClick={backNav}
                />
            )}
            {nextShow && (
                <NextButton
                    onClick={nextNav}
                />
            )}
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 50,
    }
});

export default NavContainer;

