
// export const SET_POTENTIAL_DATA = 'SET_POTENTIAL_DATA';
// export const SET_RELEVANCY_DATA = 'SET_RELEVANCY_DATA';
export const SET_USER_DATA_2020 = 'SET_USER_DATA_2020';
export const PRE_ORDER_CONFIRMED = 'PRE_ORDER_CONFIRMED';
export const SET_SIGNUP_USER_DATA = 'SET_SIGNUP_USER_DATA';
export const SET_USER_POTENTIAL_DATA_ON_LOGIN_2020 = 'SET_USER_POTENTIAL_DATA_ON_LOGIN_2020';
export const SET_INITIAL_POTENTIAL_DATA_FROM_RELEVANCY = 'SET_INITIAL_POTENTIAL_DATA_FROM_RELEVANCY';
export const SET_NEW_USER_POTENTIAL_INFO = 'SET_NEW_USER_POTENTIAL_INFO';
export const SET_CONTACT_NEW_CANTON_COVERAGE = 'SET_CONTACT_NEW_CANTON_COVERAGE';

import * as potentialActions from './potential';

export const setNewUserPotentialInfo = (isDone, potAmount) => {
    return { type: SET_NEW_USER_POTENTIAL_INFO, done: isDone, amount: potAmount }
};

export const setContactNewCantonCoverage = (canton) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const userId = getState().auth.userId;

        try {
            const response = await fetch(`https://taxatsource-4a8c0.firebaseio.com/users/${userId}/contactFor/Canton.json?auth=${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                //firebase authomatically creates an id
                body: JSON.stringify({
                    canton
                })
            });
            if (!response.ok) {
                const errorResponseData = await response.json();
                let message = errorResponseData.error.message
                throw new Error(message);
            }
        } catch (err) {
            throw err;
        }
    }
};

    export const setUser2020Data = () => {
        return async (dispatch, getState) => {
            const token = getState().auth.token;
            const userId = getState().auth.userId;

            const allUserResp = await fetch(`https://taxatsource-4a8c0.firebaseio.com/users/${userId}.json?auth=${token}`);

            if (!allUserResp.ok) {
                const errorAllUserResp = await allUserResp.json();
                let message = errorAllUserResp.error.message
                throw new Error(message);
            }

            const allUserData = await allUserResp.json();

            const user2020Data = allUserData.taxYear2020;

            const user2020Potential = allUserData.taxYear2020.potential;

            const user2020Relevancy = allUserData.taxYear2020.relevancy;

            const user2020PotentialDone = allUserData.taxYear2020.potentialDone;

            const user2020PotentialAmount = allUserData.taxYear2020.potentialAmount;

            const ordersData = allUserData.orders;

            let uOrders = [];
            for (const key in ordersData) {
                uOrders.push(ordersData[key]);
            }
            if (user2020PotentialDone !== true) {
                dispatch(potentialActions.setInitialPotentialDataFromRelevancy(user2020Relevancy));
            } else {
                dispatch(potentialActions.setInitialPotentialDataFromPotential(user2020Potential));
            }

            await dispatch({ type: SET_USER_DATA_2020, data: user2020Data, uOrders: uOrders });
        };
    };

    export const savePotentialData = (done, potential) => {
        return async (dispatch, getState) => {
            const token = getState().auth.token;
            const userId = getState().auth.userId;
            const potentialData = getState().potential;

            // dispatch(setNewUserPotentialInfo(true, potential));

            try {
                const potentialResp = await fetch(`https://taxatsource-4a8c0.firebaseio.com/users/${userId}/taxYear2020.json?auth=${token}`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    //firebase authomatically creates an id
                    body: JSON.stringify({
                        potential: potentialData,
                        potentialDone: done,
                        potentialAmount: potential
                    })
                });

                if (!potentialResp.ok) {
                    const errorResponseData = await potentialResp.json();
                    let message = errorResponseData.error.message
                    throw new Error(message);
                }

            } catch (err) {
                throw err;
            }
        };
    };

    export const confirmPreOrder = (user, paid, amount, ccy) => {
        return { type: PRE_ORDER_CONFIRMED, userId: user, hasPaid: paid, amountPaid: amount, currency: ccy }
    }

    export const setSignupUserData = () => {
        return { type: SET_SIGNUP_USER_DATA }
    }
