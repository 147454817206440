import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, View, Dimensions, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import DefaultDropdown from '../../../components/DefaultDropdown';
import NavContainer from '../../../components/NavContainer';

import * as potentialActions from '../../../store/actions/potential';

import { availableCantons, cantons } from '../../../static/swissCantons';
import CantonNotCoveredScreen from './CantonNotCoveredScreen';

const OtherCantonScreen = props => {
    const nonCoveredCantonData = useSelector(state => state.potential.nonCoveredCanton);
    const [nonCoveredCanton, setNonCoveredCanton] = useState(nonCoveredCantonData);
    const [nonCoveredCantonValid, setNonCoveredCantonValid] = useState(nonCoveredCantonData ? (nonCoveredCantonData.length > 2 ? true : false) : false);

    const dispatch = useDispatch();

    const cantonsList = cantons;

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({animated: true});
    })

    const inputChangeHandler = useCallback((inputId, inputValue, inputValidity) => {
        setNonCoveredCanton(inputValue);
        setNonCoveredCantonValid(inputValidity);
        if (inputValidity) {
            dispatch(potentialActions.setNonCoveredCanton(inputValue));
        }
    }, [nonCoveredCanton, nonCoveredCantonValid]);

    const doneHandler = () => {
        if (nonCoveredCanton === 'Zürich') {
            dispatch(potentialActions.setCanton('Zürich'));
            props.navigation.navigate('UserPermit');
        } else {
            props.navigation.navigate('Result');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>In what canton did you live as of the end of 2020?</DefaultText>
                    <View style={styles.answerContainer}>
                        <DefaultDropdown
                            id='canton'
                            title={Platform.OS === 'ios' ? "Scroll to find your Canton:" : "Select your Canton:"}
                            data={cantonsList}
                            required
                            onInputChange={inputChangeHandler}
                            defaultValue=""
                            minLength={3}
                            initialValue={nonCoveredCanton}
                            initiallyValid={nonCoveredCantonValid}
                        />
                    </View>
                    <NavContainer
                        backShow={true}
                        nextShow={nonCoveredCantonValid && nonCoveredCanton !==""}
                        nextNav={doneHandler}
                        backNav={() => {props.navigation.goBack()}}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const otherCantonScreenOptions = navigationData => {
    return {
        title: "Select Your Canton",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 60,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default OtherCantonScreen;