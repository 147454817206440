import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    KeyboardAvoidingView,
    Platform,
    ScrollView,
    Dimensions,
    TouchableWithoutFeedback,
    Keyboard,
    SafeAreaView,
    Animated,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const WeekStaysForWorkScreen = props => {
    const weekStaysForWorkData = useSelector(state => state.potential.weeklyStays);
    const paidForWeekStaysForWorkData = useSelector(state => state.potential.paidForWeeklyStays);
    const weekStaysForWorkAmountData = useSelector(state => state.potential.weeklyStaysAmount);
    const [weekStaysForWork, setWeekStaysForWork] = useState(weekStaysForWorkData);
    const [paidForWeekStaysForWork, setPaidForWeekStaysForWork] = useState(paidForWeekStaysForWorkData);
    const [weekStaysForWorkAmount, setWeekStaysForWorkAmount] = useState(weekStaysForWorkAmountData);
    const [weekStaysForWorkAmountValid, setWeekStaysForWorkAmountValid] = useState(weekStaysForWorkAmountData ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (paidForWeekStaysForWork === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [paidForWeekStaysForWork])

    const dispatch = useDispatch();

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    const inputChangeHandler = async (id, value, valid) => {
        setWeekStaysForWorkAmount(value);
        setWeekStaysForWorkAmountValid(valid);
        dispatch(potentialActions.setWeekStaysForWorkAmount(value));
    };

    const selectionHandlerStay = async (answer) => {
        setWeekStaysForWork(answer);
        dispatch(potentialActions.setWeekStaysForWork(answer));
        if (weekStaysForWorkData === '') {
            if (answer === false) {
                props.navigation.navigate('Education');
            } 
        }
    };

    const selectionHandlerPaidFor = async (answer) => {
        setPaidForWeekStaysForWork(answer);
        dispatch(potentialActions.setPaidForWeekStaysForWork(answer));        
            if (answer === false) {
                props.navigation.navigate('Education');
            } 
    };

    const doneHandler = () => {
        if (weekStaysForWork === false || weekStaysForWorkAmountValid || paidForWeekStaysForWork === false) {
            props.navigation.navigate('Education');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard
                    onPress={() => {
                        Keyboard.dismiss();
                    }}>
                    <View style={styles.questionContainer}>
                <DefaultText style={styles.questionText}>Did you spend nights far from home for your work?</DefaultText>
                <View style={styles.answerContainer}>
                    <View style={styles.yesNoContainer}>
                        <TouchableItem
                            onSelectItem={() => selectionHandlerStay(false)}
                            style={weekStaysForWork === false ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={weekStaysForWork === false ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                No
                                </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={() => selectionHandlerStay(true)}
                            style={weekStaysForWork === true ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={weekStaysForWork === true ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Yes
                            </DefaultText>
                        </TouchableItem>
                        </View>

                        { weekStaysForWork === true && (                            
                                <View style={styles.paidForContainer}>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandlerPaidFor(true)}
                                        style={paidForWeekStaysForWork === true ? styles.activeAnswerLong : styles.passiveAnswerLong}
                                    >
                                        <DefaultText
                                            style={paidForWeekStaysForWork === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            I paid for the accomodation
                                            </DefaultText>
                                    </TouchableItem>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandlerPaidFor(false)}
                                        style={paidForWeekStaysForWork === false ? styles.activeAnswerLong : styles.passiveAnswerLong}
                                    >
                                        <DefaultText
                                            style={paidForWeekStaysForWork === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            My employer paid for it
                                        </DefaultText>
                                    </TouchableItem>
                                </View>
                                )}

                    {paidForWeekStaysForWork === true && weekStaysForWork === true && (
                        <Animated.View
                        style={[
                            styles.inputContainer,
                            {
                                opacity: fadeAnim // Bind opacity to animated value
                            }
                        ]}>
                        <DefaultInputMaskedMoney
                            focus={!(weekStaysForWorkAmountData > 0)}
                            id='accomodationCost'
                            title="Amount paid for accomodation:"
                            errorMessage="Please enter a valid amount"
                            placeHolder="100'000"
                            keyboardType='numeric'
                            onInputChange={inputChangeHandler}
                            initialValue={weekStaysForWorkAmount}
                            initiallyValid={weekStaysForWorkAmountValid}
                            decimal
                            required
                        />
                        </Animated.View>
                    )}
                </View>

                <NavContainer
                                backShow={true}
                                nextShow={(weekStaysForWork === false || weekStaysForWorkAmountValid === true || paidForWeekStaysForWork === false)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
        </SafeAreaView>
    );
};

export const weekStaysForWorkScreenOptions = navigationData => {
    return {
        title: "Stays Away From Home For Work",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1,
        marginTop: 20
    },
    paidForContainer: {
        justifyContent: 'space-between',
        marginTop: 30
        // flexDirection: 'row',
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswerLong: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerLong: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default WeekStaysForWorkScreen;