import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, ScrollView, SafeAreaView, Platform, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import HeaderButton from '../../../components/HeaderButton';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const PartnerTaxStatusScreen = props => {
    const partnerTaxStatusData = useSelector(state => state.relevancy.partnerTaxStatus);
    const [partnerTaxStatus, setPartnerTaxStatus] = useState(partnerTaxStatusData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setPartnerTaxStatus(answer);
        dispatch(relevancyActions.setPartnerTaxStatus(answer));
        if (partnerTaxStatusData === '') {
            if (answer === 'BorL') {
                props.navigation.navigate('ResultAndSignUp');
            } else {
                props.navigation.navigate('CheckTaxdoneWebsite');
            }
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (partnerTaxStatus === 'BorL') {
            props.navigation.navigate('ResultAndSignUp');
        } else {
            props.navigation.navigate('CheckTaxdoneWebsite');
        }
    };

    useEffect(() => {
        if (partnerTaxStatus !== '') {
            props.navigation.setOptions({
                headerRight: () =>
                    <HeaderButtons HeaderButtonComponent={HeaderButton} >
                        <Item title="Save" iconName='ios-arrow-forward' onPress={doneHandler} />
                    </HeaderButtons>

            });
        }
    }, [doneHandler]);

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>What is the tax status of your partner?</DefaultText>
                    <View style={styles.answerContainer}>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'BorL')}
                            style={partnerTaxStatus === 'BorL' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={partnerTaxStatus === 'BorL' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                B or L permit
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'C')}
                            style={partnerTaxStatus === 'C' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={partnerTaxStatus === 'C' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                C permit
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'Swiss')}
                            style={partnerTaxStatus === 'Swiss' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={partnerTaxStatus === 'Swiss' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Swiss citizen
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'Other')}
                            style={partnerTaxStatus === 'Other' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={partnerTaxStatus === 'Other' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Other
                            </DefaultText>
                        </TouchableItem>
                    </View>

                    <NavContainer
                        backShow={true}
                        nextShow={partnerTaxStatus !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const partnerTaxStatusScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            <HeaderButtons HeaderButtonComponent={HeaderButton} >
                <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                    navigationData.navigation.goBack();
                }} />
            </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: Platform.OS === 'web' ? 600 : 0,
        maxWidth: 800
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 280,
        maxHeight: 280,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default PartnerTaxStatusScreen;