import React from 'react';
import {
    StyleSheet
} from 'react-native';

import Colors from '../constants/Colors';
import TouchableItem from './TouchableItem';
import DefaultText from './DefaultText';

const DefaultNextButton = props => {

    return (
        <TouchableItem onSelectItem={props.onNext}>
            <DefaultText style={styles.submitButton}>{props.title}</DefaultText>
        </TouchableItem>
    )
};

const styles = StyleSheet.create({
    submitButton: {
        fontFamily: 'lato-bold',
        color: 'white',
        backgroundColor: Colors.darkGreen,
        borderRadius: 5,
        minWidth: 100,
        padding: 10,
        textAlign: 'center',
        overflow: 'hidden',
    },
})

export default DefaultNextButton;