import {
    SET_TAS_IN_CH,
    SET_PERMIT,
    SET_OVER_120,
    SET_INCOME_ABROAD,
    SET_INCOME_ABROAD_TYPES,
    SET_LIVE_ABROAD,
    SET_LIVE_ABROAD_FULL,
    SET_MARITAL_STATUS,
    SET_MORE_IN_OUT,
    SET_MOVE_IN_DATE,
    SET_MOVE_OUT_DATE, 
    SET_PARTNER_ABROAD, 
    SET_PARTNER_TAX_STATUS, 
    SET_YEAR,
} from '../actions/relevancy';

const initialState = {
    year: '',
    taSinCh: '',
    permit: '',
    over120: '',
    incomeAbroad: '',
    incomeAbroadTypes: [],
    maritalStatus: '',
    liveAbroad: '',
    liveAbroadFull: '',
    moreInAndOut: '',
    moveInDate: '',
    moveOutDate: '',
    partnerAbroad: '',
    partnerTaxStatus: '',
};

const relevancyReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_YEAR:
            return {
                ...state,
                year: action.data,
            };
        case SET_TAS_IN_CH:
            return {
                ...state,
                taSinCh: action.data,
            };
        case SET_PERMIT:
            return {
                ...state,
                permit: action.data,
            };
        case SET_OVER_120:
            return {
                ...state,
                over120: action.data,
            };
        case SET_INCOME_ABROAD:
            return {
                ...state,
                incomeAbroad: action.data,
            };
        case SET_INCOME_ABROAD_TYPES:
            return {
                ...state,
                incomeAbroadTypes: action.data,
            };
        case SET_LIVE_ABROAD:
            return {
                ...state,
                liveAbroad: action.data,
            };
        case SET_LIVE_ABROAD_FULL:
            return {
                ...state,
                liveAbroadFull: action.data,
            };
        case SET_MARITAL_STATUS:
            return {
                ...state,
                maritalStatus: action.data,
            };
        case SET_MORE_IN_OUT:
            return {
                ...state,
                moreInAndOut: action.data,
            };
        case SET_MOVE_IN_DATE:
            return {
                ...state,
                moveInDate: action.data,
            };
        case SET_MOVE_OUT_DATE:
            return {
                ...state,
                moveOutDate: action.data,
            };
        case SET_PARTNER_ABROAD:
            return {
                ...state,
                partnerAbroad: action.data,
            };
        case SET_PARTNER_TAX_STATUS:
        return {
            ...state,
            partnerTaxStatus: action.data,
        };
        default:
            return state;
    }
    return state;
};

export default relevancyReducer;