//library "formik" in order to easily create forms

import React, { useReducer, useEffect, useRef } from 'react';
// import TextInputMask from 'react-native-text-input-mask';
import {
    View,
    StyleSheet,
    Text,
    Platform,
    Animated
} from 'react-native';

import { TextInputMask } from 'react-native-masked-text'

import DefaultText from './DefaultText';

import Colors from '../constants/Colors';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                stringValue: action.stringValue,
                isValid: action.isValid,
                touched: true
            }
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            }
        default:
            return state;
    }
};

const DefaultInputMaskedDate = props => {
    const { onInputChange, id, focus, initialValue, initiallyValid, touched } = props;

    const [inputState, dispatchInputState] = useReducer(inputReducer, {
        value: initialValue ? initialValue : '',
        stringValue:
            initialValue ?
                (new Date(initialValue).getDate() < 10 ? '0' + new Date(initialValue).getDate().toString() : new Date(initialValue).getDate().toString())
                + (new Date(initialValue).getMonth() + 1 < 10 ? '0' + (new Date(initialValue).getMonth() + 1).toString() : (new Date(initialValue).getMonth() + 1).toString())
                + new Date(initialValue).getFullYear().toString() : '',
        isValid: initiallyValid,
        touched: touched ? touched : false
    });

    const fadeAnim = useRef(new Animated.Value(0)).current;

    //This allows to avoid re-rendering when other props change, If not dont, needs to add props. in useEffect()

    useEffect(() => {
        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
    }, [inputState, onInputChange, id]);

    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0)
    };


    // setStringForInput(new Date(dateIn).getDate().toString() + new Date(dateIn).getMonth().toString() + new Date(dateIn).getFullYear().toString());

    // library for easier validation: validate.js
    const textChangeHandler = (textRaw) => {
        const text = textRaw.replace(/\D/g, '')        
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (text.trim().length !== 8 || Number(text.substring(2, 4)) > 12 || Number(text.substring(0, 2)) > 31 || Number(text.substring(4, 8)) !== 2020) {
            isValid = false;
        }
        if (props.email && !emailRegex.test(text.toLowerCase())) {
            isValid = false;
        }
        if (props.minDate != null && new Date(text.substring(4, 8), (Number(text.substring(2, 4)) - 1).toString(), text.substring(0, 2)) < props.minDate) {
            isValid = false;
        }
        if (props.maxDate != null && new Date(text.substring(4, 8), (Number(text.substring(2, 4)) - 1).toString(), text.substring(0, 2)) > props.maxDate) {
            isValid = false;
        }
        if (props.minLength != null && text.length < props.minLength) {
            isValid = false;
        }
        if (props.decimal && (!isNumber(text) || text === '0')) {
            isValid = false;
        }
        dispatchInputState({
            type: INPUT_CHANGE,
            value: new Date(text.substring(4, 8), (Number(text.substring(2, 4)) - 1).toString(), text.substring(0, 2)),
            stringValue: text,
            isValid: isValid
        });
    };

    const lostFocusHandler = () => {
        dispatchInputState({ type: INPUT_BLUR });
        // fadeOut();
    }

    const focusedOptions =
    {
        format: 'DD/MM/YYYY'
    };

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (inputState.stringValue.length <= 8 && inputState.stringValue.length !== 0) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [inputState.stringValue])

    return (
        <View style={styles.inputContainer}>
            {/* { props.isTitle === true && (
                <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
            )} */}

            { (inputState.stringValue.length <= 8 || inputState.stringValue.length !== 0) &&

                (

                    <Animated.View
                        style={[
                            styles.inputTitleContainer,
                            {
                                opacity: fadeAnim // Bind opacity to animated value
                            }
                        ]}>
                        <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
                    </Animated.View>

                )
            }

            <TextInputMask
                {...props}
                autoFocus={focus}
                style={Platform.OS === 'web' ? { ...styles.inputWeb, outlineWidth: 0 } : styles.input}
                onChangeText={textChangeHandler}
                focusable='true'
                value={inputState.stringValue}
                onBlur={lostFocusHandler}
                placeholder={props.title}
                placeholderTextColor={Colors.darkBlue}
                returnKeyType="done"
                selectionColor={Colors.darkBlue}
                // outlineWidth={0}
                // inlineImageLeft={props.imageLeft}
                // mask={maskParams}
                // onFocus={gainFocusHandler}
                type={'datetime'}
                options={focusedOptions}
            />
            {!inputState.isValid && inputState.touched && (
                <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{props.errorMessage}</Text>
                </View>
            )}
        </View>
    )
};

const styles = StyleSheet.create({
    inputContainer: {
        borderRadius: 10,
        padding: 10,
        // backgroundColor: 'white',
        marginVertical: 10
    },
    inputTitle: {
        fontFamily: 'lato',
        marginBottom: 5,
        color: Colors.darkBlue
    },
    inputTitleContainer: {

    },
    input: {
        borderBottomColor: Colors.darkBlue,
        color: Colors.darkBlue,
        width: '100%',
        minHeight: 30,
        borderBottomWidth: 0.5,
        fontSize: Platform.OS === 'web' ? 20 : 16,
        paddingLeft: 5,
    },
    inputWeb: {
        borderBottomColor: Colors.darkBlue,
        color: Colors.darkBlue,
        width: '100%',
        minHeight: 30,
        borderBottomWidth: 0.5,
        fontSize: Platform.OS === 'web' ? 20 : 16,
        paddingLeft: 5,
        // outlineWidth: 0
    },
    errorContainer: {
        marginVertical: 5
    },
    errorText: {
        fontFamily: 'lato',
        fontSize: 13,
        color: 'red'
    }
});

export default DefaultInputMaskedDate;

