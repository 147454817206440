import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { useSelector } from 'react-redux';;
import { navigationRef } from './RootNavigation';
import { HelloNavigator } from './components/Hello';
import { LoggedInNavigator } from './components/MySpace';
import StartScreen from '../screens/StartScreen';

const AppNavigator = props => {
    const isAuth = useSelector(state => !!state.auth.token);
    const didTryAutoLogin = useSelector(state => state.auth.didTryAutoLogin);    

    return (
    <NavigationContainer ref={navigationRef}>
        {isAuth && <LoggedInNavigator/>}
        {!isAuth && didTryAutoLogin && <HelloNavigator/>}
        {!didTryAutoLogin && <StartScreen/>}
    </NavigationContainer>
    );
};

export default AppNavigator;

