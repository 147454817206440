//library "formik" in order to easily create forms

import React, { useReducer, useEffect, useRef } from 'react';
import {
    View,
    StyleSheet,
    TextInput,
    Text,
    Platform,
    Animated
} from 'react-native';

import DefaultText from './DefaultText';

import Colors from '../constants/Colors';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                touched: true
            }
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            }
        default:
            return state;
    }
};

const DefaultInput = props => {
    const [inputState, dispatchInputState] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : '',
        isValid: props.initiallyValid,
        touched: props.touched ? props.touched : false
    });

    //This allows to avoid re-rendering when other props change, If not dont, needs to add props. in useEffect()
    const { onInputChange, id, focus } = props;

    useEffect(() => {
        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
    }, [inputState, onInputChange, id]);

    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0)
    };

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (inputState.value !== '') {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [inputState.value]);

    // library for easier validation: validate.js
    const textChangeHandler = text => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (props.required && text.trim().length === 0) {
            isValid = false;
        }
        if (props.email && !emailRegex.test(text.toLowerCase())) {
            isValid = false;
        }
        if (props.min != null && +text < props.min) {
            isValid = false;
        }
        if (props.max != null && +text > props.max) {
            isValid = false;
        }
        if (props.minLength != null && text.length < props.minLength) {
            isValid = false;
        }
        if (props.decimal && !isNumber(text)) {
            isValid = false;
        }
        dispatchInputState({
            type: INPUT_CHANGE,
            value: text,
            isValid: isValid
        });
    };

    const lostFocusHandler = () => {
        dispatchInputState({ type: INPUT_BLUR })
    };

    return (
        <View style={styles.inputContainer}>
            { props.isTitle === true && (
                <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
            )}
            { inputState.value !== '' && (

                <Animated.View
                    style={[
                        styles.inputTitleContainer,
                        {
                            opacity: fadeAnim // Bind opacity to animated value
                        }
                    ]}
                >
                    <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
                </Animated.View>
            )}

            <TextInput
                {...props}
                // autoFocus={focus}
                style={Platform.OS === 'web' ? { ...styles.inputWeb, outlineWidth: 0 } : styles.input}
                onChangeText={textChangeHandler}
                focusable='true'
                value={inputState.value}
                onBlur={lostFocusHandler}
                placeholder={props.title}
                placeholderTextColor={Colors.darkBlue}
                returnKeyType="done"
                selectionColor={Colors.darkBlue}
            // inlineImageLeft={props.imageLeft}
            />
            {!inputState.isValid && inputState.touched && (
                <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{props.errorMessage}</Text>
                </View>
            )}
        </View>
    )
};

const styles = StyleSheet.create({
    inputContainer: {
        borderRadius: 10,
        padding: 10,
        backgroundColor: 'white',
        marginVertical: 10
    },
    inputTitle: {
        fontFamily: 'lato',
        fontSize: 12,
        marginBottom: 5,
        color: Colors.darkBlue
    },
    input: {
        borderBottomColor: Colors.darkBlue,
        width: '100%',
        minHeight: 30,
        borderBottomColor: 'lightgrey',
        borderBottomWidth: 0.5,
        fontSize: 16,
        // outlineWidth: 0
    },
    inputWeb: {
        borderBottomColor: Colors.darkBlue,
        width: '100%',
        minHeight: 30,
        borderBottomColor: 'lightgrey',
        borderBottomWidth: 0.5,
        fontSize: 16,
        // outlineWidth: 0
    },
    errorContainer: {
        marginVertical: 5
    },
    errorText: {
        fontFamily: 'lato',
        fontSize: 13,
        color: 'red'
    }
});

export default DefaultInput;

