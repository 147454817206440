import {
    SET_PREORDER_PRICE
} from '../actions/payment';

const initialState = {
    preOrderPrice: '',
    normalPrice: '',
    reductionAmount: ''                
};

const paymentReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_PREORDER_PRICE:
            return {
                ...state,
                preOrderPrice: action.finalPrice,
                normalPrice: action.normalPrice,
                reductionAmount: action.reduction,
            };
        default:
            return state;
    }
    return state;
};

export default paymentReducer;