import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, ScrollView, SafeAreaView, Platform, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import HeaderButton from '../../../components/HeaderButton';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const PartnerAbroadScreen = props => {
    const partnerAbroadData = useSelector(state => state.relevancy.partnerAbroad);
    const [partnerAbroad, setPartnerAbroad] = useState(partnerAbroadData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setPartnerAbroad(answer);
        dispatch(relevancyActions.setPartnerAbroad(answer));
        if (partnerAbroadData === '') {
            if (answer === true) {
                props.navigation.navigate('ResultAndSignUp');
            } else {
                props.navigation.navigate('PartnerTaxStatus');
            }
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (partnerAbroad === true) {
            props.navigation.navigate('ResultAndSignUp');
        } else {
            props.navigation.navigate('PartnerTaxStatus');
        }
    };

    useEffect(() => {
        if (partnerAbroad !== '') {
        props.navigation.setOptions({
            headerRight: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-forward' onPress={doneHandler} />
                </HeaderButtons>
                
        });
    }
    }, [doneHandler]);

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
            <View style={styles.questionContainer}>
                <DefaultText style={styles.questionText}>Where does your partner live?</DefaultText>
                <View style={styles.answerContainer}>
                    <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, false)}
                        style={partnerAbroad === false ? styles.activeAnswer : styles.passiveAnswer}
                        >
                        <DefaultText
                            numberOfLines={1}
                            style={partnerAbroad === false ? styles.activeAnswerText : styles.passiveAnswerText}
                        >
                            In Switzerland
                            </DefaultText>
                    </TouchableItem>
                    <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, true)}
                        style={partnerAbroad === true ? styles.activeAnswer : styles.passiveAnswer}
                    >
                        <DefaultText
                            numberOfLines={1}
                            style={partnerAbroad === true ? styles.activeAnswerText : styles.passiveAnswerText}
                        >
                            Outside of Switzerland
                            </DefaultText>
                    </TouchableItem>
                </View>

                <NavContainer
                        backShow={true}
                        nextShow={partnerAbroad !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

            </View>
        </ScrollView>
        </SafeAreaView>
    );
};

export const partnerAbroadScreenOptions = navigationData => {
    return {
        headerLeft: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                        navigationData.navigation.goBack();
                    }} />
                </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: Platform.OS === 'web' ? 600 : 0,
        maxWidth: 800
    },  
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 140,
        maxHeight: 140,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        maxHeight: 50,
        minWidth: 140,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        maxHeight: 50,
        minWidth: 150,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default PartnerAbroadScreen;