import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Image,
    ImageBackground
} from 'react-native';
import { useDispatch } from 'react-redux';
import { WebView } from 'react-native-webview';

import Colors from '../../constants/Colors';

const PrivacyPolicyScreen = props => {

    const dispatch = useDispatch();

    return (
        <SafeAreaView style={styles.screen}>
            <WebView
                source={{ uri: "https://www.taxdone.ch/privacy" }} style={{ marginTop: 20 }}
            />
        </SafeAreaView>
    );
};


export const privacyPolicyScreenOptions = navigationData => {
    return {
        title: "Privacy Policy",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        justifyContent: 'center'
    },
    potentialAction: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        maxHeight: 50
    },
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
    },
    mainContainer: {
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: Dimensions.get('window').height * 0.8
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    preOrderContainer: {
        height: 100,
        marginBottom: 10,
        justifyContent: 'space-evenly'
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 20
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginHorizontal: 30,
        marginBottom: 50
    },
    restartContainer: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginHorizontal: 30,
        marginTop: 10,
        flexDirection: 'row'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 18
    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
    successText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'center',
        padding: 10
    },
    successDesc1: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
    },
    successDesc: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
        paddingBottom: 20
    },
});

export default PrivacyPolicyScreen;