import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView, SafeAreaView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import HeaderButton from '../../../components/HeaderButton';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';

import * as relevancyActions from '../../../store/actions/relevancy';

const TaxYearScreen = props => {
    const yearData = useSelector(state => state.relevancy.year);
    const [year, setYear] = useState(yearData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setYear(answer);
        dispatch(relevancyActions.setYear(answer));
        if (yearData === '') {
            if (answer === '2020') {
                props.navigation.navigate('Canton');
            } else {
                props.navigation.navigate('CheckTaxdoneWebsite');
            }
        }
    };

    const doneHandler = () => {
        if (year === '2020') {
            props.navigation.navigate('Canton');
        } else {
            props.navigation.navigate('CheckTaxdoneWebsite');
        }
    };

    useEffect(() => {
        if (year !== '') {
        props.navigation.setOptions({
            headerRight: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-forward' onPress={doneHandler} />
                </HeaderButtons>
                
        });
    }
    }, [doneHandler]);

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView >
            <View style={styles.questionContainer}>
                <DefaultText style={styles.questionText}>What tax year do you want to look at?</DefaultText>
                <View style={styles.answerContainer}>
                    <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, '2019')}
                        style={year === '2019' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                        <DefaultText
                            numberOfLines={1}
                            style={year === '2019' ? styles.activeAnswerText : styles.passiveAnswerText}
                        >
                            2019
                            </DefaultText>
                    </TouchableItem>
                    <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, '2020')}
                        style={year === '2020' ? styles.activeAnswer : styles.passiveAnswer}
                    >
                        <DefaultText
                            numberOfLines={1}
                            style={year === '2020' ? styles.activeAnswerText : styles.passiveAnswerText}
                        >
                            2020
                            </DefaultText>
                    </TouchableItem>
                </View>
            </View>
        </ScrollView>
        </SafeAreaView>
    );
};

export const taxYearScreenOptions = navigationData => {
    return {
        headerLeft: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                        navigationData.navigation.goBack();
                    }} />
                </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10
    },
    questionContainer: {
        flex: 1,
        justifyContent: 'center',
        padding: 10
    },  
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 60,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default TaxYearScreen;