import React, { useState, useCallback, useRef, useEffect } from 'react';
import { StyleSheet, View, Linking, SafeAreaView, ScrollView, Dimensions } from 'react-native';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';
import { useDispatch } from 'react-redux';

import HeaderButton from '../../../components/HeaderButton';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import DefaultDropdown from '../../../components/DefaultDropdown';

import { cantons } from '../../../static/swissCantons';

import * as userActions from '../../../store/actions/user';

const CantonNotCoveredScreen = props => {
    const [canton, setCanton] = useState('');
    const [cantonValidity, setCantonValidity] = useState(false);

    const cantonsList = cantons;

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({animated: true});
    })


    const dispatch = useDispatch();

    const inputChangeHandler = useCallback((inputId, inputValue, inputValidity) => {
        setCanton(inputValue);
        setCantonValidity(inputValidity);
    }, [canton, cantonValidity]);

    const contactHandler = () => {
        dispatch(userActions.setContactNewCantonCoverage(canton));
        props.navigation.navigate('TasOverview');
    };

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>My Canton:</DefaultText>
                    <View style={styles.answerContainer}>
                        <DefaultDropdown
                            id='canton'
                            title=""
                            data={cantonsList}
                            required
                            onInputChange={inputChangeHandler}
                            defaultValue=" "
                            minLength={3}
                            initialValue={canton}
                            initiallyValid={cantonValidity}
                        />
                    </View>
                    <View style={styles.ButtonsContainer}>
                        <TouchableItem
                            style={styles.button}
                            onSelectItem={contactHandler}
                        >
                            <DefaultText
                                style={styles.buttonText}
                            >Contact me as soon as</DefaultText>
                            <DefaultText
                                style={styles.buttonText}
                            >{canton} is available !</DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            style={styles.buttonNo}
                            onSelectItem={() => { props.navigation.navigate('TasOverview') }}
                        >
                            <DefaultText
                                style={styles.switchText}
                            >No thanks, bring me back to the overview</DefaultText>
                        </TouchableItem>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const cantonNotCoveredScreenOptions = navigationData => {
    return {
        title: "All other Swiss Cantons",
        headerLeft: () =>
            <HeaderButtons HeaderButtonComponent={HeaderButton} >
                <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                    navigationData.navigation.goBack();
                }} />
            </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: 'flex-end'
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 60,
        padding: 10,
    },
    ButtonsContainer: {
        justifyContent: 'space-between',
        marginTop: 50,
        height: 100
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 20
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 60,
        maxHeight: 60,
        marginHorizontal: 30,
        padding: 10
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 15,

    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
});

export default CantonNotCoveredScreen;