import {
    SET_USER_DATA_2020,
    PRE_ORDER_CONFIRMED,
    SET_SIGNUP_USER_DATA,
    SET_NEW_USER_POTENTIAL_INFO
} from '../actions/user';

import {
    LOGOUT
} from '../actions/auth';

const initialState = {
    relevancyData2020: [],
    potentialData2020: [],
    userData2020: [],
    userOrders: [],
    potentialDone: '',
    potentialAmount: '',
};

const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_NEW_USER_POTENTIAL_INFO:
            return {
                ...state,
                potentialDone: action.done,
                potentialAmount: action.amount
            }
        case LOGOUT:
            return {
                ...state,
                userData2020: [],
                userOrders: [],
                potentialDone: '',
                potentialAmount: ''
            }
        case SET_SIGNUP_USER_DATA:
            return {
                ...state,
                potentialDone: false
            }
        case SET_USER_DATA_2020:
            return {
                ...state,
                userData2020: action.data,
                userOrders: action.uOrders,
                potentialDone: action.data.potentialDone,
                potentialAmount: action.data.potentialAmount
            }
        case PRE_ORDER_CONFIRMED:
            const updatedUserOrder = [...state.userOrders];
            const newOrder = {
                taxYear: '2020',
                preOrder: true,
                amount: action.amountPaid,
                currency: action.currency,
                user: action.userId,
                orderDate: new Date(),
            };
            updatedUserOrder.push(newOrder);
            return {
                ...state,
                userOrders: updatedUserOrder
            };
        default:
            return state;
    }
    return state;
};

export default userReducer;