import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    SafeAreaView,
    Animated,
    Platform,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultInput from '../../../components/DefaultInput';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const AnyOtherIncomeScreen = props => {
    const maritalStatus = useSelector(state => state.potential.maritalStatus);
    const investmentIncomeAmount = useSelector(state => state.potential.investmentIncomeAmount);
    const otherIncomeTypesData = useSelector(state => state.potential.otherIncomeTypes);
    const otherIncomeAmountData = useSelector(state => state.potential.otherIncomeAmount);
    const [otherIncomeTypes, setOtherIncomeTypes] = useState(otherIncomeTypesData ? otherIncomeTypesData : []);
    const [otherIncomeAmount, setOtherIncomeAmount] = useState(otherIncomeAmountData);
    const [otherIncomeAmountValid, setOtherIncomeAmountValid] = useState((otherIncomeAmountData > 0) ? true : false);
    
    const dispatch = useDispatch();

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (otherIncomeTypes.length > 0) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [otherIncomeTypes])

    const selectionHandler = async (answer) => {
        let newAnswers = [...otherIncomeTypes];
        if (answer === 'none') {
            newAnswers = [];
        } else if (newAnswers.includes(answer)) {
            newAnswers = newAnswers.filter(a => a !== answer)
        } else {
            newAnswers.push(answer);
        }
        setOtherIncomeTypes(newAnswers);
        await dispatch(potentialActions.setOtherIncomeTypes(newAnswers));
    };

    const inputChangeHandler = async (id, value, valid) => {
        setOtherIncomeAmount(value);
        setOtherIncomeAmountValid(valid);
        dispatch(potentialActions.setOtherIncomeAmount(value));
    };

    const doneHandler = () => {
        if ( (Number(investmentIncomeAmount) + Number(otherIncomeAmount)) > 2500 && otherIncomeTypes.length > 0) {
            props.navigation.navigate('Result');
        } else if (otherIncomeTypes.length === 0 || otherIncomeAmountValid) {
            props.navigation.navigate('Wealth');
        }
    };

    const sView = useRef();

    // useEffect(() => {
    //     if (Dimensions.get('window').height > 500) {
    //         sView.current.scrollToEnd({ animated: true });
    //     }
    // })

    return (
        <SafeAreaView style={styles.screen}>
            <KeyboardAvoidingView
                behavior='padding'
                keyboardVerticalOffset={50}
            >
                <ScrollView ref={sView}>
                    <DismissKeyboard>
                        <View style={styles.questionContainer}>
                            <View style={styles.questionTextContainer}>
                                <DefaultText style={styles.questionText}>
                                    {maritalStatus === 'Married' ?
                                        'While in Switzerland, did you and your partner receive any other income ?'
                                        :
                                        'While in Switzerland, Did you receive any other income ?'
                                    }
                                </DefaultText>
                                <DefaultText style={styles.note}>
                                    Please select what applies
                        </DefaultText>
                            </View>
                            <View style={styles.answerContainer}>
                                <View style={styles.yesNoContainer}>

                                    <TouchableItem
                                        onSelectItem={selectionHandler.bind(this, 'selfEmployment')}
                                        style={otherIncomeTypes.includes('selfEmployment') ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.includes('selfEmployment') ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Self-employment
                                        </DefaultText>
                                    </TouchableItem>

                                    <TouchableItem
                                        onSelectItem={selectionHandler.bind(this, 'maternity')}
                                        style={otherIncomeTypes.includes('maternity') ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.includes('maternity') ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Pensions
                                    </DefaultText>
                                    </TouchableItem>

                                    <TouchableItem
                                        style={otherIncomeTypes.includes('alimony') ? styles.activeAnswer : styles.passiveAnswer}
                                        onSelectItem={selectionHandler.bind(this, 'alimony')}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.includes('alimony') ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Alimony from an ex for children or else
                                </DefaultText>
                                    </TouchableItem>

                                    {/* or old-age and survivors' insurance, invalidity insurance, non-occupational accident insurance, occupational pension */}

                                    <TouchableItem
                                        style={otherIncomeTypes.includes('health') ? styles.activeAnswer : styles.passiveAnswer}
                                        onSelectItem={selectionHandler.bind(this, 'health')}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.includes('health') ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Health or accident benefits
                                </DefaultText>
                                    </TouchableItem>

                                    <TouchableItem
                                        style={otherIncomeTypes.includes('securities') ? styles.activeAnswer : styles.passiveAnswer}
                                        onSelectItem={selectionHandler.bind(this, 'securities')}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.includes('securities') ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Securities income (dividends, interests...)
                                </DefaultText>
                                    </TouchableItem>

                                    <TouchableItem
                                        style={otherIncomeTypes.includes('realEstate') ? styles.activeAnswer : styles.passiveAnswer}
                                        onSelectItem={selectionHandler.bind(this, 'realEstate')}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.includes('realEstate') ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            From real estate
                                </DefaultText>
                                    </TouchableItem>

                                    <TouchableItem
                                        style={otherIncomeTypes.includes('other') ? styles.activeAnswer : styles.passiveAnswer}
                                        onSelectItem={selectionHandler.bind(this, 'other')}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.includes('other') ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Other income
                                </DefaultText>
                                    </TouchableItem>

                                    <TouchableItem
                                        style={otherIncomeTypes.length === 0 ? styles.activeAnswer : styles.passiveAnswer}
                                        onSelectItem={selectionHandler.bind(this, 'none')}
                                    >
                                        <DefaultText
                                            style={otherIncomeTypes.length === 0 ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Nothing else
                                </DefaultText>
                                    </TouchableItem>

                                </View>
                                {otherIncomeTypes.length > 0 && (
                                    <Animated.View
                                        style={[
                                            styles.inputContainer,
                                            {
                                                opacity: fadeAnim // Bind opacity to animated value
                                            }
                                        ]}>
                                        <DefaultInputMaskedMoney
                                            focus={false}
                                            id='otherIncome'
                                            title="Sum of all these other incomes:"
                                            errorMessage="Please enter a valid amount"
                                            placeHolder="10'000"
                                            keyboardType='numeric'
                                            onInputChange={inputChangeHandler}
                                            initialValue={otherIncomeAmount}
                                            initiallyValid={otherIncomeAmountValid}
                                            decimal
                                            required
                                        />
                                    </Animated.View>
                                )}

                            </View>

                            <NavContainer
                                backShow={true}
                                nextShow={(otherIncomeTypes.length === 0 || otherIncomeAmountValid)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />
                        </View>
                    </DismissKeyboard>
                </ScrollView>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};

export const anyOtherIncomeScreenOptions = navigationData => {
    return {
        title: "Any other income ?",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1,
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        marginBottom: 20
    },
    questionTextContainer: {
        marginBottom: 40,
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: '100%',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: '100%',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width < 330 ? 12 : Dimensions.get('window').width > 380 ? 16 : 14,
        color: Colors.darkGreen,
        textAlign: 'center',
        paddingHorizontal: 20
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width < 330 ? 12 : Dimensions.get('window').width > 380 ? 16 : 14,
        color: Colors.darkGreen,
        textAlign: 'center',
        paddingHorizontal: 20
    },
});

export default AnyOtherIncomeScreen;