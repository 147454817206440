import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Image,
    ImageBackground
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import TouchableItem from '../../components/TouchableItem';
import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';

const TaxAtSourceOverviewScreen = props => {
    const [hasPreOrdered, setHasPreOrdered] = useState();

    const potentialDone = useSelector(state => state.user.potentialDone);
    const potentialAmount = useSelector(state => state.user.potentialAmount);
    const userOrders = useSelector(state => state.user.userOrders);

    useEffect(() => {
        if (userOrders.length > 0) {
            setHasPreOrdered(true)
        } else {
            setHasPreOrdered(false)
        }
    }, [userOrders])

    const testPotentialHandler = () => {
        if ( potentialDone !== true ) {
        props.navigation.navigate('MaritalStatusConfirmation');
    } else {
        props.navigation.navigate('Result');
    }
    }

    const dispatch = useDispatch();

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView >
                <View style={styles.mainContainer}>

                        <TouchableItem 
                        onSelectItem={testPotentialHandler}
                        style={styles.potentialAction}>
                            <Image
                                style={{ width: 50, height: 50, borderColor: Colors.backColor, marginRight: 10 }}
                                source={require('../../assets/TaxQuestions2.png')}
                                resizeMethod='auto'
                                resizeMode='cover'
                            />
                            <View style={{ }}>
                                <DefaultText style={{ fontFamily: 'lato-bold', color: Colors.darkBlue }}>
                                    Can you get a refund ?
                                </DefaultText>
                                <DefaultText style={{ fontFamily: 'lato', color: Colors.darkBlue }}>
                                    Check-out our simulator!
                                </DefaultText>
                            </View>
                        </TouchableItem>
                    
                    <ImageBackground
                        style={{ width: 250, height: 250, borderColor: Colors.backColor, alignItems: 'center' }}
                        source={require('../../assets/TimeAndMoney2.png')}
                        resizeMethod='auto'
                        resizeMode='cover'
                    >
                        <DefaultText style={{ fontFamily: 'lato-bold', color: Colors.darkBlue }}>Start process on 1st of Jan. 2021</DefaultText>
                    </ImageBackground>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};


export const taxAtSourceOverviewScreenOptions = navigationData => {
    return {
        title: "Overview",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        justifyContent: 'center'
    },
    potentialAction: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        maxHeight: 50,
    },
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
    },
    mainContainer: {
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: Dimensions.get('window').height * 0.8
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    preOrderContainer: {
        height: 100,
        marginBottom: 10,
        justifyContent: 'space-evenly'
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 20
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginHorizontal: 30,
        marginBottom: 50
    },
    restartContainer: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginHorizontal: 30,
        marginTop: 10,
        flexDirection: 'row'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 18
    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
    successText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'center',
        padding: 10
    },
    successDesc1: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
    },
    successDesc: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
        paddingBottom: 20
    },
});

export default TaxAtSourceOverviewScreen;