import React, { useState, useEffect,useRef } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Keyboard,
    Dimensions,
    ScrollView,
    SafeAreaView,
    Animated,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const Pillar3aScreen = props => {
    const pillar3aData = useSelector(state => state.potential.pillar3a);
    const pillar3aAmountData = useSelector(state => state.potential.pillar3aAmount);
    const [pillar3a, setPillar3a] = useState(pillar3aData);
    const [pillar3aAmount, setPillar3aAmount] = useState(pillar3aAmountData);
    const [pillar3aAmountValid, setPillar3aAmountValid] = useState(pillar3aAmountData ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (pillar3a === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [pillar3a])

    const dispatch = useDispatch();

    const inputChangeHandler = async (id, value, valid) => {
        setPillar3aAmount(value);
        setPillar3aAmountValid(valid);
        dispatch(potentialActions.setPillar3aAmount(value));
    };

    const selectionHandler = async (answer) => {
        setPillar3a(answer);
        dispatch(potentialActions.setPillar3a(answer));
        if (pillar3aData === '') {
            if (answer === false) {
                props.navigation.navigate('AlimonyPaid');
            }
        }
    };

    const doneHandler = () => {
        if (pillar3a === false || pillar3aAmountValid) {
            props.navigation.navigate('AlimonyPaid');
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard>
                        <View style={styles.questionContainer}>
                    <View style={styles.questionTextContainer}>
                        <DefaultText style={styles.questionText}>Did you contribute to your pillar 3 in 2020?</DefaultText>
                        <DefaultText style={styles.note}>For most people, it's up to 6'826 CHF.</DefaultText>
                    </View>
                    <View style={styles.answerContainer}>
                        <View style={styles.yesNoContainer}>
                            <TouchableItem
                                onSelectItem={() => selectionHandler(false)}
                                style={pillar3a === false ? styles.activeAnswer : styles.passiveAnswer}
                            >
                                <DefaultText
                                    style={pillar3a === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                >
                                    No
                                </DefaultText>
                            </TouchableItem>
                            <TouchableItem
                                onSelectItem={() => selectionHandler(true)}
                                style={pillar3a === true ? styles.activeAnswer : styles.passiveAnswer}
                            >
                                <DefaultText
                                    style={pillar3a === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                >
                                    Yes
                            </DefaultText>
                            </TouchableItem>
                        </View>
                        
                        {pillar3a === true && (
                            <Animated.View 
                            style={[
                                styles.inputContainer,
                                {
                                  opacity: fadeAnim // Bind opacity to animated value
                                }
                              ]}>
                                <DefaultInputMaskedMoney
                                    focus={!(pillar3aAmountData > 0)}
                                    id='pillar3a'
                                    title="Pillar 3a buy-in amount:"
                                    errorMessage="Please enter a valid amount"
                                    placeHolder="10'000"
                                    keyboardType='numeric'
                                    onInputChange={inputChangeHandler}
                                    initialValue={pillar3aAmount}
                                    initiallyValid={pillar3aAmountValid}
                                    decimal
                                    required
                                />
                            </Animated.View>
                        )}
                    </View>

                    <NavContainer
                                backShow={true}
                                nextShow={(pillar3a === false || pillar3aAmountValid === true)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                    </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView >
        </SafeAreaView>
    );
};

export const pillar3aScreenOptions = navigationData => {
    return {
        title: "Pillar 3a",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1,
        marginTop: 20
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    questionTextContainer: {
        marginBottom: 40,
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default Pillar3aScreen;