import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    KeyboardAvoidingView,
    Dimensions,
    ScrollView,
    SafeAreaView,
    Animated,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';
import DefaultDropdown from '../../../components/DefaultDropdown';

import * as potentialActions from '../../../store/actions/potential';

const TimeSpentInChScreen = props => {
    const maritalStatus = useSelector(state => state.potential.maritalStatus);
    const partnerAbroad = useSelector(state => state.potential.partnerAbroad);
    const fullYearInChData = useSelector(state => state.potential.fullYearInCh);
    const monthInChData = useSelector(state => state.potential.monthInCh);
    const [fullYearInCh, setFullYearInCh] = useState(fullYearInChData);
    const [monthInCh, setMonthInCh] = useState(monthInChData);

    console.log('monthInChData: ' + monthInChData);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (fullYearInCh === false) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [fullYearInCh])

    const dispatch = useDispatch();

    const inputChangeHandler = async (id, value, valid) => {
        setMonthInCh(value);
        dispatch(potentialActions.setMonthInCh(value));
    };

    const selectionHandler = async (answer) => {
        setFullYearInCh(answer);
        dispatch(potentialActions.setFullYearInCh(answer));
        if (fullYearInChData === '') {
            if (answer === true) {
                props.navigation.navigate('IncomeTas');
            }
        }
    };

    const doneHandler = () => {
        props.navigation.navigate('IncomeTas');
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <KeyboardAvoidingView behavior='position'>
                    <DismissKeyboard>
                        <View style={styles.questionContainer}>
                            <View style={styles.questionTextContainer}>
                            {(maritalStatus === 'Married' && !partnerAbroad) ? 
                            <DefaultText style={styles.questionText}>Did you and your partner spend the whole year in Switzerland ?</DefaultText>
                            :
                                <DefaultText style={styles.questionText}>Did you spend the whole year in Switzerland ?</DefaultText>
                            }
                                <DefaultText style={styles.note}>If you went somewhere in holidays for a couple of weeks it does NOT count as spending time abroad :)</DefaultText>
                            </View>
                            <View style={styles.answerContainer}>
                                <View style={styles.yesNoContainer}>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandler(false)}
                                        style={fullYearInCh === false ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={fullYearInCh === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            No
                                </DefaultText>
                                    </TouchableItem>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandler(true)}
                                        style={fullYearInCh === true ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={fullYearInCh === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Yes
                            </DefaultText>
                                    </TouchableItem>
                                </View>
                                {fullYearInCh === false && (
                                    <Animated.View
                                        style={[
                                            styles.inputContainer,
                                            {
                                                opacity: fadeAnim // Bind opacity to animated value
                                            }
                                        ]}>

                                        <View style={styles.dropDownContainer}>
                                            <DefaultDropdown
                                                id='monthInChCount'
                                                title="How many month did you spend in Switzerland and paid tax at source:"
                                                data={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']}
                                                required
                                                onInputChange={inputChangeHandler}
                                                // defaultValue="1"
                                                minLength={1}
                                                initialValue={monthInCh}
                                                initiallyValid={true}
                                            />
                                        </View>
                                    </Animated.View>
                                )}
                            </View>

                            <NavContainer
                                backShow={true}
                                nextShow={(fullYearInCh === true || monthInCh !== '')}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                        </View>
                    </DismissKeyboard>
                </KeyboardAvoidingView>
            </ScrollView>
        </SafeAreaView>
    );
};

export const timeSpentInChScreenOptions = navigationData => {
    return {
        title: "Time in Switzerland",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    inputContainer: {
        flex: 1,
        marginTop: 20,
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    yesNoContainerC: {
        justifyContent: 'space-between',
        marginTop: 10
    },
    dropDownContainer: {
        marginTop: 20
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    questionTextContainer: {
        marginBottom: 40,
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
    activeAnswerTextC: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('screen').width > 380 ? 18 : 15,
        color: Colors.darkGreen,
    },
    passiveAnswerTextC: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('screen').width > 380 ? 18 : 15,
        color: Colors.darkGreen,
    },
    passiveAnswerLong: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerLong: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 140,
        // width: (Dimensions.get('window').width - 100) / 2,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default TimeSpentInChScreen;