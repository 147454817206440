import React from 'react';
import {
    TouchableWithoutFeedback,
    View,
    Keyboard,
    Platform
} from 'react-native';

const DismissKeyboard = props => {

    let Container = TouchableWithoutFeedback;

    if ( Platform.OS === 'web' ) {
        Container = View;
    }

    return (
        <Container
                onPress={() => {
                Keyboard.dismiss();
            }}>
                {props.children}
        </Container>
    )
};

export default DismissKeyboard;

// {...props.style, ...styles.view }