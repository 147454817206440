import AsyncStorage from '@react-native-async-storage/async-storage';
import * as firebase from 'firebase';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const SET_DID_TRY_AL = 'SET_DID_TRY_AL';

import * as userActions from './user';
import * as potentialActions from './potential';

let timer;

export const setDidTryAl = (trueFalse) => {
    return { type: SET_DID_TRY_AL, trueFalse: trueFalse }
};

export const signup = (email, password) => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const userId = getState().auth.userId;
        const relevancyData = getState().relevancy;

        try {
            await firebase.auth().createUserWithEmailAndPassword(email, password)
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
            const userId = idTokenResult.claims.user_id;
            const token = idTokenResult.token;
            const expirationDate = new Date(idTokenResult.expirationTime);
            const timeToExpiration = (idTokenResult.claims.exp - idTokenResult.claims.auth_time) * 1000;
            const userEmail = idTokenResult.claims.email;

            dispatch(authenticate(token, userId, timeToExpiration, userEmail));

            dispatch(userActions.setSignupUserData());

            saveDataToStorage(token, userId, expirationDate, userEmail);

            const applicationResp = await fetch(`https://taxatsource-4a8c0.firebaseio.com/users/${userId}/taxYear2020.json?auth=${token}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                //firebase authomatically creates an id
                body: JSON.stringify({
                    relevancy: relevancyData
                })
            });

            if (!applicationResp.ok) {
                const errorResponseData = await applicationResp.json();
                let message = errorResponseData.error.message
                throw new Error(message);
            }
        } catch (err) {
            throw err;
        }

        await dispatch(potentialActions.setInitialPotentialDataFromRelevancy(relevancyData));
    };
};

export const login = (email, password) => {
    return async dispatch => {

        try {
            await firebase.auth().signInWithEmailAndPassword(email, password);
            const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
            const userId = idTokenResult.claims.user_id;
            const token = idTokenResult.token;
            const expirationDate = new Date(idTokenResult.expirationTime);
            const timeToExpiration = (idTokenResult.claims.exp - idTokenResult.claims.auth_time) * 1000;
            const userEmail = idTokenResult.claims.email;

            await dispatch(authenticate(token, userId, timeToExpiration, userEmail));

            dispatch(userActions.setUser2020Data());

            saveDataToStorage(token, userId, expirationDate, userEmail);            

        } catch (error) {
            throw error
        }
    };
};


export const resetPassword = (email) => {
    return async dispatch => {
        try {
            await firebase.auth().sendPasswordResetEmail(email);
            dispatch({ type: PASSWORD_RESET });
        } catch (err) {
            throw err;
        }
    };
};

const clearLogoutTimer = () => {
    if (timer) {
        clearTimeout(timer);
    }
};

const setLogoutTimer = expirationTime => {
    return dispatch => {
        timer = setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
};

const saveDataToStorage = (token, userId, expirationDate, userEmail) => {
    AsyncStorage.setItem(
        'userData',
        JSON.stringify({
            token: token,
            userId: userId,
            expiryDate: expirationDate.toISOString(),
            email: userEmail
        })
    );
};

export const authenticate = (token, userId, expiryTime, userEmail) => {
    return async (dispatch) => {
        dispatch(setLogoutTimer(expiryTime));
        dispatch({ type: AUTHENTICATE, token: token, userId: userId, email: userEmail });

        // Permissions.getAsync(Permissions.NOTIFICATIONS)
        //     .then((statusObj) => {
        //         if (statusObj.status !== 'granted') {
        //             return
        //         } else {
        //             Notifications.getExpoPushTokenAsync()
        //                 .then((response) => {
        //                     createPushToken(response.data, token, userId);
        //                 })

        //         }
        //     })
    }
};


export const logout = () => {
    return async (dispatch) => {
        // dispatch(potentialActions.savePotentialData());
        clearLogoutTimer();
        AsyncStorage.removeItem('userData');
        dispatch({ type: LOGOUT });
    }
};

// const createPushToken = (pushToken, token, userId) => {

//     fetch(`https://shopapp-233d6.firebaseio.com/userTokens/${userId}.json?auth=${token}`, {
//         method: 'PATCH',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         //firebase authomatically creates an id
//         body: JSON.stringify({
//             expoPushToken: pushToken,
//         })
//     });
// };