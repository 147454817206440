import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    SafeAreaView,
    Animated,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const CharityDonationsScreen = props => {
    const donationData = useSelector(state => state.potential.donation);
    const donationAmountData = useSelector(state => state.potential.donationAmount);
    const [donation, setDonation] = useState(donationData);
    const [donationAmount, setDonationAmount] = useState(donationAmountData);
    const [donationAmountValid, setDonationAmountValid] = useState(donationAmountData ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true
        }).start();
      };
    
      const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: true
        }).start();
      };

    useEffect (() => {
        if ( donation === true ) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [donation])

    const dispatch = useDispatch();

    const inputChangeHandler = async (id, value, valid) => {
        setDonationAmount(value);
        setDonationAmountValid(valid);
        dispatch(potentialActions.setDonationAmount(value));
    };

    const selectionHandler = async (answer) => {
        setDonation(answer);
        dispatch(potentialActions.setDonation(answer));
        if (donationData === '') {
            if (answer === false) {
                props.navigation.navigate('WorkTransport');
            }
        }
    };

    const doneHandler = () => {
        if (donationData === false || donationAmountValid) {
            props.navigation.navigate('WorkTransport');
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard>
                    <View style={styles.questionContainer}>
                        <View style={styles.questionTextContainer}>
                            <DefaultText style={styles.questionText}>Did you make any charitable donations?</DefaultText>
                            <DefaultText style={styles.note}>Keep the receipt!</DefaultText>
                        </View>
                        <View style={styles.answerContainer}>
                            <View style={styles.yesNoContainer}>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(false)}
                                    style={donation === false ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={donation === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        No
                                </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(true)}
                                    style={donation === true ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={donation === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Yes
                            </DefaultText>
                                </TouchableItem>
                            </View>
                            {donation === true && (
                                <Animated.View 
                                style={[
                                    styles.inputContainer,
                                    {
                                      opacity: fadeAnim // Bind opacity to animated value
                                    }
                                  ]}>
                                    <DefaultInputMaskedMoney
                                        focus={true}
                                        id='donation'
                                        title="Donation amount:"
                                        errorMessage="Please enter a valid amount"
                                        placeHolder="1'000"
                                        keyboardType='numeric'
                                        onInputChange={inputChangeHandler}
                                        initialValue={donationAmount}
                                        initiallyValid={donationAmountValid}
                                        decimal
                                        required
                                    />
                                </Animated.View>
                            )}
                        </View>

                        <NavContainer
                                backShow={true}
                                nextShow={(donation === false || donationAmountValid === true)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                    </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
        </SafeAreaView>
    );
};

export const charityDonationsScreenOptions = navigationData => {
    return {
        title: "Charity Donation",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1,
        paddingTop: 20
    },
    note: {        
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        // padding: 10
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    questionTextContainer: {
        marginBottom: 40,
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default CharityDonationsScreen;