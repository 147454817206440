import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    SafeAreaView,
    Animated,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const FurtherEducationScreen = props => {
    const furtherEducationData = useSelector(state => state.potential.furtherEducation);
    const furtherEducationAmountData = useSelector(state => state.potential.furtherEducationAmount);
    const [furtherEducation, setFurtherEducation] = useState(furtherEducationData);
    const [furtherEducationAmount, setFurtherEducationAmount] = useState(furtherEducationAmountData);
    const [furtherEducationAmountValid, setFurtherEducationAmountValid] = useState(furtherEducationAmountData ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (furtherEducation === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [furtherEducation])

    const dispatch = useDispatch();

    const inputChangeHandler = async (id, value, valid) => {
        setFurtherEducationAmount(value);
        setFurtherEducationAmountValid(valid);
        dispatch(potentialActions.setFurtherEducationAmount(value));
    };

    const selectionHandler = async (answer) => {
        setFurtherEducation(answer);
        dispatch(potentialActions.setFurtherEducation(answer));
        if (furtherEducationData === '') {
            if (answer === false) {
                props.navigation.navigate('Pillar2');
            }
        }
    };

    const doneHandler = () => {
        if (furtherEducation === false || furtherEducationAmountValid) {
            props.navigation.navigate('Pillar2');
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard>
                    <View style={styles.questionContainer}>
                        <View style={styles.questionTextContainer}>
                        <DefaultText style={styles.questionText}>Did you pay for professional education ?</DefaultText>
                        <DefaultText style={styles.note}>Did you learn a skill or language and paid the courses yourself? Learning to code, design, or anything relevant to your job?</DefaultText>
                        </View>
                        <View style={styles.answerContainer}>
                            <View style={styles.yesNoContainer}>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(false)}
                                    style={furtherEducation === false ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={furtherEducation === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        No
                                </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(true)}
                                    style={furtherEducation === true ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={furtherEducation === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Yes
                            </DefaultText>
                                </TouchableItem>
                            </View>
                            {furtherEducation === true && (
                                <Animated.View 
                                style={[
                                    styles.inputContainer,
                                    {
                                      opacity: fadeAnim // Bind opacity to animated value
                                    }
                                  ]}>
                                    <DefaultInputMaskedMoney
                                        focus={!(furtherEducationAmountData > 0)}
                                        id='furtherEducation'
                                        title="Further Education Amount:"
                                        errorMessage="Please enter a valid amount"
                                        placeHolder="1'000"
                                        keyboardType='numeric'
                                        onInputChange={inputChangeHandler}
                                        initialValue={furtherEducationAmount}
                                        initiallyValid={furtherEducationAmountValid}
                                        decimal
                                        required
                                    />
                                </Animated.View>
                            )}
                        </View>

                        <NavContainer
                                backShow={true}
                                nextShow={(furtherEducation === false || furtherEducationAmountValid === true)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                    </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
        </SafeAreaView>
    );
};

export const furtherEducationScreenOptions = navigationData => {
    return {
        title: "Work Related Further Education",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    note: {
        paddingHorizontal: 10,  
        marginBottom: 2,
        fontFamily: 'lato'
    },
    insideScreen: {
        padding: 10
    },
    inputContainer: {
        flex: 1,
        paddingTop: 20
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    questionTextContainer: {
        marginBottom: 40,
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default FurtherEducationScreen;