import {
    SET_HAS_CHILDREN,
    SET_OTHER_INCOME_TYPES,
    SET_OTHER_INCOME_AMOUNT,
    SET_DEBT_INTERESTS,
    SET_DEBT_INTERESTS_AMOUNT,
    SET_FURTHER_EDUCATION_AMOUNT,
    SET_FURTHER_EDUCATION,
    SET_HEALTH_COST,
    SET_HEALTH_COST_AMOUNT,
    SET_DISABILITY_COST,
    SET_DISABILITY_COST_AMOUNT,
    SET_DONATION,
    SET_DONATION_AMOUNT,
    SET_TRANSPORT_TYPES,
    SET_WEEKLY_STAYS_AMOUNT,
    SET_WEEKLY_STAYS,
    SET_ALIMONY_PAID_AMOUNT,
    SET_ALIMONY_PAID,
    SET_SUPPORT_AMOUNT,
    SET_SUPPORT,
    SET_PILLAR2,
    SET_PILLAR2_AMOUNT,
    SET_PILLAR3A,
    SET_PILLAR3A_AMOUNT,
    SET_CHILDCARE_AMOUNT,
    SET_CHILDCARE,
    SET_VEHICLE_AMOUNT,
    SET_POTENTIAL,
    SET_CASH_FROM_TAS_INCOME,
    SET_CHILD_COUNT,
    SET_POT_MARITAL_STATUS,
    SET_POT_PARTNER_ABROAD,
    SET_POT_PARTNER_TAX_STATUS,
    SET_PARTNER_CASH_FROM_TAS_INCOMES,
    SET_PARTNER_HAS_INCOME,
    SET_CANTON,
    SET_INITIAL_POTENTIAL_DATA_FROM_POTENTIAL,
    SET_INITIAL_POTENTIAL_DATA_FROM_RELEVANCY,
    SET_RELIGION,
    SET_PAID_FOR_WEEK_STAYS,
    SET_FULL_YEAR_IN_CH,
    SET_MONTH_IN_CH,
    SET_USER_HAS_TAS_INCOME_IN_CH,
    SET_WEALTH_OVER_100,
    SET_WEALTH_AMOUNT,
    SET_WORK_ABROAD_AMOUNT,
    SET_WORK_ABROAD,
    SET_USER_HAS_VARIANCE,
    SET_PARTNER_HAS_VARIANCE,
    SET_USER_PERMIT,
    SET_NON_COVERED_CANTON,
    SET_INVESTMENT_INCOME,
    SET_INVESTMENT_INCOME_AMOUNT
} from '../actions/potential';

const initialState = {
    maritalStatus: '',
    partnerAbroad: '',
    partnerTaxStatus: '',
    hasChildren: '',
    otherIncomeTypes: [],
    otherIncomeAmount: '',
    debtInterests: '',
    debtInterestsAmount: '',
    furtherEducationAmount: '',
    furtherEducation: '',
    healthCostAmount: '',
    healthCost: '',
    disabilityCostAmount: '',
    disabilityCost: '',
    donation: '',
    donationAmount: '',
    transportTypes: [],
    weeklyStaysAmount: '',
    weeklyStays: '',
    paidForWeeklyStays: '',
    alimonyPaidAmount: '',
    alimonyPaid: '',
    supportAmount: '',
    support: '',
    pillar3aAmount: '',
    pillar3a: '',
    pillar2Amount: '',
    pillar2: '',
    childcareAmount: '',
    childcare: '',
    vehicleAmount: '',
    potential: '',
    cashFromTasIncomes: '',
    childCount: '',
    partnerHasIncome: '',
    partnerCashFromTasIncomes: '',
    canton: '',
    religion: '',
    tasRateInitial: '',
    tasRateAfterDeduction: '',
    initialMonthlyTaxableIncomeUsed: '',
    afterDeductionMonthlyTaxableIncomeUsed: '',
    monthInCh: '',
    fullYearInCh: '',
    userHasTasIncomeInCh: '',
    hasWealthOver100: '',
    wealthAmount: '',
    workAbroadAmount: '',
    workAbroad: '',
    taxedIncome: '',
    taxableIncomeForRateAfterRetreatment: '',
    potentialDeduction: '',
    disclaimers: [],
    userHasVariance: '',
    partnerHasVariance: '',
    userPermit: '',
    nonCoveredCanton: '',
    // investmentIncome: '',
    // investmentIncomeAmount: ''
};

const potentialReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_INVESTMENT_INCOME:
            return {
                ...state,
                investmentIncome: action.data
            };
        case SET_INVESTMENT_INCOME_AMOUNT:
            return {
                ...state,
                investmentIncomeAmount: action.data
            };
        case SET_USER_PERMIT:
            return {
                ...state,
                userPermit: action.data
            };
        case SET_NON_COVERED_CANTON:
            return {
                ...state,
                nonCoveredCanton: action.data
            };
        case SET_USER_HAS_VARIANCE:
            return {
                ...state,
                userHasVariance: action.data,
            };

        case SET_PARTNER_HAS_VARIANCE:
            return {
                ...state,
                partnerHasVariance: action.data,
            };

        case SET_WORK_ABROAD:
            return {
                ...state,
                workAbroad: action.data,
            };

        case SET_WORK_ABROAD_AMOUNT:
            return {
                ...state,
                workAbroadAmount: action.data,
            };

        case SET_WEALTH_OVER_100:
            return {
                ...state,
                hasWealthOver100: action.data,
            };
        case SET_WEALTH_AMOUNT:
            return {
                ...state,
                wealthAmount: action.data,
            };

        case SET_USER_HAS_TAS_INCOME_IN_CH:
            return {
                ...state,
                userHasTasIncomeInCh: action.data,
            };
        case SET_FULL_YEAR_IN_CH:
            return {
                ...state,
                fullYearInCh: action.data,
            };
        case SET_MONTH_IN_CH:
            return {
                ...state,
                monthInCh: action.data,
            };
        case SET_INITIAL_POTENTIAL_DATA_FROM_POTENTIAL:
            const newState = action.data;
            return {
                ...newState
            };
        case SET_INITIAL_POTENTIAL_DATA_FROM_RELEVANCY:
            return {
                ...state,
                maritalStatus: action.data.maritalStatus,
                partnerAbroad: action.data.partnerAbroad,
                partnerTaxStatus: action.data.partnerTaxStatus,
            };
        case SET_POTENTIAL:
            return {
                ...state,
                potential: action.potential,
                tasRateInitial: action.initialRate,
                tasRateAfterDeduction: action.afterDeductionRate,
                initialMonthlyTaxableIncomeUsed: action.initialMonthlyTaxableIncomeUsed,
                afterDeductionMonthlyTaxableIncomeUsed: action.afterDeductionMonthlyTaxableIncomeUsed,
                taxedIncome: action.taxedIncome,
                taxableIncomeForRateAfterRetreatment: action.taxableIncomeForRateAfterRetreatment,
                potentialDeduction: action.potentialDeduction,
                disclaimers: action.disclaimers
            };
        case SET_CANTON:
            return {
                ...state,
                canton: action.data,
            }
        case SET_PAID_FOR_WEEK_STAYS:
            return {
                ...state,
                paidForWeeklyStays: action.data,
            };
        case SET_CASH_FROM_TAS_INCOME:
            return {
                ...state,
                cashFromTasIncomes: action.data,
            };
        case SET_RELIGION:
            return {
                ...state,
                religion: action.data,
            };
        case SET_HAS_CHILDREN:
            return {
                ...state,
                hasChildren: action.data,
            }

        case SET_OTHER_INCOME_TYPES:
            return {
                ...state,
                otherIncomeTypes: action.data,
            }
        case SET_CHILD_COUNT:
            return {
                ...state,
                childCount: action.data,
            }
        case SET_OTHER_INCOME_AMOUNT:
            return {
                ...state,
                otherIncomeAmount: action.data,
            }

        case SET_DEBT_INTERESTS:
            return {
                ...state,
                debtInterests: action.data,
            }
        case SET_DEBT_INTERESTS_AMOUNT:
            return {
                ...state,
                debtInterestsAmount: action.data,
            }

        case SET_FURTHER_EDUCATION:
            return {
                ...state,
                furtherEducation: action.data,
            }
        case SET_FURTHER_EDUCATION_AMOUNT:
            return {
                ...state,
                furtherEducationAmount: action.data,
            }
        case SET_HEALTH_COST:
            return {
                ...state,
                healthCost: action.data,
            }
        case SET_HEALTH_COST_AMOUNT:
            return {
                ...state,
                healthCostAmount: action.data,
            }
        case SET_DISABILITY_COST:
            return {
                ...state,
                disabilityCost: action.data,
            }
        case SET_DISABILITY_COST_AMOUNT:
            return {
                ...state,
                disabilityCostAmount: action.data,
            }
        case SET_DONATION:
            return {
                ...state,
                donation: action.data,
            }
        case SET_DONATION_AMOUNT:
            return {
                ...state,
                donationAmount: action.data,
            }
        case SET_TRANSPORT_TYPES:
            return {
                ...state,
                transportTypes: action.data,
            }

        case SET_WEEKLY_STAYS_AMOUNT:
            return {
                ...state,
                weeklyStaysAmount: action.data,
            }
        case SET_WEEKLY_STAYS:
            return {
                ...state,
                weeklyStays: action.data,
            }

        case SET_ALIMONY_PAID_AMOUNT:
            return {
                ...state,
                alimonyPaidAmount: action.data,
            }
        case SET_ALIMONY_PAID:
            return {
                ...state,
                alimonyPaid: action.data,
            }
        case SET_SUPPORT_AMOUNT:
            return {
                ...state,
                supportAmount: action.data,
            }
        case SET_SUPPORT:
            return {
                ...state,
                support: action.data,
            }
        case SET_PILLAR2:
            return {
                ...state,
                pillar2: action.data,
            }
        case SET_PILLAR2_AMOUNT:
            return {
                ...state,
                pillar2Amount: action.data,
            }

        case SET_PILLAR3A:
            return {
                ...state,
                pillar3a: action.data,
            }
        case SET_PILLAR3A_AMOUNT:
            return {
                ...state,
                pillar3aAmount: action.data,
            }
        case SET_CHILDCARE_AMOUNT:
            return {
                ...state,
                childcareAmount: action.data,
            }
        case SET_CHILDCARE:
            return {
                ...state,
                childcare: action.data,
            }
        case SET_VEHICLE_AMOUNT:
            return {
                ...state,
                vehicleAmount: action.data,
            }

        case SET_POT_MARITAL_STATUS:
            return {
                ...state,
                maritalStatus: action.data,
            }
        case SET_POT_PARTNER_ABROAD:
            return {
                ...state,
                partnerAbroad: action.data,
            }
        case SET_POT_PARTNER_TAX_STATUS:
            return {
                ...state,
                partnerTaxStatus: action.data,
            }
        case SET_PARTNER_CASH_FROM_TAS_INCOMES:
            return {
                ...state,
                partnerCashFromTasIncomes: action.data,
            }
        case SET_PARTNER_HAS_INCOME:
            return {
                ...state,
                partnerHasIncome: action.data,
            }
        default:
            return state;
    }
    return state;
};

export default potentialReducer;