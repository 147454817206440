import React, { useState, useEffect, useCallback, useRef } from 'react';
import { StyleSheet, View, ActivityIndicator, ScrollView, SafeAreaView, Dimensions, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import DefaultDropdown from '../../../components/DefaultDropdown';
import NavContainer from '../../../components/NavContainer';

import * as potentialActions from '../../../store/actions/potential';

const ReligionScreen = props => {
    const religionData = useSelector(state => state.potential.religion);
    const [religion, setReligion] = useState(religionData);
    const [religionValid, setReligionValid] = useState(religionData ? true : false);

    const dispatch = useDispatch();

    const religionOptions = [
        "None",
        "Reformed",
        "Roman catholic",
        "Christ catholic",
        "Other"
    ];

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    const inputChangeHandler = useCallback((inputId, inputValue, inputValidity) => {
        setReligion(inputValue);
        setReligionValid(inputValidity);
        if (inputValidity) {
            dispatch(potentialActions.setReligion(inputValue));
        }
    }, [religion, religionValid]);

    const doneHandler = () => {
        props.navigation.navigate('Children');
    };

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>What church are you affiliated to?</DefaultText>
                    <View style={styles.answerContainer}>
                        <DefaultDropdown
                            id='religion'
                            title=""
                            data={religionOptions}
                            required
                            onInputChange={inputChangeHandler}
                            defaultValue=" "
                            minLength={3}
                            initialValue={religion}
                            initiallyValid={true}
                        />
                    </View>

                    <NavContainer
                        backShow={true}
                        nextShow={true}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const religionScreenOptions = navigationData => {
    return {
        title: "Your Religion",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 60,
        padding: 10,
    },    
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default ReligionScreen;