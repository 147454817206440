import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import TaSinChScreen, { taSinChScreenOptions } from '../../screens/hello/TaSinChScreen';
import PermitScreen, { permitScreenOptions } from '../../screens/hello/archive/PermitScreen';
import Over120Screen, { over120ScreenOptions } from '../../screens/hello/archive/Over120Screen';
import IncomeAbroadScreen, { incomeAbroadScreenOptions } from '../../screens/hello/archive/IncomeAbroadScreen';
import IncomeAbroadTypesScreen, { incomeAbroadTypesScreenOptions } from '../../screens/hello/archive/IncomeAbroadTypesScreen';
import MaritalStatusScreen, { maritalStatusScreenOptions } from '../../screens/hello/archive/MaritalStatusScreen';
import LiveAbroadScreen, { liveAbroadScreenOptions } from '../../screens/hello/archive/LiveAbroadScreen';
import LiveAbroadFullYearScreen, { liveAbroadFullYearScreenOptions } from '../../screens/hello/archive/LiveAbroadFullYearScreen';
import MoreInAndOutScreen, { moreInAndOutScreenOptions } from '../../screens/hello/archive/MoreInAndOutScreen';
import MoveInDateScreen, { moveInDateScreenOptions } from '../../screens/hello/archive/MoveInDateScreen';
import MoveOutDateScreen, { moveOutDateScreenOptions } from '../../screens/hello/archive/MoveOutDateScreen';
import CheckTaxdoneWebsiteScreen, { checkTaxdoneWebsiteScreenOptions } from '../../screens/CheckTaxdoneWebsiteScreen';
import ResultAndSignupScreen, { resultAndSignupScreenOptions } from '../../screens/hello/ResultAndSignupScreen';
import PartnerAbroadScreen, { partnerAbroadScreenOptions } from '../../screens/hello/archive/PartnerAbroadScreen';
import PartnerTaxStatusScreen, { partnerTaxStatusScreenOptions } from '../../screens/hello/archive/PartnerTaxStatusScreen';
import InitialUserScreen, { initialUserScreenOptions } from '../../screens/InitialUserScreen';
import TaxYearScreen, { taxYearScreenOptions } from '../../screens/hello/archive/TaxYearScreen';

import ResetPasswordScreen, { resetPasswordScreenOptions } from '../../screens/ResetPasswordScreen';
import AuthScreen, { authScreenOptions } from '../../screens/AuthScreen';

import { loggedOutDefaultNavOptions } from './defaultNavOptions';

const HelloStackNavigator = createStackNavigator();

export const HelloNavigator = () => {
    return <HelloStackNavigator.Navigator 
            initialRouteName="Initial"
            screenOptions={loggedOutDefaultNavOptions}>
        <HelloStackNavigator.Screen
            name="Initial"
            component={InitialUserScreen}
            options={initialUserScreenOptions} 
        />
        <HelloStackNavigator.Screen
            name="TaxYear"
            component={TaxYearScreen}
            options={taxYearScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="TaSinCh"
            component={TaSinChScreen}
            options={taSinChScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="Permit"
            component={PermitScreen}
            options={permitScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="Over120"
            component={Over120Screen}
            options={over120ScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="IncomeAbroad"
            component={IncomeAbroadScreen}
            options={incomeAbroadScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="MaritalStatus"
            component={MaritalStatusScreen}
            options={maritalStatusScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="LiveAbroad"
            component={LiveAbroadScreen}
            options={liveAbroadScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="LiveAbroadFull"
            component={LiveAbroadFullYearScreen}
            options={liveAbroadFullYearScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="MoreInAndOut"
            component={MoreInAndOutScreen}
            options={moreInAndOutScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="MoveInDate"
            component={MoveInDateScreen}
            options={moveInDateScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="MoveOutDate"
            component={MoveOutDateScreen}
            options={moveOutDateScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="IncomeAbroadTypes"
            component={IncomeAbroadTypesScreen}
            options={incomeAbroadTypesScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="CheckTaxdoneWebsite"
            component={CheckTaxdoneWebsiteScreen}
            options={checkTaxdoneWebsiteScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="ResultAndSignUp"
            component={ResultAndSignupScreen}
            options={resultAndSignupScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="PartnerAbroad"
            component={PartnerAbroadScreen}
            options={partnerAbroadScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="PartnerTaxStatus"
            component={PartnerTaxStatusScreen}
            options={partnerTaxStatusScreenOptions}
        />
        <HelloStackNavigator.Screen
            name="Auth"
            component={AuthScreen}
            options={authScreenOptions} 
        />
        <HelloStackNavigator.Screen
            name="PasswordReset"
            component={ResetPasswordScreen}
            options={resetPasswordScreenOptions} 
        />
    </HelloStackNavigator.Navigator>
};
