import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Platform,
    FlatList,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';

const OrdersScreen = props => {
    const userOrders = useSelector(state => state.user.userOrders);

    const userPaidOrders = ! userOrders ? null : userOrders.filter(u => u.status === 'paid')

    const dispatch = useDispatch();

    const renderGridItem = itemData => {
        return (
            <View style={styles.orderItem} >
                <DefaultText>Tax year: {itemData.item.taxYear}</DefaultText>
                <DefaultText>Paid: {itemData.item.currency} {itemData.item.amount}</DefaultText>
                {itemData.item.preOrder &&(
                <DefaultText>This is a pre-order</DefaultText>
                )}
            </View>
        )
    }

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView>
            { ! userPaidOrders || userPaidOrders.length === 0 ?
                <View style={styles.noOrdersContainer}>
                    <DefaultText style={styles.mainTitle}>No orders found</DefaultText>
                </View>
                :
                <View style={styles.mainContainer}>
                    <DefaultText style={styles.mainTitle}>Your orders</DefaultText>

                    <FlatList
                        style={styles.orderList}                        
                        data={userPaidOrders}
                        keyExtractor={item => item.id}
                        renderItem={renderGridItem}
                    />
                </View>
            }
            </ScrollView>
        </SafeAreaView>
    );
};


export const ordersScreenOptions = navigationData => {
    return {
        title: "Your Orders",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        justifyContent: 'center',        
    },
    noOrdersContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    potentialAction: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        maxHeight: 50
    },
    orderList: {
        marginTop: 20
    },
    orderItem: {
        borderWidth: 1,
        borderRadius: 10,
        padding: 10,
        marginVertical: 10,
        borderColor: Colors.buttonGreen
    },  
    mainTitle: {
        fontSize: 16,
        fontFamily: 'lato-bold',
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 10,
        color: Colors.darkBlue
    },
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch'
    },
    mainContainer: {
        justifyContent: 'space-evenly',
        padding: 10,
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    preOrderContainer: {
        height: 100,
        marginBottom: 10,
        justifyContent: 'space-evenly'
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 20
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginHorizontal: 30,
        marginBottom: 50
    },
    restartContainer: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginHorizontal: 30,
        marginTop: 10,
        flexDirection: 'row'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 18
    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
    successText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'center',
        padding: 10
    },
    successDesc1: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
    },
    successDesc: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
        paddingBottom: 20
    },
});

export default OrdersScreen;