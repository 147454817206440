import React, { useState } from 'react';
import { StyleSheet, View, Linking, Platform } from 'react-native';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';
import * as WebBrowser from 'expo-web-browser';

import HeaderButton from '../components/HeaderButton';
import Colors from '../constants/Colors';
import DefaultText from '../components/DefaultText';
import DefaultNextButton from '../components/DefaultNextButton';

const url = "https://hello.taxdone.ch";

const CheckTaxdoneWebsiteScreen = props => {

    return (
        <View style={styles.screen}>
            <View style={styles.questionContainer}>
                <DefaultText style={styles.questionText}>Hey, if you want us to fill your taxes or need advice, check us out here :</DefaultText>
                <View style={styles.answerContainer}>
                    <DefaultNextButton 
                        title={'Go to Taxdone.ch'}
                        onNext={() => {
                        if (Platform.OS === 'web') {
                        WebBrowser.openBrowserAsync(url)
                    } else {
                        Linking.openURL(url)
                    }
                    }}
                    />
                </View>
            </View>
        </View>
    );
};

export const checkTaxdoneWebsiteScreenOptions = navigationData => {
    return {
        headerLeft: () =>
        <HeaderButtons HeaderButtonComponent={HeaderButton} >
            <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                navigationData.navigation.goBack();
            }} />
        </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        alignItems: 'center',
        justifyContent: 'center'
    }, 
    questionContainer: {
        margin: 20,
        alignItems: 'center',
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50
    },
    answerContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        minHeight: 50
    },
    submitButton: {
        fontFamily: 'lato-bold',
        color: 'white',
        backgroundColor: Colors.darkGreen,
        borderRadius: 5,
        minWidth: 100,
        padding: 10,
        textAlign: 'center',
        overflow: 'hidden',
        marginTop: 30
    },
    logo: { 
        marginBottom: '40%',
        width: 50, 
        height: 50 }
});

export default CheckTaxdoneWebsiteScreen;