import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    Linking,
    Platform,
    SafeAreaView
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';
import * as WebBrowser from 'expo-web-browser';

import HeaderButton from '../../components/HeaderButton';
import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';
import TouchableItem from '../../components/TouchableItem';

import * as authActions from '../../store/actions/auth';

const MyAccountScreen = props => {
    const url = "https://www.taxdone.ch/privacy";
    const userEmail = useSelector(state => state.auth.email)

    const dispatch = useDispatch();

    useEffect(() => {
        props.navigation.setOptions({
            headerRight: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item prod="material" iconName="logout" onPress={async () => {
                        dispatch(authActions.logout());
                        props.navigation.pop();
                    }} />
                </HeaderButtons>
        });
    }, []);

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView>
            <View style={styles.questionContainer}>
                <DefaultText style={styles.questionText}>{userEmail}</DefaultText>
                {/* <TouchableItem style={styles.subcontainer}>
                    <DefaultText>Refer a friend</DefaultText>
                </TouchableItem> */}
                <TouchableItem 
                style={styles.subcontainer}
                onSelectItem={() => {props.navigation.navigate('Orders')}}
                >
                    <DefaultText>Your orders</DefaultText>
                </TouchableItem>                
                <TouchableItem 
                style={styles.subcontainer}
                onSelectItem={() => {
                    if ( Platform.OS === 'web' ) {
                        WebBrowser.openBrowserAsync(url)
                    } else {
                        props.navigation.navigate('PrivacyPolicy')
                    }
                }}
                >
                    <DefaultText>Privacy policy</DefaultText>
                </TouchableItem>
                <TouchableItem 
                style={styles.subcontainer}
                onSelectItem={() => {props.navigation.navigate('TandC')}}
                >
                    <DefaultText>Terms and conditions</DefaultText>
                </TouchableItem>
                <TouchableItem 
                    style={styles.subcontainer}
                    onSelectItem={() => {props.navigation.navigate('Imprint')}}
                    >
                    <DefaultText>Imprint</DefaultText>
                </TouchableItem>            
            </View>
        </ScrollView>
        </SafeAreaView>
    );
};

export const myAccountScreenOptions = navigationData => {
    return {
        title: "Your Account",
        headerLeft: () =>
            <HeaderButtons HeaderButtonComponent={HeaderButton} >
                <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                    navigationData.navigation.goBack();
                }} />
            </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: 'center'
    },
    questionContainer: {
        padding: 10,
        flex: 1,
        minHeight: Dimensions.get('window').height * 0.8,
        justifyContent: 'flex-start',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    subcontainer: {
        borderColor: Colors.darkBlue,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
        marginVertical: 5,
        maxHeight: 50
    },
    inputContainer: {
        flex: 1
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        height: 70,
        marginBottom: 70
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default MyAccountScreen;