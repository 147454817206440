import React, { useState, useEffect, useRef } from 'react';
import { 
    StyleSheet, 
    View, 
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    SafeAreaView,
    Animated,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInput from '../../../components/DefaultInput';
import DefaultInputMasked from '../../../components/DefaultInputMaskedNotUsed';
import DefaultInputMaskedOther from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const DebtInterestsScreen = props => {
    const debtInterestsData = useSelector(state => state.potential.debtInterests);
    const debtInterestsAmountData = useSelector(state => state.potential.debtInterestsAmount);
    const [debtInterests, setDebtInterests] = useState(debtInterestsData);
    const [debtInterestsAmount, setDebtInterestsAmount] = useState(debtInterestsAmountData);
    const [debtInterestsAmountValid, setDebtInterestsAmountValid] = useState(debtInterestsAmountData ? true : false);

    const dispatch = useDispatch();

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });        
    })

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const inputChangeHandler = async (id, value, valid) => {
        setDebtInterestsAmount(value);
        setDebtInterestsAmountValid(valid);
        dispatch(potentialActions.setDebtInterestsAmount(value));
    };

    useEffect (() => {
        if ( debtInterests === true ) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [debtInterests])

    const selectionHandler = async (answer) => {
        setDebtInterests(answer);
        dispatch(potentialActions.setDebtInterests(answer));
        if (debtInterestsData === '') {
            if (answer === false) {
                props.navigation.navigate('HealthCosts');
            } 
        }
    };

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: true
        }).start();
      };
    
      const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: true
        }).start();
      };

    const doneHandler = () => {
        if (debtInterests === false || debtInterestsAmountValid) {
            props.navigation.navigate('HealthCosts');
        }
    };



    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard>
                    <View style={styles.questionContainer}>
                        <View style={styles.questionSubContainer}>
                <DefaultText style={styles.questionText}>Did you pay interest on debt you contracted?</DefaultText>
                <DefaultText style={styles.note}>Yearly interests for consumer loan, leasing, mortgage, etc.</DefaultText>
                    </View>
                <View style={styles.answerContainer}>
                    <View style={styles.yesNoContainer}>
                        <TouchableItem
                            onSelectItem={() => selectionHandler(false)}
                            style={debtInterests === false ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={debtInterests === false ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                No
                                </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={() => selectionHandler(true)}
                            style={debtInterests === true ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={debtInterests === true ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Yes
                            </DefaultText>
                        </TouchableItem>
                        </View>
                    {debtInterests === true && (
                        <Animated.View 
                        style={[
                            styles.inputContainer,
                            {
                              opacity: fadeAnim // Bind opacity to animated value
                            }
                          ]}>
                        <DefaultInputMaskedOther
                            focus={!(debtInterestsAmountData > 0)}
                            id='debtInterest'
                            title="Debt interests amount:"
                            errorMessage="Please enter a valid amount"
                            placeHolder="5'000"
                            keyboardType='numeric'
                            onInputChange={inputChangeHandler}
                            initialValue={debtInterestsAmount}
                            initiallyValid={debtInterestsAmountValid}
                            decimal
                            required
                        />
                        </Animated.View>
                    )}
                </View>

                <NavContainer
                                backShow={true}
                                nextShow={(debtInterests === false || debtInterestsAmountValid === true)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
        </SafeAreaView>
    );
};

export const debtInterestsScreenOptions = navigationData => {
    return {
        title: "Debt Interests",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    questionSubContainer: {
        marginBottom: 40,
    },
    inputContainer: {
        flex: 1,
        marginTop: 20
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default DebtInterestsScreen;