import axios from 'axios';

export const PRE_ORDER_CONFIRMED = 'PRE_ORDER_CONFIRMED';
export const SET_PREORDER_PRICE = 'SET_PREORDER_PRICE';

// import * as userActions from './user';

export const getPreOrderPrice = () => {
    return async (dispatch, getState) => {

        try {
            const res = await axios.get(`https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/prices/preorder`);

            if (res.status === 500) {
                throw new Error(res)
            }

            dispatch({ 
                type: SET_PREORDER_PRICE, 
                normalPrice: res.data.normalPrice,
                reduction: res.data.reduction,
                finalPrice: res.data.finalPrice,
            })

        } catch (err) {
            throw err;
        }
    };
};


// export const makePreOrderPayment = (token) => {
//     return async (dispatch, getState) => {
        
//         const userIdInfo = getState().auth.userId;
//         const userEmailInfo = getState().auth.email;

//         try {
//             const response = await axios({
//                 method: 'POST',
//                 url: 'https://us-central1-taxatsource-4a8c0.cloudfunctions.net/makePaymentWithStripe',
//                 data: {
//                     amount: 130,
//                     currency: 'chf',
//                     userId: userIdInfo,
//                     preOrder: true,
//                     year: '2020',
//                     token: token,
//                     userEmail: userEmailInfo
//                 }
//             })

//             const userId = getState().auth.userId;
//             const isPaid = response.data.paid;
//             const amountPaid = response.data.amount_captured;
//             const transactionCcy = response.data.currency;

//             if (response.status !== 200 || !isPaid) {
//                 throw new Error('Something went wrong with the payment')
//             }

//             await dispatch(userActions.confirmPreOrder(userId, isPaid, amountPaid, transactionCcy));

//         } catch (err) {
//             throw err;
//         }
//     };
// };

export const createStripSession = (platformOS) => {
    return async (dispatch, getState) => {
        
        const userIdInfo = getState().auth.userId;
        const userEmailInfo = getState().auth.email;

        try {
            const res = await axios.post(`https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/checkout`, {
                items: [{name: 'pre-order', quantity: 1}],
                platform: platformOS,
                email: userEmailInfo,
                user: userIdInfo,
                description: 'Your reclaim service pre-order for 2020',
                year: '2020',
                preOrder: true,
                currency: 'chf'
            });

            if (response.status === 500) {
                throw new Error(res)
            }

        } catch (err) {
            throw err;
        }
    };
};
