import React, { useEffect, useState, useReducer } from 'react';
import {
    View,
    StyleSheet,
    Platform, Button
} from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import DefaultText from '../components/DefaultText';
import TouchableItem from '../components/TouchableItem';

import Colors from '../constants/Colors';

const INPUT_CHANGE = 'INPUT_CHANGE';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                touched: true
            }
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            }
        default:
            return state;
    }
};

const DefaultDatePicker = props => {
    const { onInputChange, id, initialValue } = props;
    const [showCalendar, setShowCalendar] = useState(false);
    const [inputState, dispatchInputState] = useReducer(inputReducer, {
        value: initialValue ? new Date(initialValue) : new Date(),
        isValid: initialValue ? true : false,
        touched: false
    });

    const onChange = (event, selectedDate) => {
        Platform.OS === 'android' ? setShowCalendar(false) : null;
        const currentDate = selectedDate === undefined ? inputState.value : selectedDate;
        let isValid = true;
        dispatchInputState({
            type: INPUT_CHANGE,
            value: currentDate,
            isValid: isValid
        });
    };

    useEffect(() => {
        onInputChange(id, inputState.value, inputState.isValid);
    }, [inputState, onInputChange, id]);

    return (
        <View style={styles.inputContainer}>
            {Platform.OS === 'android' ? 
            <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
                <TouchableItem
                    onSelectItem={() => { setShowCalendar(!showCalendar) }}
                >
                    <View>
                        <DefaultText style={styles.inputTitleDate}>{inputState.value.toISOString().split('T')[0]}</DefaultText>
                    </View>
                </TouchableItem>
            </View>
            {showCalendar && (
                <DateTimePicker
                    textColor= {Colors.darkGreen}
                    value={inputState.value}
                    mode="date"
                    display={Platform.OS === 'android' ? "calendar" : "default"}
                    onChange={onChange}
                    minimumDate={props.minDate}
                    maximumDate={props.maxDate}
                />
            )}
            {!inputState.isValid && inputState.touched && (
                <View style={styles.errorContainer}>
                    <DefaultText style={styles.errorText}>{props.errorMessage}</DefaultText>
                </View>
            )}
            </View>
            :
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
                <DateTimePicker
                    textColor= {Colors.darkGreen}
                    style={{minWidth: 150}}
                    value={inputState.value}
                    mode="date"
                    display={"default"}
                    onChange={onChange}
                    minimumDate={props.minDate}
                    maximumDate={props.maxDate}
                />
            </View>
        }
        </View>
    )
};

const styles = StyleSheet.create({
    inputContainer: {
        marginVertical: 10,
        justifyContent: 'space-between',
        minWidth: '80%',
        borderRadius: 10,
        padding: 10,
    },
    inputTitle: {
        fontFamily: 'lato-bold',
        marginBottom: 10,
        color: Colors.primaryColor
    },
    inputTitleDate: {
        fontFamily: 'lato-bold',
        marginBottom: 10,
        color: Colors.darkGreen,
        textAlign: 'right',
    },
    picker: {
        width: '100%',
        justifyContent: 'flex-start',
    },
    errorContainer: {
        marginVertical: 5
    },
    errorText: {
        fontFamily: 'lato',
        fontSize: 13,
        color: 'red'
    }
});

export default DefaultDatePicker;

