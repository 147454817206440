import React from 'react';
import {
    StyleSheet,
    SafeAreaView,
    ImageBackground,
    Platform,
    View
} from 'react-native';

import DefaultText from '../components/DefaultText';
import TouchableItem from '../components/TouchableItem';

import Colors from '../constants/Colors';
import globalStyles from '../constants/Styles';

const InitialUserScreen = props => {

    return (
        <SafeAreaView style={styles.screen}>
            <DefaultText
                        style={styles.mmessage}
                    >
                        Taxed at Source in Zürich?
                </DefaultText>
            <ImageBackground
                style={styles.imageB}
                source={require('../assets/MoneyImage.png')}>
                <View>
                    <DefaultText
                        style={styles.mmessage}
                    >
                        Estimate Your Tax Refund                         
                </DefaultText>
                <DefaultText
                        style={styles.mmessage}
                    >
                        in 5 minutes !
                </DefaultText>
                </View>
                <TouchableItem style={styles.button} onSelectItem={() => {
                    props.navigation.navigate('TaSinCh');
                }}>
                    <DefaultText style={styles.buttonText}>Get Started for FREE</DefaultText>
                </TouchableItem>
            </ImageBackground>

            <TouchableItem style={styles.buttonSwitch} onSelectItem={() => {
                props.navigation.navigate('Auth');
            }}>
                <DefaultText style={styles.switchText}>I am already registered</DefaultText>
            </TouchableItem>
        </SafeAreaView>
    );
};

export const initialUserScreenOptions = navigationData => {
    return {
        title: "Hello",
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        padding: 10,
        backgroundColor: Colors.backColor,
        alignItems: 'center',
        justifyContent: Platform.OS === 'web' ? 'space-evenly' : 'space-evenly'
    },
    button: {
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        padding: 10,
        flex: 0,
        maxHeight: 40,
        justifyContent: 'center'
    },
    imageB: {
        width: 300,
        height: 300,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: Platform.OS === 'web' ? 0 : '20%'
    },
    buttonSwitch: {
        padding: 10,
        borderRadius: 20,
        backgroundColor: Colors.buttonGreen,
        marginBottom: 20,
        maxHeight: 40,
        justifyContent: 'center'
    },
    switchText: {
        color: Colors.darkBlue,
        fontFamily: 'lato-bold',
        fontSize: 16
    },
    switchTextTest: {
        color: 'red',
        fontFamily: 'lato-bold',
        fontSize: 16
    },
    mmessage: { 
        fontFamily: 'lato-bold', 
        textAlign: 'center', 
        fontSize: 20, 
        color: Colors.darkBlue 
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        // fontWeight: 'bold'
        fontSize: 16
    }
});

export default InitialUserScreen;