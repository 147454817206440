import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { enableScreens } from 'react-native-screens'
import * as Font from 'expo-font';
import { AppLoading } from 'expo';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import * as firebase from 'firebase';
import firebaseConfig from './config/firebase';
// import stripe from 'tipsi-stripe';

import AppNavigator from './navigation/AppNavigator';

import relevancyReducer from './store/reducers/relevancy';
import authReducer from './store/reducers/auth';
import potentialReducer from './store/reducers/potential';
import userReducer from './store/reducers/user';
import staticReducer from './store/reducers/static';
import paymentReducer from './store/reducers/payment';

enableScreens();

const fetchFonts = () => {
  return Font.loadAsync({
    'lato': require('./assets/fonts/OpenSans-Regular.ttf'),
    'lato-bold': require('./assets/fonts/OpenSans-Bold.ttf'),
    'lato-reg': require('./assets/fonts/Lato-Regular.ttf'),
    'lato-bold': require('./assets/fonts/Lato-Bold.ttf')
  });
};

const rootReducer = combineReducers({
  relevancy: relevancyReducer,
  auth: authReducer,
  user: userReducer,
  potential: potentialReducer,
  static: staticReducer,
  payment: paymentReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

firebase.initializeApp(firebaseConfig);

export default function App() {
  const [fontLoaded, setFontLoaded] = useState(false);

  if (!fontLoaded) {
    return (

      <AppLoading
        startAsync={fetchFonts}
        onFinish={() => setFontLoaded(true)}
        onError={(err) => console.log(err)}
      />
    );
  }

  return (    
    <Provider store={store}>
      <AppNavigator />
    </Provider>
  );
}

// const styles = StyleSheet.create({
// });
