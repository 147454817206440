import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Dimensions, ScrollView, SafeAreaView, Platform} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const Over120Screen = props => {
    const over120Data = useSelector(state => state.relevancy.over120);
    const [over120, setOver120] = useState(over120Data);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setOver120(answer);
        dispatch(relevancyActions.setOver120(answer));
        if (over120Data === '') {
            if (answer === false) {
                props.navigation.navigate('MaritalStatus');
            } else {
                props.navigation.navigate('CheckTaxdoneWebsite');
            }
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (over120 === false) {
            props.navigation.navigate('MaritalStatus');
        } else {
            props.navigation.navigate('CheckTaxdoneWebsite');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <View style={styles.questionContainer}>
                <DefaultText style={styles.questionText}>Did you earn more than CHF 120'000 on an annual basis?</DefaultText>
                <View style={styles.answerContainer}>
                    <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, false)}
                        style={over120 === false ? styles.activeAnswer : styles.passiveAnswer}
                        >
                        <DefaultText style={over120 === false ? styles.activeAnswerText : styles.passiveAnswerText}>No</DefaultText>
                    </TouchableItem>
                    <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, true)}
                        style={over120 === true ? styles.activeAnswer : styles.passiveAnswer}
                        >
                        <DefaultText style={over120 === true ? styles.activeAnswerText : styles.passiveAnswerText}>Yes</DefaultText>
                    </TouchableItem>
                </View>

                <NavContainer
                        backShow={true}
                        nextShow={over120 !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

            </View>
        </ScrollView>
        </SafeAreaView>
    );
};

export const over120ScreenOptions = navigationData => {
    return {
        headerLeft: () =>
                null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: Platform.OS === 'web' ? 600 : 0,
        maxWidth: 800
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 10
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        // minWidth: Platform.OS === 'web' ? Math.min((Dimensions.get('window').width - 1000) / 2, 250) : (Dimensions.get('window').width - 100) / 2,
        // maxWidth: (Dimensions.get('window').width - 100) / 2,
        width: Platform.OS === 'web' ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        // minWidth: Platform.OS === 'web' ? Math.min((Dimensions.get('window').width - 1000) / 2, 250) : (Dimensions.get('window').width - 100) / 2,
        // maxWidth: (Dimensions.get('window').width - 100) / 2,
        width: Platform.OS === 'web' ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },  
});

export default Over120Screen;