import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, FlatList, View, Image, Dimensions, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';

import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const MaritalStatusScreen = props => {
    const maritalStatusData = useSelector(state => state.relevancy.maritalStatus);
    const [maritalStatus, setMaritalStatus] = useState(maritalStatusData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setMaritalStatus(answer);
        await dispatch(relevancyActions.setMaritalStatus(answer));
        if (answer === 'Married') {
            props.navigation.navigate('PartnerAbroad');
        } else {
            props.navigation.navigate('ResultAndSignUp');
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (maritalStatus === 'Married') {
            props.navigation.navigate('PartnerAbroad');
        } else {
            props.navigation.navigate('ResultAndSignUp');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <View
                style={styles.questionContainer}
            >
                <DefaultText
                    style={styles.questionText}
                >
                    What was your marital status in Switzerland as of 31.12.2020?
    </DefaultText>
                <View style={styles.answerContainer}>
                    <View style={styles.answerContainerRow}>
                        <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, 'Single')}
                        style={maritalStatus === 'Single' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText style={maritalStatus === 'Single' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Single
                            </DefaultText>
                        </TouchableItem>

                        <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, 'Married')}
                        style={maritalStatus === 'Married' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={maritalStatus === 'Married' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Married
                            </DefaultText>
                        </TouchableItem>
                    </View>
                    <View style={styles.answerContainerRow}>
                        <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, 'Divorced')}
                        style={maritalStatus === 'Divorced' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={maritalStatus === 'Divorced' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Divorced
                            </DefaultText>
                        </TouchableItem>

                        <TouchableItem 
                        onSelectItem={selectionHandler.bind(this, 'Widowed')}
                        style={maritalStatus === 'Widowed' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={maritalStatus === 'Widowed' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Widowed
                            </DefaultText>
                        </TouchableItem>
                    </View>
                </View>

                <NavContainer
                        backShow={true}
                        nextShow={maritalStatus !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

            </View>
        </ScrollView>
        </SafeAreaView>
    );
};

export const maritalStatusScreenOptions = navigationData => {
    return {
        headerLeft: () =>
                null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: Platform.OS === 'web' ? 600 : 0
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        alignContent: 'stretch',
        flexDirection: 'row',
        padding: 10,
        minHeight: 140,
        maxHeight: 140,
        // marginBottom: Platform.OS === 'android' ? 0 : 0,
    },
    answerContainerRow: {
        justifyContent: 'space-between',
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        maxHeight: 50,
        width: Platform.OS === 'web' ? 250 : (Dimensions.get('window').width - 100) / 2,
        // maxWidth: (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        maxHeight: 50,
        width: Platform.OS === 'web' ? 250 : (Dimensions.get('window').width - 100) / 2,
        // maxWidth: (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default MaritalStatusScreen;