import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import HasChildrenScreen, { hasChildrenScreenOptions } from '../../screens/auth/potential/HasChildrenScreen';
import PartnerIncomeTaxedAtSourceScreen, { partnerIncomeTaxedAtSourceScreenOptions } from '../../screens/auth/potential/PartnerIncomeTaxedAtSourceScreen';
import MaritalSituationConfirmationScreen, { maritalSituationConfirmationScreenOptions } from '../../screens/auth/potential/MaritalSituationConfirmationScreen';

import CheckTaxdoneWebsiteScreen, { checkTaxdoneWebsiteScreenOptions } from '../../screens/auth/potential/CheckTaxdoneWebsiteScreen';

import CheckoutScreen, { checkoutScreenOptions } from '../../screens/auth/CheckoutScreen';
import HealthCostsScreen, { healthCostsScreenOptions } from '../../screens/auth/potential/HealthCostsScreen';
import Pillar2Screen, { pillar2ScreenOptions } from '../../screens/auth/potential/Pillar2Screen';
import Pillar3aScreen, { pillar3aScreenOptions } from '../../screens/auth/potential/Pillar3aScreen';
import SupportScreen, { supportScreenOptions } from '../../screens/auth/potential/SupportScreen';
import WorkTransportScreen, { workTransportScreenOptions } from '../../screens/auth/potential/WorkTransportScreen';
import WeekStaysForWorkScreen, { weekStaysForWorkScreenOptions } from '../../screens/auth/potential/WeekStaysForWorkScreen';
import DebtInterestsScreen, { debtInterestsScreenOptions } from '../../screens/auth/potential/DebtInterestsScreen';
import AlimonyPaidScreen, { alimonyPaidScreenOptions } from '../../screens/auth/potential/AlimonyPaidScreen';
import CharityDonationsScreen, { charityDonationsScreenOptions } from '../../screens/auth/potential/CharityDonationsScreen';
import ChildcareScreen, { childcareScreenOptions } from '../../screens/auth/potential/ChildcareScreen';
import DisabilityCostsScreen, { disabilityCostsScreenOptions } from '../../screens/auth/potential/DisabilityCostsScreen';
import FurtherEducationScreen, { furtherEducationScreenOptions } from '../../screens/auth/potential/FurtherEducationScreen';
import CantonScreen, { cantonScreenOptions } from '../../screens/auth/potential/CantonScreen';
import ReligionScreen, { religionScreenOptions } from '../../screens/auth/potential/ReligionScreen';
import TimeSpentInChScreen, { timeSpentInChScreenOptions } from '../../screens/auth/potential/TimeSpentInChScreen';
import WorkIncomeAbroadScreen, { workIncomeAbroadScreenOptions } from '../../screens/auth/potential/WorkIncomeAbroadScreen';
import UserSalaryVarianceScreen, { userSalaryVarianceScreenOptions } from '../../screens/auth/potential/UserSalaryVarianceScreen';
import OtherCantonScreen, { otherCantonScreenOptions } from '../../screens/auth/potential/OtherCantonScreen';
import WealthScreen, { wealthScreenOptions } from '../../screens/auth/potential/WealthScreen';
import InvestmentIncomeScreen, { investmentIncomeScreenOptions } from '../../screens/auth/potential/InvestmentIncomeScreen';

import CantonNotCoveredScreen, { cantonNotCoveredScreenOptions } from '../../screens/auth/potential/CantonNotCoveredScreen';

import IncomeTaxedAtSourceScreen, { incomeTaxedAtSourceScreenOptions } from '../../screens/auth/potential/IncomeTaxedAtSourceScreen';
import AnyOtherIncomeScreen, { anyOtherIncomeScreenOptions } from '../../screens/auth/potential/AnyOtherIncomeScreen';

import PotentialResultScreen, { potentialResultScreenOptions } from '../../screens/auth/potential/PotentialResultScreen';

import MyAccountScreen, { myAccountScreenOptions } from '../../screens/auth/MyAccountScreen';

import TaxAtSourceOverviewScreen, { taxAtSourceOverviewScreenOptions } from '../../screens/auth/TaxAtSourceOverviewScreen';

import PaymentScreen, {paymentScreenOptions} from '../../screens/auth/PaymentScreen';

import LoadingScreen, { loadingScreenOptions } from '../../screens/auth/LoadingScreen';

import ImprintScreen, { imprintScreenOptions } from '../../screens/auth/ImprintScreen';
import PrivacyPolicyScreen, { privacyPolicyScreenOptions } from '../../screens/auth/PrivacyPolicyScreen';
import TermsAndConditionsScreen, { termsAndConditionsScreenOptions } from '../../screens/auth/TermsAndConditionsScreen';

import UserPermitScreen, {userPermitScreenOptions } from '../../screens/auth/potential/UserPermitScreen';

import OrdersScreen, { ordersScreenOptions } from '../../screens/auth/OrdersScreen';

import ThankYouScreen, {thankYouScreenOptions} from '../../screens/auth/ThankYouScreen';

import { loggedInDefaultNavOptions } from './defaultNavOptions';

const LoggedInStackNavigator = createStackNavigator();

export const LoggedInNavigator = () => {

    return <LoggedInStackNavigator.Navigator
        initialRouteName="Loading"
        screenOptions={loggedInDefaultNavOptions}
    >
        <LoggedInStackNavigator.Screen
            name="Loading"
            component={LoadingScreen}
            options={loadingScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Religion"
            component={ReligionScreen}
            options={religionScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="MaritalStatusConfirmation"
            component={MaritalSituationConfirmationScreen}
            options={maritalSituationConfirmationScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="PartnerIncomeTas"
            component={PartnerIncomeTaxedAtSourceScreen}
            options={partnerIncomeTaxedAtSourceScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="IncomeTas"
            component={IncomeTaxedAtSourceScreen}
            options={incomeTaxedAtSourceScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="OtherIncome"
            component={AnyOtherIncomeScreen}
            options={anyOtherIncomeScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="NotCovered"
            component={CheckTaxdoneWebsiteScreen}
            options={checkTaxdoneWebsiteScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Children"
            component={HasChildrenScreen}
            options={hasChildrenScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="HealthCosts"
            component={HealthCostsScreen}
            options={healthCostsScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Pillar2"
            component={Pillar2Screen}
            options={pillar2ScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Pillar3a"
            component={Pillar3aScreen}
            options={pillar3aScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Support"
            component={SupportScreen}
            options={supportScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="WorkTransport"
            component={WorkTransportScreen}
            options={workTransportScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="WeekStaysForWork"
            component={WeekStaysForWorkScreen}
            options={weekStaysForWorkScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="DebtInterests"
            component={DebtInterestsScreen}
            options={debtInterestsScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="AlimonyPaid"
            component={AlimonyPaidScreen}
            options={alimonyPaidScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Donation"
            component={CharityDonationsScreen}
            options={charityDonationsScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Childcare"
            component={ChildcareScreen}
            options={childcareScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Disability"
            component={DisabilityCostsScreen}
            options={disabilityCostsScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Education"
            component={FurtherEducationScreen}
            options={furtherEducationScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Result"
            component={PotentialResultScreen}
            options={potentialResultScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="MyAccount"
            component={MyAccountScreen}
            options={myAccountScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="TasOverview"
            component={TaxAtSourceOverviewScreen}
            options={taxAtSourceOverviewScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Canton"
            component={CantonScreen}
            options={cantonScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="OtherCanton"
            component={OtherCantonScreen}
            options={otherCantonScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Imprint"
            component={ImprintScreen}
            options={imprintScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="TandC"
            component={TermsAndConditionsScreen}
            options={termsAndConditionsScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="PrivacyPolicy"
            component={PrivacyPolicyScreen}
            options={privacyPolicyScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Orders"
            component={OrdersScreen}
            options={ordersScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="CantonNotCovered"
            component={CantonNotCoveredScreen}
            options={cantonNotCoveredScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Payment"
            component={PaymentScreen}
            options={paymentScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Thanks"
            component={ThankYouScreen}
            options={thankYouScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Checkout"
            component={CheckoutScreen}
            options={checkoutScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="TimeInCh"
            component={TimeSpentInChScreen}
            options={timeSpentInChScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Wealth"
            component={WealthScreen}
            options={wealthScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="WorkAbroad"
            component={WorkIncomeAbroadScreen}
            options={workIncomeAbroadScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="Variance"
            component={UserSalaryVarianceScreen}
            options={userSalaryVarianceScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="UserPermit"
            component={UserPermitScreen}
            options={userPermitScreenOptions}
        />
        <LoggedInStackNavigator.Screen
            name="InvestmentIncome"
            component={InvestmentIncomeScreen}
            options={investmentIncomeScreenOptions}
        />
    </LoggedInStackNavigator.Navigator>
};
