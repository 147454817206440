import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
    StyleSheet,
    View,
    KeyboardAvoidingView,
    Platform,
    ScrollView,
    ActivityIndicator,
    Image,
    Alert,
    Dimensions,
    SafeAreaView
} from 'react-native';
import { useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import HeaderButton from '../../components/HeaderButton';
import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';
import DefaultInput from '../../components/DefaultInput';
import TouchableItem from '../../components/TouchableItem';

import * as authActions from '../../store/actions/auth';

const FORM_UPDATE = 'FORM_UPDATE';

const formReducer = (state, action) => {
    if (action.type === FORM_UPDATE) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        };
        const updatedValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updatedValidities) {
            updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
        }
        return {
            formIsValid: updatedFormIsValid,
            inputValues: updatedValues,
            inputValidities: updatedValidities
        };
    }
    return state;
};

const ResultAndSignupScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [showError, setShowError] = useState(false);

    const dispatch = useDispatch();

    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: {
            email: '',
            password: '',
        },
        inputValidities: {
            email: false,
            password: false,
        },
        formIsValid: false,
    });

    const inputChangeHandler = useCallback((inputId, inputValue, inputValidity) => {
        dispatchFormState({
            type: FORM_UPDATE,
            value: inputValue,
            isValid: inputValidity,
            input: inputId
        });
    }, [dispatchFormState]);

    const signUpHandler = useCallback(async () => {
        if (!formState.formIsValid) {
            Alert.alert('Invalid title', 'Please check the error in the form',
                [{ text: 'Ok' }])
            return;
        }
        setError(null);
        setShowError(false);
        setIsLoading(true);
        try {
            await dispatch(authActions.signup(
                formState.inputValues.email,
                formState.inputValues.password,
            ));
        } catch (err) {
            setError(err.message);
            setShowError(true);
            setIsLoading(false);
        }
    }, [dispatch,
        formState
    ]);

    useEffect(() => {
        if (error) {
            Alert.alert('An issue has occured', error, [{ text: 'ok' }]);
        }
    }, [error]);

    return (        
        <KeyboardAvoidingView
        behavior={Platform.OS === 'android' ? 'padding' : 'padding'}
        enabled={Platform.OS === 'android' ? false : true}
        keyboardVerticalOffset={Platform.OS === 'android' && Platform.Version >= 21 ? 0 : 50}
        style={styles.screen}
    >

        <View style={{width : '100%', alignItems: 'center'}}>
                        <View style={styles.imageContainer}>
                            <Image
                                style={styles.image}
                                source={require('../../assets/DeadlineImage.png')}>
                            </Image>
                            <View style={{ height: 100, justifyContent: 'center' }}>
                                <DefaultText style={styles.title}>
                                    Signup for a free refund estimate!
                        </DefaultText>
                                <DefaultText style={styles.title}>
                                    We will help you claim it.
                            </DefaultText>
                            </View>
                        </View>
        
        {isLoading ?

            <ActivityIndicator size='large' color={Colors.primaryColor} /> :
            
            <View style={styles.authContainer}>
                { Platform.OS === 'web' && showError ?

                    <View style={{ alignItems: 'center' }}>
                        <DefaultText style={{ marginBottom: 10, textAlign: 'center', fontFamily: 'lato-bold', fontSize: 15 }}>Something went wrong !</DefaultText>
                        <DefaultText style={{ marginBottom: 20, marginHorizontal: 30 }}>{error}</DefaultText>
                        <TouchableItem
                            style={styles.buttonOk}
                            onSelectItem={() => { setShowError(false) }}>
                            <DefaultText
                                style={styles.buttonText}
                            >ok</DefaultText>
                        </TouchableItem>
                    </View>

                    :

                    <ScrollView>                        
                        <DefaultInput
                            id='email'
                            title="E-mail"
                            keybordType="email-address"
                            required
                            email
                            errorMessage="Please enter a valid email address"
                            onInputChange={inputChangeHandler}
                            initialValue={formState.inputValues.email}
                            initiallyValid={formState.inputValidities.email}
                        />
                        <DefaultInput
                            id='password'
                            title="Password"
                            keybordType="default"
                            secureTextEntry
                            minLength={5}
                            required
                            autoCapitalize="none"
                            errorMessage="Please enter a valid password"
                            onInputChange={inputChangeHandler}
                            initialValue={formState.inputValues.password}
                            initiallyValid={formState.inputValidities.password}
                        />
                        <TouchableItem style={styles.button} onSelectItem={signUpHandler}>
                            <DefaultText style={styles.buttonText}>{"Signup"}</DefaultText>
                        </TouchableItem>
                        <TouchableItem style={styles.buttonSwitch} onSelectItem={() => { props.navigation.navigate('Auth') }}>
                            <DefaultText style={styles.switchText}>{"Already have an account?"}</DefaultText>
                        </TouchableItem>
                    </ScrollView>

                }
            </View>
            }
        </View>
    </KeyboardAvoidingView>
    );
};

export const resultAndSignupScreenOptions = navigationData => {
    return {
        title: "Signup",
        headerLeft: () =>
            <HeaderButtons HeaderButtonComponent={HeaderButton} >
                <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                    navigationData.navigation.goBack();
                }} />
            </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    imageContainer: {
        flex: 1, 
        paddingHorizontal: 20, 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginBottom: 60, 
        flexDirection: 'row'
    },
    screen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        backgroundColor: Colors.backColor
    },
    actionContainer: {
        height: '100%',
        justifyContent: 'center',
    },
    resultContainer: {
        marginVertical: 50,
        alignItems: 'center'
    },
    image: {
        width: Dimensions.get('window').width > 400 ? 100 : 70,
        height: Dimensions.get('window').width > 400 ? 100 : 70,
        alignItems: 'center'
    },
    authContainer: {
        width: '100%',
        maxWidth: 500,
        padding: 20,
        borderRadius: 10,
        backgroundColor: 'white'
    },
    title: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width > 400 ? 14 : 12,
        textAlign: 'left',
        padding: 10
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        marginHorizontal: 30,
        justifyContent: 'center'
    },
    switchText: {
        color: Colors.primaryColor,
        fontFamily: 'lato'
    },
    buttonSwitch: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 25,
        marginHorizontal: 30,
        // backgroundColor: Colors.buttonGreen,
        overflow: 'hidden'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center'
    },
    loginButton: {
        marginTop: 20
    },
    buttonOk: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        width: 200,
        justifyContent: 'center'
    },
    loginText: {
        color: Colors.darkBlue,
        fontFamily: 'lato',
        textAlign: 'center'
    }
});

export default ResultAndSignupScreen;