import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import {
    View,
    Platform,
    ScrollView,
    StyleSheet,
    KeyboardAvoidingView,
    Alert,
    ActivityIndicator,
} from 'react-native';

import DefaultInput from '../components/DefaultInput';
import DefaultText from '../components/DefaultText';
import TouchableItem from '../components/TouchableItem';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import HeaderButton from '../components/HeaderButton';
import * as authActions from '../store/actions/auth';

import Colors from '../constants/Colors';

const FORM_UPDATE = 'FORM_UPDATE';

const formReducer = (state, action) => {
    if (action.type === FORM_UPDATE) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        };
        const updatedValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updatedValidities) {
            updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
        }
        return {
            formIsValid: updatedFormIsValid,
            inputValues: updatedValues,
            inputValidities: updatedValidities
        };
    }
    return state;
};


const ResetPasswordScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [isSignup, setIsSignup] = useState(false);


    const dispatch = useDispatch();

    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: {
            email: '',
            password: '',
        },
        inputValidities: {
            email: false,
            password: false,
        },
        formIsValid: false,
    });
    
    const inputChangeHandler = useCallback((inputId, inputValue, inputValidity) => {
        dispatchFormState({
            type: FORM_UPDATE,
            value: inputValue,
            isValid: inputValidity,
            input: inputId
        });
    }, [dispatchFormState]);

    const getNewPasswordHandler = useCallback(async () => {
        if (!formState.formIsValid) {
            Alert.alert('Invalid email', 'Please make sure the entered email is a valid email',
                [{ text: 'Ok' }])
            return;
        }
        setError(null);
        setIsLoading(true);
        try {
            await dispatch(authActions.resetPassword(
                formState.inputValues.email,
            ));
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    }, [dispatch,
        formState
    ]);

    useEffect(() => {
        props.navigation.setOptions({
            headerLeft: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                        props.navigation.goBack();
                    }} />
                </HeaderButtons>
        });
    });

    useEffect(() => {
        if(error) {
        Alert.alert('An issue has occured', error, [{ text: 'ok' }]);
    }
    }, [error]);

    return (
        <KeyboardAvoidingView
            behavior= {Platform.OS === 'android' ? 'padding' : 'padding'}
            enabled= {Platform.OS === 'android' ? false : true}
            keyboardVerticalOffset= {Platform.OS === 'android' && Platform.Version >= 21 ? 0 : 50}
            style={styles.screen}
        >
                {isLoading ? 
                
                <ActivityIndicator size='large' color={Colors.primaryColor}/> :
                <View style={styles.authContainer}>
                    <ScrollView >
                        <DefaultInput
                            id='email'
                            title="E-mail"
                            keybordType="email-address"
                            required
                            email
                            errorMessage="Please enter a valid email address"
                            onInputChange={inputChangeHandler}
                            initialValue=""
                        />
                        <TouchableItem style={styles.button} onSelectItem={getNewPasswordHandler}>
                            <DefaultText style={styles.buttonText}>Reset my password</DefaultText>
                        </TouchableItem>
                    </ScrollView>
                </View>
                }
        </KeyboardAvoidingView>
    );
};

export const resetPasswordScreenOptions = navigationData => {
    return {
        title: "Reset Password",
    }
};


const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        backgroundColor: Colors.backColor
    },
    // overviewContainer: {
    //     justifyContent: 'center'
    // },  
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        marginHorizontal: 30,
        justifyContent: 'center'
    },
    buttonSwitch: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 25,
        marginHorizontal: 30,
        // backgroundColor: Colors.buttonGreen,
        overflow: 'hidden'
    },
    authContainer: {
        width: '100%',
        maxWidth: 500,
        padding: 20,
        borderRadius: 10,
        backgroundColor: 'white'
    },
    new: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 70,
        marginBottom: 20,
        marginHorizontal: 0,
        backgroundColor: Colors.buttonGreen,
        overflow: 'hidden'
    },
    newText: {
        color: Colors.primaryColor,
        fontFamily: 'lato-bold'
    },
    gradient: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    switchText: {
        color: Colors.primaryColor,
        fontFamily: 'lato'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold'
    }
});

export default ResetPasswordScreen;