import React, { useState, useEffect, useReducer, useRef } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    KeyboardAvoidingView,
    Dimensions,
    SafeAreaView,
    Animated,
    Platform,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const WealthScreen = props => {
    const maritalStatus = useSelector(state => state.potential.maritalStatus);
    const hasWealthOver100Data = useSelector(state => state.potential.hasWealthOver100);
    const wealthAmountData = useSelector(state => state.potential.wealthAmount);

    const [hasWealthOver100, setHasWealthOver100] = useState(hasWealthOver100Data);
    const [wealthAmount, setWealthAmount] = useState(wealthAmountData);
    const [wealthAmountValid, setWealthAmountValid] = useState((wealthAmountData > 0) ? true : false);

    const dispatch = useDispatch();

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (hasWealthOver100 === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [hasWealthOver100])

    const selectionHandler = async (answer) => {
        setHasWealthOver100(answer);
        dispatch(potentialActions.setHasWealthOver100(answer));
        if (hasWealthOver100Data === '') {
            if (answer === false) {
                props.navigation.navigate('DebtInterests');
            }
        }
    };

    const inputChangeHandler = async (id, value, valid) => {
        setWealthAmount(value);
        setWealthAmountValid(valid);
        dispatch(potentialActions.setWealthAmount(value));
    };

    const doneHandler = () => {
        if (Number(wealthAmount) > 200000 && hasWealthOver100 === true) {
            props.navigation.navigate('Result');
        } else if (hasWealthOver100 === false || Number(wealthAmount) > 0) {
            props.navigation.navigate('DebtInterests');
        }
    };

    const sView = useRef();

    useEffect(() => {
        if (Dimensions.get('window').height > 500) {
            sView.current.scrollToEnd({ animated: true });
        }
    })

    return (
        <SafeAreaView style={styles.screen}>
            <KeyboardAvoidingView
                behavior='padding'
                keyboardVerticalOffset={50}
            >
                <ScrollView ref={sView}>
                    <DismissKeyboard>
                        <View style={styles.questionContainer}>
                            <View style={styles.questionTextContainer}>
                                <DefaultText style={styles.questionText}>
                                    {maritalStatus === 'Married' ?
                                        "Do you and your partner own wealth over CHF 100'000 ?"
                                        :
                                        "Do you own wealth over CHF 100'000 ?"
                                    }
                                </DefaultText>
                                <DefaultText style={styles.note}>
                                    Excluding amounts paid by the employer
                        </DefaultText>
                            </View>
                            <View style={styles.answerContainer}>
                                <View style={styles.yesNoContainer}>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandler(false)}
                                        style={hasWealthOver100 === false ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={hasWealthOver100 === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            No
                                </DefaultText>
                                    </TouchableItem>
                                    <TouchableItem
                                        onSelectItem={() => selectionHandler(true)}
                                        style={hasWealthOver100 === true ? styles.activeAnswer : styles.passiveAnswer}
                                    >
                                        <DefaultText
                                            style={hasWealthOver100 === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                        >
                                            Yes
                            </DefaultText>
                                    </TouchableItem>
                                </View>
                                {hasWealthOver100 === true && (
                                    <Animated.View
                                        style={[
                                            styles.inputContainer,
                                            {
                                                opacity: fadeAnim // Bind opacity to animated value
                                            }
                                        ]}>
                                        <DefaultInputMaskedMoney
                                            focus={!(wealthAmountData > 0)}
                                            id='wealth'
                                            title="Please provide an estimate of your total wealth:"
                                            errorMessage="Please enter a valid amount"
                                            placeHolder="250'000"
                                            keyboardType='numeric'
                                            onInputChange={inputChangeHandler}
                                            initialValue={wealthAmount}
                                            initiallyValid={wealthAmountValid}
                                            decimal
                                            required
                                        />
                                    </Animated.View>
                                )}
                            </View>

                            <NavContainer
                                backShow={true}
                                nextShow={(hasWealthOver100 === false || wealthAmount > 0)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />
                        </View>
                    </DismissKeyboard>
                </ScrollView>
            </KeyboardAvoidingView>
        </SafeAreaView>
    );
};

export const wealthScreenOptions = navigationData => {
    return {
        title: "Your Wealth",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1,
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        marginBottom: 20
    },
    questionTextContainer: {
        marginBottom: 40,
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: '100%',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: '100%',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width < 330 ? 12 : Dimensions.get('window').width > 380 ? 16 : 14,
        color: Colors.darkGreen,
        textAlign: 'center',
        paddingHorizontal: 20
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width < 330 ? 12 : Dimensions.get('window').width > 380 ? 16 : 14,
        color: Colors.darkGreen,
        textAlign: 'center',
        paddingHorizontal: 20
    },
});

export default WealthScreen;