import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Image,
    ActivityIndicator,
    Button
} from 'react-native';

import Colors from '../../constants/Colors';

const LoadingScreen = props => {
    const potentialDone = useSelector(state => state.user.potentialDone);

    useEffect(() => {
        if (!potentialDone || potentialDone === '') {
            notDone();
        } else {
            done();
        }        
    });

    const done = () => {
        props.navigation.push('TasOverview');
    }
    const notDone = () => {
        props.navigation.push('Canton');
    }

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView >
                <View style={styles.mainContainer}>
                    <Image
                        style={{ width: 150, height: 150, borderColor: Colors.backColor, marginBottom: 20 }}
                        source={require('../../assets/Logo256.png')}
                    />
                    <ActivityIndicator size='small' color={Colors.darkBlue} />
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const loadingScreenOptions = navigationData => {
    return {
        title: "Loading",
        headerShown: false
    }
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        justifyContent: 'center'
    },
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
    },
    mainContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        height: Dimensions.get('window').height * 0.8
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    preOrderContainer: {
        height: 100,
        marginBottom: 10,
        justifyContent: 'space-evenly'
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 20
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginHorizontal: 30,
        marginBottom: 50
    },
    restartContainer: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginHorizontal: 30,
        marginTop: 10,
        flexDirection: 'row'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 18
    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
    successText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'center',
        padding: 10
    },
    successDesc1: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
    },
    successDesc: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
        paddingBottom: 20
    },
});

export default LoadingScreen;