import React from 'react';
import {
    TouchableOpacity,
    Platform,
    TouchableNativeFeedback,
    View,
    StyleSheet
} from 'react-native';

const TouchableItem = props => {

    let TouchableComponent = TouchableOpacity;

    if (Platform.OS === 'android' && Platform.Version >= 21) {
        TouchableComponent = TouchableNativeFeedback;
        ;
    }
    return (
        <TouchableComponent onPress={props.onSelectItem}>
            <View style={props.style}>
            {props.children}
            </View>
        </TouchableComponent>
    )
};

const styles = StyleSheet.create({
    view: {
        flex: 1,
        justifyContent: 'center',
    }
});

export default TouchableItem;

// {...props.style, ...styles.view }