import React from 'react';
import { HeaderButton } from 'react-navigation-header-buttons';
import { Entypo, Ionicons, MaterialCommunityIcons, Feather, EvilIcons, MaterialIcons, FontAwesome5, AntDesign} from '@expo/vector-icons';
import { Platform } from 'react-native';

import Colors from '../constants/Colors';

const CustomHeaderButton = props => {

    let IconProd = Ionicons;

    if (props.prod === 'material') {
        IconProd = MaterialCommunityIcons;
        ;
    } else if (props.prod === 'feather') {
        IconProd = Feather;
    } else if (props.prod === 'evil') {
        IconProd = EvilIcons;
    } else if (props.prod === 'materialI') {
        IconProd = MaterialIcons;
    } else if (props.prod === 'entypo') {
        IconProd = Entypo;
    } else if (props.prod === 'awesome5') {
        IconProd = FontAwesome5;
    } else if (props.prod === 'ant') {
        IconProd = AntDesign;
    }

    return (
        <HeaderButton
            {...props}
            IconComponent={IconProd}
            iconSize={props.size ? props.size : 23}
            color={Colors.darkGreen}
            // color={Platform.OS === 'android' ? 'white' : Colors.darkGreen}
        />
    )
};

export default CustomHeaderButton;

