import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    ScrollView,
    View,
    KeyboardAvoidingView,
    Dimensions,
    SafeAreaView,
    Platform,
    Animated,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import DismissKeyboard from '../../../components/DismissKeyboard';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import TouchableItem from '../../../components/TouchableItem';

// import { cashOptionsText } from '../../../static/incomeData';

import * as potentialActions from '../../../store/actions/potential';

const IncomeTaxedAtSourceScreen = props => {
    const maritalStatus = useSelector(state => state.potential.maritalStatus);
    const userHasTasIncomeInChData = useSelector(state => state.potential.userHasTasIncomeInCh);
    const cashFromTasIncomesData = useSelector(state => state.potential.cashFromTasIncomes);
    const userHasVarianceData = useSelector(state => state.potential.userHasVariance);
    
    const [userHasVariance, setUserHasVariance] = useState(userHasVarianceData);
    const [userHasTasIncomeInCh, setUserHasTasIncomeInCh] = useState(userHasTasIncomeInChData);
    const [cashFromTasIncomes, setCashFromTasIncomes] = useState(cashFromTasIncomesData);
    const [cashFromTasIncomesValid, setCashFromTasIncomesValid] = useState(cashFromTasIncomesData > 0 ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (userHasTasIncomeInCh === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [userHasTasIncomeInCh])

    const dispatch = useDispatch();

    const selectionHandler = (answer) => {
        setUserHasTasIncomeInCh(answer);
        dispatch(potentialActions.setUserHasTasIncomeInCh(answer));
    };

    const selectionHandlerVariance = (answer) => {
        setUserHasVariance(answer);
        dispatch(potentialActions.setUserHasVariance(answer));
    };

    const inputChangeHandler = async (id, value, valid) => {
        setCashFromTasIncomes(value);
        setCashFromTasIncomesValid(valid);
        dispatch(potentialActions.setCashFromTasIncomes(value));
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    const doneHandler = () => {
        if (cashFromTasIncomesValid || userHasTasIncomeInCh === false) {
            if (cashFromTasIncomes > 8000) {
                props.navigation.navigate('Result');
            } else {
                if (maritalStatus === 'Married') {
                    props.navigation.navigate('PartnerIncomeTas');
                } else {
                    props.navigation.navigate('OtherIncome');
                }
            }
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
                <KeyboardAvoidingView 
                    behavior='position'
                    keyboardVerticalOffset={0}
                >
                    <ScrollView ref={sView}>
                    <DismissKeyboard>
                        <View style={styles.questionContainer}>
                            <View style={styles.questionSubContainer}>
                                <DefaultText style={styles.questionText}>Did you receive employment related income in Switzerland ?</DefaultText>
                                <DefaultText style={styles.note}>This is income you paid tax at source on: employment and daily allowances paid through your employer or unemployment.</DefaultText>
                            </View>
                        <View style={styles.answerContainer}>
                            <View style={styles.yesNoContainer}>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(true)}
                                    style={userHasTasIncomeInCh === true ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={userHasTasIncomeInCh === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        I had employment related income
                                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(false)}
                                    style={userHasTasIncomeInCh === false ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={userHasTasIncomeInCh === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        I had NO employment related income
                                        </DefaultText>
                                </TouchableItem>
                            </View>

                            {userHasTasIncomeInCh === true && (
                                <Animated.View
                                    style={[
                                        styles.inputContainer,
                                        {
                                            opacity: fadeAnim
                                        }
                                    ]}>
                            <View style={styles.yesNoContainer}>
                                <TouchableItem
                                    onSelectItem={() => selectionHandlerVariance(true)}
                                    style={userHasVariance === true ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={userHasVariance === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        Some month were significantly higher than others
                                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    onSelectItem={() => selectionHandlerVariance(false)}
                                    style={userHasVariance === false ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={userHasVariance === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        My salary was more or less the same over the months
                                        </DefaultText>
                                </TouchableItem>
                            </View>
                            
                                    <DefaultInputMaskedMoney
                                        focus={!(cashFromTasIncomes > 0)}
                                        id='cashFromTas'
                                        title="MONTHLY average cash amount received:"
                                        errorMessage="Please enter a valid amount"
                                        placeHolder="6'500"
                                        keyboardType='numeric'
                                        onInputChange={inputChangeHandler}
                                        initialValue={cashFromTasIncomes}
                                        initiallyValid={cashFromTasIncomesValid}
                                        decimal
                                        required
                                    />
                                </Animated.View>
                            )}
                        </View>
                            <NavContainer
                                backShow={true}
                                nextShow={userHasTasIncomeInCh === false || (cashFromTasIncomes > 0 && userHasVariance !== '')}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            /> 
                        </View>

                    </DismissKeyboard>
                    </ScrollView>
                </KeyboardAvoidingView>            
        </SafeAreaView>
    );
};

export const incomeTaxedAtSourceScreenOptions = navigationData => {
    return {
        title: "Income Taxed At Source",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,        
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    questionSubContainer: {
        marginBottom: 40,
    },
    inputContainer: {
        flex: 1,
        marginTop: 20
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 60,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        // marginBottom: 20,
        // padding: 10
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
        paddingHorizontal: 20,
        textAlign: 'center'
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
        paddingHorizontal: 20,
        textAlign: 'center'
    },
});

export default IncomeTaxedAtSourceScreen;