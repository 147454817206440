import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import DefaultDropdown from '../../../components/DefaultDropdown';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as potentialActions from '../../../store/actions/potential';

const HasChildrenScreen = props => {
    const hasChildrenData = useSelector(state => state.potential.hasChildren);
    const childCountData = useSelector(state => state.potential.childCount);
    const [hasChildren, setHasChildren] = useState(hasChildrenData);
    const [childCount, setChildCount] = useState(childCountData);
    const [childCountValid, setChildCountValid] = useState(childCountData ? true : false);

    const dispatch = useDispatch();

    const sView = useRef();

    const selectionHandler = async (answer) => {        
        setHasChildren(answer);
        dispatch(potentialActions.setHasChildren(answer));
        if (hasChildrenData === '') {
            if (answer === false) {
                props.navigation.navigate('TimeInCh');
            };        
        }
    };

    useEffect(() => {
        sView.current.scrollToEnd({animated: true});
    }, [hasChildren])

    const inputChangeHandler = async (id, value, valid) => {
        setChildCount(value);
        setChildCountValid(valid);
        dispatch(potentialActions.setChildCount(value));
    };

    const doneHandler = () => {
        props.navigation.navigate('TimeInCh');
    };

    useEffect(() => {
        props.navigation.setOptions({
            headerLeft: () =>
                null
        });
    });


    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText style={styles.questionText}>Did you have children as of the end of 2020?</DefaultText>
                    <View style={styles.answerContainer}>
                        <TouchableItem
                            style={hasChildren === false ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, false)}>
                            <DefaultText style={hasChildren === false ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                No
                    </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            style={hasChildren === true ? styles.activeAnswer : styles.passiveAnswer}
                            onSelectItem={selectionHandler.bind(this, true)}
                        >
                            <DefaultText
                                style={hasChildren === true ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Yes
                        </DefaultText>
                        </TouchableItem>
                    </View>
                    {hasChildren ?
                        <View style={styles.dropDownContainer}>
                            <DefaultDropdown
                                id='childrenCount'
                                title="How many do you have:"
                                data={['', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'more']}
                                required
                                onInputChange={inputChangeHandler}
                                defaultValue=""
                                minLength={1}
                                initialValue={childCount}
                                initiallyValid={childCountValid}
                            />
                        </View>
                        :
                        <View></View>
                    }

                    <NavContainer
                        backShow={true}
                        nextShow={(hasChildren === false || (hasChildren === true && childCount !== ''))}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack()}}
                    />

                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const hasChildrenScreenOptions = navigationData => {
    return {
        title: "Children",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    dropDownContainer: {
        marginTop: 20
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 10
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },

});

export default HasChildrenScreen;