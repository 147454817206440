import React, { useEffect, useReducer } from 'react';
import {
    View,
    StyleSheet,
    Platform
} from 'react-native';
import { Picker } from '@react-native-community/picker';

import DefaultText from './DefaultText';
import Colors from '../constants/Colors';

const INPUT_CHANGE = 'INPUT_CHANGE';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
            }
        default:
            return state;
    }
};

const DefaultDropdown = props => {
    const [inputState, dispatchInputState] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : props.defaultValue,
        isValid: props.initiallyValid,
        touched: false
    });

    const { onInputChange, id } = props;

    const textChangeHandler = text => {
        let isValid = true;
        if (props.required && text.trim().length === 0) {
            isValid = false;
        }
        if (props.minLength != null && text.length < props.minLength) {
            isValid = false;
        }
        if (props.isNot0 != null && text === "0") {
            isValid = false;
        }
        dispatchInputState({
            type: INPUT_CHANGE,
            value: text,
            isValid: isValid
        });
        ;
    };

    useEffect(() => {
        onInputChange(id, inputState.value, inputState.isValid);
    }, [inputState, onInputChange, id]);

    let options = props.data.map((option) => {
        return (
            <Picker.Item label={option} value={option} key={option} />
        )
    });

    return (
        <View style={styles.inputContainer}>
            <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
            <Picker style={styles.picker}
                selectedValue={inputState.value}
                mode="dropdown"
                onValueChange={(itemValue) => {
                    textChangeHandler(itemValue)
                }}
                itemStyle={{height: Math.min(100,props.data.length * 50), fontFamily: 'lato-bold', fontSize: 18, justifyContent: 'flex-start', color: Colors.darkBlue}}
            >
                {options}
            </Picker>
        </View>
    )
};

const styles = StyleSheet.create({
    inputContainer: {
        marginVertical: 10,
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        borderRadius: 10,
        padding: 10,
        backgroundColor: Colors.backColor
    },
    inputTitle: {
        fontFamily: 'lato-bold',
        marginBottom: 10,
        color: Colors.darkBlue
    },
    picker: {
        width: '100%',
        justifyContent: 'flex-start',
        // outlineWidth: 0,
        borderWidth: 0,
        backgroundColor: Colors.backColor,
        minHeight: Platform.OS === 'web' ? 50 : null,
        fontFamily: 'lato',
        fontSize: Platform.OS === 'web' ? 20 : null,
    },
    errorContainer: {
        marginVertical: 5
    },
    errorText: {
        fontFamily: 'lato',
        fontSize: 13,
        color: 'red'
    }
});

export default DefaultDropdown;

