import {
    SET_CANTONS
} from '../actions/static';

const initialState = {
    cantons: [],
};

const staticReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_CANTONS:
            return {
                ...state,
                cantons: action.data
            };
        default:
            return state;
    }
    return state;
};

export default staticReducer;