
export const SET_YEAR = 'SET_YEAR';
export const SET_TAS_IN_CH = 'SET_TAS_IN_CH';
export const SET_PERMIT = 'SET_PERMIT';
export const SET_OVER_120 = 'SET_OVER_120';
export const SET_INCOME_ABROAD = 'SET_INCOME_ABROAD';
export const SET_INCOME_ABROAD_TYPES = 'SET_INCOME_ABROAD_TYPES';
export const SET_LIVE_ABROAD = 'SET_LIVE_ABROAD';
export const SET_LIVE_ABROAD_FULL = 'SET_LIVE_ABROAD_FULL';
export const SET_MARITAL_STATUS = 'SET_MARITAL_STATUS';
export const SET_PARTNER_ABROAD = 'SET_PARTNER_ABROAD';
export const SET_MORE_IN_OUT = 'SET_MORE_IN_OUT';
export const SET_MOVE_IN_DATE = 'SET_MOVE_IN_DATE';
export const SET_MOVE_OUT_DATE = 'SET_MOVE_OUT_DATE';
export const SET_PARTNER_TAX_STATUS = 'SET_PARTNER_TAX_STATUS';
export const SET_RELEVANCY_DATA = 'SET_RELEVANCY_DATA';


export const setYear = (data) => {
    return { type: SET_YEAR, data: data }
};

export const setTasInCh = (data) => {
    return { type: SET_TAS_IN_CH, data: data }
};

export const setPermit = (data) => {
    return { type: SET_PERMIT, data: data }
};

export const setOver120 = (data) => {
    return { type: SET_OVER_120, data: data }
};

export const setIncomeAbroad = (data) => {
    return { type: SET_INCOME_ABROAD, data: data }
};

export const setIncomeAbroadTypes = (data) => {
    return { type: SET_INCOME_ABROAD_TYPES, data: data }
};

export const setLiveAbroad = (data) => {
    return { type: SET_LIVE_ABROAD, data: data }
};

export const setLiveAbroadFull = (data) => {
    return { type: SET_LIVE_ABROAD_FULL, data: data }
};

export const setMaritalStatus = (data) => {
    return { type: SET_MARITAL_STATUS, data: data }
};

export const setMoreInOut = (data) => {
    return { type: SET_MORE_IN_OUT, data: data }
};

export const setMoveInDate = (data) => {
    return { type: SET_MOVE_IN_DATE, data: data }
};

export const setMoveOutDate = (data) => {
    return { type: SET_MOVE_OUT_DATE, data: data }
};

export const setPartnerAbroad = (data) => {
    return { type: SET_PARTNER_ABROAD, data: data }
};

export const setPartnerTaxStatus = (data) => {
    return { type: SET_PARTNER_TAX_STATUS, data: data }
};