import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    ActivityIndicator,
    Alert
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';
// import stripe from 'tipsi-stripe';
import { PaymentsStripe as Stripe } from 'expo-payments-stripe';
import { WebView } from 'react-native-webview';

import DismissKeyboard from '../../components/DismissKeyboard';
import HeaderButton from '../../components/HeaderButton';
import Colors from '../../constants/Colors';
import * as userActions from '../../store/actions/user';

const CheckoutScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [error, setError] = useState();

    const userId = useSelector(state => state.auth.userId);

    const sessionId = props.route.params ? props.route.params.sessionId : null
    const orderId = props.route.params ? props.route.params.orderId : null

    const dispatch = useDispatch();

    const handleChange = async (e) => {
            if (!e.loading && e.url === `https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/payment/success`) {
                await dispatch(userActions.confirmPreOrder(userId, true, 100, 'ccy'));
                props.navigation.pop();
            }
            else if (!e.loading && e.url === `https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/payment/cancel`) {                  
                props.navigation.navigate('Result');                
            }
    }

    useEffect(() => {
        props.navigation.setOptions({
            headerRight: () =>
                null,
            headerLeft: () =>
                null
        });
    }, []);

    return (
        // <ScrollView style={styles.screen}>
        //     <KeyboardAvoidingView behavior='position'>
        //         <DismissKeyboard>
        <WebView
            style={{ flex: 1 }}
            originWhitelist={['*']}
            source={{ uri: `https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/web/checkout/redirect?sessionId=${sessionId}` }}
            onNavigationStateChange={handleChange}
            ref={(ref) => { webview = ref; }}
            onError={() => {props.navigation.pop()}}
        />
        //         </DismissKeyboard>
        //     </KeyboardAvoidingView>
        // </ScrollView>
    );
};

export const checkoutScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            <HeaderButtons HeaderButtonComponent={HeaderButton} >
                <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                    navigationData.navigation.goBack();
                }} />
            </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
    },
    questionContainer: {
        padding: 10,
        flex: 1,
        minHeight: Dimensions.get('window').height * 0.8,
        justifyContent: 'center'
    },
    inputContainer: {
        flex: 1
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        height: 70,
        marginBottom: 70
    },
    cardDetailsContainer: {
        // alignItems: 'center'
        padding: 30,
        // width: Dimensions.get('window').width,
        // justifyContent: 'space-between'
    },
    cardDetailsline: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        marginHorizontal: 30
    },
    buttonSwitch: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 25,
        marginHorizontal: 30,
        // backgroundColor: Colors.buttonGreen,
        overflow: 'hidden'
    },
    switchText: {
        color: Colors.primaryColor,
        fontFamily: 'lato'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold'
    }
});

export default CheckoutScreen;