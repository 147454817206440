import React from 'react';
import {
    StyleSheet,
} from 'react-native';

import Colors from './Colors';

const globalStyles = StyleSheet.create({
    screen: {
        flex: 1,
        padding: 10,
        backgroundColor: Colors.backColor,
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    button: {
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        padding: 10,
        flex: 0,
        maxHeight: 40,
    },
    imageB: {
        width: 300, 
        height: 300, 
        alignItems: 'center', 
        justifyContent: 'flex-end', 
        marginTop: '20%'
    },
    buttonSwitch: {
        padding: 10,
        borderRadius: 20,
        backgroundColor: Colors.buttonGreen,
        marginBottom: 20,
        maxHeight: 40,
    },
    switchText: {
        color: Colors.darkBlue,
        fontFamily: 'lato-bold',
    },
    switchTextTest: {
        color: 'red',
        fontFamily: 'lato-bold',
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        // fontWeight: 'bold'
    }
});

export default globalStyles;