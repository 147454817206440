import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Platform
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import TouchableItem from '../../components/TouchableItem';
import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';

const ImprintScreen = props => {

    const dispatch = useDispatch();

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView >
                <View style={styles.mainContainer}>
                    <DefaultText style={styles.mainTitle}>Imprint</DefaultText>
                    <DefaultText style={styles.subTitle}>Contact address</DefaultText>
                    <DefaultText style={styles.paragraphs}>Taxdone</DefaultText>
                    <DefaultText style={styles.paragraphs}>Minervastrasse 119</DefaultText>
                    <DefaultText style={styles.paragraphs}>8032 Zürich Switzerland</DefaultText>
                    <DefaultText style={styles.paragraphs}>E-mail: contact@taxdone.ch</DefaultText>
                    <DefaultText style={styles.paragraphs}>Phone number: +41-43-550-72-35</DefaultText>
                    <DefaultText style={styles.subTitle}>Authorised representatives</DefaultText>
                    <DefaultText style={styles.paragraphs}>Jeremy Cohen, Founder</DefaultText>
                    <DefaultText style={styles.paragraphs}>Adrien El Karoui, Founder</DefaultText>
                    <DefaultText style={styles.subTitle}>Disclaimer</DefaultText>
                    <DefaultText style={styles.paragraphs}>The author accepts no liability whatsoever for the correctness, accuracy, timeliness, reliability or completeness of the information provided. Liability claims regarding damage caused by the use of any information provided, including any kind of information which is incomplete or incorrect, will therefore be rejected.All offers are non-binding. The author expressly reserves the right to change, supplement or delete parts of the pages or the entire offer without special notice or to temporarily or permanently cease publication.</DefaultText>
                    <DefaultText style={styles.subTitle}>Disclaimer for links</DefaultText>
                    <DefaultText style={styles.paragraphs}>References and links to third-party websites are outside our area of responsibility. Any responsibility for such websites is rejected. Access to and use of such websites is at the user's own risk.</DefaultText>
                    <DefaultText style={styles.subTitle}>Copyrights</DefaultText>
                    <DefaultText style={styles.paragraphs}>The copyright and all other rights on contents, pictures, photos or other files on this website belong exclusively to the company Taxdone or the specially named copyright holders. The written consent of the copyright holder must be obtained in advance for the reproduction of any elements.</DefaultText>
                </View>
            </ScrollView>
        </SafeAreaView>
    );
};


export const imprintScreenOptions = navigationData => {
    return {
        title: "Imprint",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        justifyContent: 'center'
    },
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch'
    },
    mainTitle: {
        fontSize: 16,
        fontFamily: 'lato-bold',
        textAlign: 'center',
        marginBottom: 0,
        color: Colors.darkBlue
    },
    subTitle: {
        fontSize: 14,
        fontFamily: 'lato-bold',
        marginTop: 20,
        marginBottom: 5,
        color: Colors.darkBlue
    },
    subTitle2: {
        fontSize: 12,
        fontFamily: 'lato',
        marginTop: 20,
        marginBottom: 0,
        color: Colors.darkBlue
    },
    paragraphs: {
        fontSize: 12,
        color: Colors.darkBlue
    },  
    mainContainer: {
        justifyContent: 'space-evenly',
        // alignItems: 'center',
        padding: 10,
        // height: Dimensions.get('window').height * 0.8
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    preOrderContainer: {
        height: 100,
        marginBottom: 10,
        justifyContent: 'space-evenly'
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 20
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginHorizontal: 30,
        marginBottom: 50
    },
    restartContainer: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginHorizontal: 30,
        marginTop: 10,
        flexDirection: 'row'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 18
    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
    successText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'center',
        padding: 10
    },
    successDesc1: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
    },
    successDesc: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
        paddingBottom: 20
    },
});

export default ImprintScreen;