import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    ActivityIndicator,
    Alert
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';
// import stripe from 'tipsi-stripe';
import { PaymentsStripe as Stripe } from 'expo-payments-stripe';

import DismissKeyboard from '../../components/DismissKeyboard';
import HeaderButton from '../../components/HeaderButton';
import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';
import TouchableItem from '../../components/TouchableItem';

import * as paymentActions from '../../store/actions/payment';

const PaymentScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [error, setError] = useState();
    const [tokenId, setTokenId] = useState(null);
    const [token, setToken] = useState(null);


    Stripe.setOptionsAsync({
        publishableKey: 'pk_live_VBtaHFmwZpIM4AiatPatbMXt',
    });

    const userEmail = useSelector(state => state.auth.email)

    const dispatch = useDispatch();

    const makePaymentHandler = async () => {
        try {
            setIsLoading(true);
            await dispatch(paymentActions.makePreOrderPayment(tokenId));
        } catch (err) {
            setError(err)
        }
        setIsLoading(false);
        props.navigation.pop();
    };

    const handleCardPayPress = async () => {
        try {
            setTokenId(null);
            const token = await Stripe.paymentRequestWithCardFormAsync({
                theme: {
                    primaryBackgroundColor: Colors.backColor,
                    secondaryBackgroundColor: 'white',
                    primaryForegroundColor: Colors.darkBlue,
                    secondaryForegroundColor: Colors.darkGreen,
                    accentColor: Colors.darkGreen,
                    errorColor: 'red'
                },                
                smsAutofillDisabled: true,
                // requiredBillingAddressFields: 'zip',
            });
            const tokenIdFromToken = token.tokenId;
            await setTokenId(tokenIdFromToken);
            await setToken(token);
        } catch (error) {
            setError('Something went wrong !')
        }
    }
    

    useEffect(() => {
        props.navigation.setOptions({
            headerRight: () =>
                null
        });
    }, []);

    useEffect(() => {
        if(error) {
        Alert.alert('An issue has occured', error, [{ text: 'ok' }]);
    }
    }, [error]);

    return (
        <ScrollView style={styles.screen}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard>
                    <View style={styles.questionContainer}>
                        {/* <DefaultText style={styles.questionText}>Pre-order</DefaultText> */}

                        {tokenId === null ?
                            <TouchableItem style={styles.buttonSwitch} onSelectItem={handleCardPayPress}>
                                <DefaultText style={styles.switchText}>{"Add card details"}</DefaultText>
                            </TouchableItem>
                            :
                            <View>
                                {token && (
                                <TouchableItem style={styles.cardDetailsContainer} onSelectItem={handleCardPayPress}>
                                    <View style={styles.cardDetailsline}>
                                        <DefaultText>Brand: </DefaultText>
                                        <DefaultText style={{fontFamily: "lato-bold", textAlign: 'right'}}>{token.card.brand}</DefaultText>
                                    </View>
                                    <View style={styles.cardDetailsline}>
                                        <DefaultText>Type: </DefaultText>
                                        <DefaultText style={{fontFamily: "lato-bold", textAlign: 'right'}}>{token.card.funding}</DefaultText>
                                    </View>
                                    <View style={styles.cardDetailsline}>
                                        <DefaultText>Expiration date: </DefaultText>
                                        <DefaultText style={{fontFamily: "lato-bold", textAlign: 'right'}}>{token.card.expMonth}/{token.card.expYear}</DefaultText>
                                    </View>
                                    <View style={styles.cardDetailsline}>
                                        <DefaultText>Card number: </DefaultText>
                                        <DefaultText style={{fontFamily: "lato-bold", textAlign: 'right'}}>**** {token.card.last4}</DefaultText>
                                    </View>
                                </TouchableItem>
                                )}
                            </View>
                        }
                        {tokenId !== null && (
                            <View>
                                {isLoading ? 
                            <ActivityIndicator color={Colors.darkGreen} size="large"/>
                                :
                            <TouchableItem style={styles.button} onSelectItem={makePaymentHandler}>
                                <DefaultText style={styles.buttonText}>{"Pay CHF 100.-"}</DefaultText>
                            </TouchableItem>
                            }
                            </View>
                        )}
                    </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
    );
};

export const paymentScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            <HeaderButtons HeaderButtonComponent={HeaderButton} >
                <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                    navigationData.navigation.goBack();
                }} />
            </HeaderButtons>
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
    },
    questionContainer: {
        padding: 10,
        flex: 1,
        minHeight: Dimensions.get('window').height * 0.8,
        justifyContent: 'center'
    },
    inputContainer: {
        flex: 1
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        height: 70,
        marginBottom: 70
    },
    cardDetailsContainer: {
        // alignItems: 'center'
        padding: 30,
        // width: Dimensions.get('window').width,
        // justifyContent: 'space-between'
    },
    cardDetailsline: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        marginHorizontal: 30
    },
    buttonSwitch: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 25,
        marginHorizontal: 30,
        // backgroundColor: Colors.buttonGreen,
        overflow: 'hidden'
    },
    switchText: {
        color: Colors.primaryColor,
        fontFamily: 'lato'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold'
    }
});

export default PaymentScreen;