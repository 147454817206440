
export const SET_CANTONS = 'SET_CANTONS';

export const setCantons = () => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(
                'https://taxatsource-4a8c0.firebaseio.com/cantons.json');

            if (!response.ok) {
                const errorcantonsResp = await response.json();
                let message = errorcantonsResp.error.message
                throw new Error(message);
            }

            const cantonData = await response.json();

            let cantons = [];
            for ( const key in cantonData) {
                cantons.push(cantonData[key])
            }

            dispatch({ type: SET_CANTONS, data: cantons });
        } catch (err) {
            throw err;
        }
    };
};