import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    ScrollView,
    View,
    KeyboardAvoidingView,
    Dimensions,
    SafeAreaView,
    Platform,
    Animated,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import DismissKeyboard from '../../../components/DismissKeyboard';
import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import TouchableItem from '../../../components/TouchableItem';

// import { cashOptionsText } from '../../../static/incomeData';

import * as potentialActions from '../../../store/actions/potential';

const WorkIncomeAbroadScreen = props => {
    const maritalStatus = useSelector(state => state.potential.maritalStatus);

    const workAbroadAmountData = useSelector(state => state.potential.workAbroadAmount);    
    const workAbroadData = useSelector(state => state.potential.workAbroad);    
    const [workAbroad, setWorkAbroad] = useState(workAbroadData);
    const [workAbroadAmount, setWorkAbroadAmount] = useState(workAbroadAmountData);
    const [workAbroadAmountValid, setWorkAbroadAmountValid] = useState((workAbroadAmountData > 0) ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (workAbroad === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [workAbroad])

    const dispatch = useDispatch();

    const selectionHandler = (answer) => {
        setWorkAbroad(answer);
        dispatch(potentialActions.setWorkAbroad(answer));
    };

    const inputChangeHandler = async (id, value, valid) => {
        setWorkAbroadAmount(value);
        setWorkAbroadAmountValid(valid);
        dispatch(potentialActions.setWorkAbroadAmount(value));
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    const doneHandler = () => {
        if (workAbroadAmount > 0 || workAbroad === false) {
            props.navigation.navigate('IncomeTas');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
                <KeyboardAvoidingView 
                    behavior='position'
                    keyboardVerticalOffset={0}
                >
                    <ScrollView ref={sView}>
                    <DismissKeyboard>
                        <View style={styles.questionContainer}>
                            <View style={styles.questionSubContainer}>
                                <DefaultText style={styles.questionText}>
                                    {maritalStatus === 'Married' ?
                                            'Did you and your partner receive work related income during your time abroad ?'
                                            :
                                            'Did you receive work related income during your time abroad ?'
                                        }
                                    </DefaultText>
                                <DefaultText style={styles.note}>This is income from employment, self-employment or social security received while abroad.</DefaultText>
                            </View>
                        <View style={styles.answerContainer}>
                            <View style={styles.yesNoContainer}>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(true)}
                                    style={workAbroad === true ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={workAbroad === true ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        {maritalStatus === 'Married' ?
                                        'We had work related income abroad' :
                                        'I had work related income abroad'
                                    }
                                            </DefaultText>
                                </TouchableItem>
                                <TouchableItem
                                    onSelectItem={() => selectionHandler(false)}
                                    style={workAbroad === false ? styles.activeAnswer : styles.passiveAnswer}
                                >
                                    <DefaultText
                                        style={workAbroad === false ? styles.activeAnswerText : styles.passiveAnswerText}
                                    >
                                        {maritalStatus === 'Married' ?
                                        'We had NO work related income abroad' :
                                        'I had NO work related income abroad'
                                    }
                                        </DefaultText>
                                </TouchableItem>
                            </View>

                            {workAbroad === true && (
                                <Animated.View
                                    style={[
                                        styles.inputContainer,
                                        {
                                            opacity: fadeAnim
                                        }
                                    ]}>
                                    <DefaultInputMaskedMoney
                                        focus={!(workAbroadAmount > 0)}
                                        id='workAbroadIncome'
                                        title="Work income received abroad:"
                                        errorMessage="Please enter a valid amount"
                                        placeHolder="10'500"
                                        keyboardType='numeric'
                                        onInputChange={inputChangeHandler}
                                        initialValue={workAbroadAmount}
                                        initiallyValid={workAbroadAmountValid}
                                        decimal
                                        required
                                    />
                                </Animated.View>
                            )}
                        </View>
                            <NavContainer
                                backShow={true}
                                nextShow={(workAbroad === false || workAbroadAmount > 0)}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            /> 
                        </View>

                    </DismissKeyboard>
                    </ScrollView>
                </KeyboardAvoidingView>            
        </SafeAreaView>
    );
};

export const workIncomeAbroadScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,        
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    note: {
        paddingHorizontal: 10,
        marginBottom: 2,
        fontFamily: 'lato'
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 10,
        paddingHorizontal: 10
    },
    questionSubContainer: {
        marginBottom: 40,
    },
    inputContainer: {
        flex: 1,
        marginTop: 20
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 60,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent:'center'
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        // marginBottom: 20,
        // padding: 10
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
        paddingHorizontal: 20
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
        paddingHorizontal: 20
    },
});

export default WorkIncomeAbroadScreen;