import { cashOptionsText, cashOptionsAmount } from '../../static/incomeData';
import axios from 'axios';

export const SET_HAS_CHILDREN = 'SET_HAS_CHILDREN';
export const SET_CHILD_COUNT = 'SET_CHILD_COUNT';

export const SET_OTHER_INCOME_TYPES = 'SET_OTHER_INCOME_TYPES';
export const SET_OTHER_INCOME_AMOUNT = 'SET_OTHER_INCOME_AMOUNT';

export const SET_DEBT_INTERESTS = 'SET_DEBT_INTE RESTS';
export const SET_DEBT_INTERESTS_AMOUNT = 'SET_DEBT_INTERESTS_AMOUNT';

export const SET_FURTHER_EDUCATION_AMOUNT = 'SET_FURTHER_EDUCATION_AMOUNT';
export const SET_FURTHER_EDUCATION = 'SET_FURTHER_EDUCATION';

export const SET_HEALTH_COST = 'SET_HEALTH_COST';
export const SET_HEALTH_COST_AMOUNT = 'SET_HEALTH_COST_AMOUNT';

export const SET_DISABILITY_COST = 'SET_DISABILITY_COST';
export const SET_DISABILITY_COST_AMOUNT = 'SET_DISABILITY_COST_AMOUNT';

export const SET_PILLAR2 = 'SET_PILLAR2';
export const SET_PILLAR2_AMOUNT = 'SET_PILLAR2_AMOUNT';

export const SET_PILLAR3A = 'SET_PILLAR3A';
export const SET_PILLAR3A_AMOUNT = 'SET_PILLAR3A_AMOUNT';

export const SET_DONATION = 'SET_DONATION';
export const SET_DONATION_AMOUNT = 'SET_DONATION_AMOUNT';

export const SET_TRANSPORT_TYPES = 'SET_TRANSPORT_TYPES';
export const SET_VEHICLE_AMOUNT = 'SET_VEHICLE_AMOUNT';
export const SET_WEEKLY_STAYS_AMOUNT = 'SET_WEEKLY_STAYS_AMOUNT';
export const SET_WEEKLY_STAYS = 'SET_WEEKLY_STAYS';
export const SET_PAID_FOR_WEEK_STAYS = 'SET_PAID_FOR_WEEK_STAYS';
export const SET_ALIMONY_PAID_AMOUNT = 'SET_ALIMONY_PAID_AMOUNT';
export const SET_ALIMONY_PAID = 'SET_ALIMONY_PAID';
export const SET_SUPPORT_AMOUNT = 'SET_SUPPORT_AMOUNT';
export const SET_SUPPORT = 'SET_SUPPORT';
export const SET_CHILDCARE_AMOUNT = 'SET_CHILDCARE_AMOUNT';
export const SET_CHILDCARE = 'SET_CHILDCARE';
export const SAVE_POTENTIAL_DATA = 'SAVE_POTENTIAL_DATA';
export const SET_POTENTIAL = 'SET_POTENTIAL';
export const SET_CASH_FROM_TAS_INCOME = 'SET_CASH_FROM_TAS_INCOME';
export const SET_POT_MARITAL_STATUS = 'SET_POT_MARITAL_STATUS';
export const SET_POT_PARTNER_ABROAD = 'SET_POT_PARTNER_ABROAD';
export const SET_POT_PARTNER_TAX_STATUS = 'SET_POT_PARTNER_TAX_STATUS';
export const SET_PARTNER_CASH_FROM_TAS_INCOMES = 'SET_PARTNER_CASH_FROM_TAS_INCOMES';
export const SET_PARTNER_HAS_INCOME = 'SET_PARTNER_HAS_INCOME';
export const SET_RELEVANT_RATE = 'SET_RELEVANT_RATE';
export const SET_CANTON = 'SET_CANTON';
export const SET_RELIGION = 'SET_RELIGION';
export const SET_INITIAL_POTENTIAL_DATA_FROM_RELEVANCY = 'SET_INITIAL_POTENTIAL_DATA_FROM_RELEVANCY';
export const SET_INITIAL_POTENTIAL_DATA_FROM_POTENTIAL = 'SET_INITIAL_POTENTIAL_DATA_FROM_POTENTIAL';
export const SET_FULL_YEAR_IN_CH = 'SET_FULL_YEAR_IN_CH';
export const SET_MONTH_IN_CH = 'SET_MONTH_IN_CH';
export const SET_USER_HAS_TAS_INCOME_IN_CH = 'SET_USER_HAS_TAS_INCOME_IN_CH';
export const SET_WEALTH_OVER_100 = 'SET_WEALTH_OVER_100';
export const SET_WEALTH_AMOUNT = 'SET_WEALTH_AMOUNT';
export const SET_WORK_ABROAD_AMOUNT = 'SET_WORK_ABROAD_AMOUNT';
export const SET_WORK_ABROAD = 'SET_WORK_ABROAD';
export const SET_PARTNER_HAS_VARIANCE = 'SET_PARTNER_HAS_VARIANCE';
export const SET_USER_HAS_VARIANCE = 'SET_USER_HAS_VARIANCE';
export const SET_USER_PERMIT = 'SET_USER_PERMIT';
export const SET_NON_COVERED_CANTON = 'SET_NON_COVERED_CANTON';

export const SET_INVESTMENT_INCOME = 'SET_INVESTMENT_INCOME';
export const SET_INVESTMENT_INCOME_AMOUNT = 'SET_INVESTMENT_INCOME_AMOUNT';

export const setInvestmentIncome = (data) => {
    return { type: SET_INVESTMENT_INCOME, data: data }
}

export const setInvestmentIncomeAmount = (data) => {
    return { type: SET_INVESTMENT_INCOME_AMOUNT, data: data }
}

export const setNonCoveredCanton = (data) => {
    return { type: SET_NON_COVERED_CANTON, data: data }
}

export const setUserPermit = (data) => {
    return { type: SET_USER_PERMIT, data: data }
}

export const setPartnerHasVariance = (data) => {
    return { type: SET_PARTNER_HAS_VARIANCE, data: data }
}

export const setUserHasVariance = (data) => {
    return { type: SET_USER_HAS_VARIANCE, data: data }
}

export const setWorkAbroad = (data) => {
    return { type: SET_WORK_ABROAD, data: data }
}

export const setWorkAbroadAmount = (data) => {
    return { type: SET_WORK_ABROAD_AMOUNT, data: data }
};

export const setHasWealthOver100 = (data) => {
    return { type: SET_WEALTH_OVER_100, data: data }
};

export const setWealthAmount = (data) => {
    return { type: SET_WEALTH_AMOUNT, data: data }
};

export const setUserHasTasIncomeInCh = (data) => {
    return { type: SET_USER_HAS_TAS_INCOME_IN_CH, data: data }
};

export const setFullYearInCh = (data) => {
    return { type: SET_FULL_YEAR_IN_CH, data: data }
};

export const setMonthInCh = (data) => {
    return { type: SET_MONTH_IN_CH, data: data }
};

export const setInitialPotentialDataFromPotential = (cleanPotentialData) => {
    return { type: SET_INITIAL_POTENTIAL_DATA_FROM_POTENTIAL, data: cleanPotentialData }
};

export const setReligion = (data) => {
    return { type: SET_RELIGION, data: data }
};

export const setInitialPotentialDataFromRelevancy = (relevancyData) => {
    return { type: SET_INITIAL_POTENTIAL_DATA_FROM_RELEVANCY, data: relevancyData }
};

export const setPaidForWeekStaysForWork = (data) => {
    return { type: SET_PAID_FOR_WEEK_STAYS, data: data }
};

export const setCanton = (data) => {
    return { type: SET_CANTON, data: data }
};

export const setCashFromTasIncomes = (data) => {
    return { type: SET_CASH_FROM_TAS_INCOME, data: data }
}

export const setOtherIncomeTypes = (data) => {
    return { type: SET_OTHER_INCOME_TYPES, data: data }
}

export const setOtherIncomeAmount = (data) => {
    return { type: SET_OTHER_INCOME_AMOUNT, data: data }
}

export const setChildCount = (data) => {
    return { type: SET_CHILD_COUNT, data: data }
}

export const setHasChildren = (data) => {
    return { type: SET_HAS_CHILDREN, data: data }
}

export const setDebtInterestsAmount = (data) => {
    return { type: SET_DEBT_INTERESTS_AMOUNT, data: data }
}

export const setDebtInterests = (data) => {
    return { type: SET_DEBT_INTERESTS, data: data }
}

export const setFurtherEducationAmount = (data) => {
    return { type: SET_FURTHER_EDUCATION_AMOUNT, data: data }
}

export const setFurtherEducation = (data) => {
    return { type: SET_FURTHER_EDUCATION, data: data }
}

export const setHealthCost = (data) => {
    return { type: SET_HEALTH_COST, data: data }
}

export const setHealthCostAmount = (data) => {
    return { type: SET_HEALTH_COST_AMOUNT, data: data }
}

export const setDisabilityCost = (data) => {
    return { type: SET_DISABILITY_COST, data: data }
}

export const setDisabilityCostAmount = (data) => {
    return { type: SET_DISABILITY_COST_AMOUNT, data: data }
}

export const setPillar2 = (data) => {
    return { type: SET_PILLAR2, data: data }
}

export const setPillar2Amount = (data) => {
    return { type: SET_PILLAR2_AMOUNT, data: data }
}

export const setPillar3a = (data) => {
    return { type: SET_PILLAR3A, data: data }
}

export const setPillar3aAmount = (data) => {
    return { type: SET_PILLAR3A_AMOUNT, data: data }
}

export const setDonationAmount = (data) => {
    return { type: SET_DONATION_AMOUNT, data: data }
}

export const setDonation = (data) => {
    return { type: SET_DONATION, data: data }
}

export const setTransportTypes = (data) => {
    return { type: SET_TRANSPORT_TYPES, data: data }
}

export const setWeekStaysForWorkAmount = (data) => {
    return { type: SET_WEEKLY_STAYS_AMOUNT, data: data }
}

export const setWeekStaysForWork = (data) => {
    return { type: SET_WEEKLY_STAYS, data: data }
}

export const setAlimonyPaidAmount = (data) => {
    return { type: SET_ALIMONY_PAID_AMOUNT, data: data }
}

export const setAlimonyPaid = (data) => {
    return { type: SET_ALIMONY_PAID, data: data }
}

export const setSupportAmount = (data) => {
    return { type: SET_SUPPORT_AMOUNT, data: data }
}

export const setSupport = (data) => {
    return { type: SET_SUPPORT, data: data }
}

export const setChildcareAmount = (data) => {
    return { type: SET_CHILDCARE_AMOUNT, data: data }
}

export const setChildcare = (data) => {
    return { type: SET_CHILDCARE, data: data }
}

export const setVehicleAmount = (data) => {
    return { type: SET_VEHICLE_AMOUNT, data: data }
}

export const setMaritalStatus = (data) => {
    return { type: SET_POT_MARITAL_STATUS, data: data }
}

export const setPartnerAbroad = (data) => {
    return { type: SET_POT_PARTNER_ABROAD, data: data }
}

export const setPartnerTaxStatus = (data) => {
    return { type: SET_POT_PARTNER_TAX_STATUS, data: data }
}

export const setPartnerHasIncome = (data) => {
    return { type: SET_PARTNER_HAS_INCOME, data: data }
}

export const setPartnerCashFromTasIncomes = (data) => {
    return { type: SET_PARTNER_CASH_FROM_TAS_INCOMES, data: data }
}

export const setPotential = () => {
    return async (dispatch, getState) => {

        // === 'Zürich' ? 'zurich' : 'notCovered';
        const canton = getState().potential.canton;
        const userPermit = getState().potential.userPermit;
        const maritalStatus = getState().potential.maritalStatus;
        const religion = getState().potential.religion;
        // const investmentIncome = getState().potential.investmentIncome ? Number(getState().potential.investmentIncomeAmount) : 0;
        const otherIncomeTypes = getState().potential.otherIncomeTypes;
        const workAbroadAmount = (!getState().potential.fullYearInCh && getState().potential.workAbroad) ? Number(getState().potential.workAbroadAmount) : 0;
        const userCashTas = getState().potential.userHasTasIncomeInCh ? Number(getState().potential.cashFromTasIncomes) : 0;
        const partnerCashTas = getState().potential.partnerHasIncome ? Number(getState().potential.partnerCashFromTasIncomes) : 0;
        const otherIncomeAmount = getState().potential.otherIncomeTypes ? getState().potential.otherIncomeTypes.length > 0 ? Number(getState().potential.otherIncomeAmount) : 0 : 0;
        const childCount = getState().potential.hasChildren ? Number(getState().potential.childCount) : 0;
        const interests = getState().potential.debtInterests ? Number(getState().potential.debtInterestsAmount) : 0;
        const pillar3 = getState().potential.pillar3a ? Math.min(Number(getState().potential.pillar3aAmount), 6826) : 0;
        const pillar2 = getState().potential.pillar2 ? Number(getState().potential.pillar2Amount) : 0;
        const education = getState().potential.furtherEducation ? Math.min(Math.max((Number(getState().potential.furtherEducationAmount) - 500), 0), 12000) : 0;
        const alimonyPaid = getState().potential.alimonyPaid ? Number(getState().potential.alimonyPaidAmount) : 0;
        const childcare = getState().potential.childcare ? Number(getState().potential.childcareAmount) : 0;
        const support = getState().potential.support ? Number(getState().potential.supportAmount) : 0;
        const health = getState().potential.healthCost ? Number(getState().potential.healthCostAmount) : 0;
        const disability = getState().potential.disabilityCost ? Number(getState().potential.disabilityCostAmount) : 0;
        const charity = getState().potential.donation ? Math.max((Number(getState().potential.donationAmount) - 100), 0) : 0;
        const transport = getState().potential.transportTypes ? (getState().potential.transportTypes.includes('vehicle') ? Math.min(Math.max((Number(getState().potential.vehicleAmount) - 1300), 0), 5000) : 0) : 0;
        const workExpenses = (getState().potential.weeklyStays && getState().potential.paidForWeeklyStays) ? Number(getState().potential.weeklyStaysAmount) : 0;
        const wealth = getState().potential.hasWealthOver100 ? getState().potential.wealthAmount : 0;        
        const timeInCh = getState().potential.fullYearInCh ? 12 : Number(getState().potential.monthInCh);
        const userVariance = getState().potential.userHasVariance;
        const partnerVariance = getState().potential.partnerHasVariance;
        const partnerTaxStatus = !getState().potential.partnerAbroad ? getState().potential.partnerTaxStatus : '';

        console.log('canton: '+ canton);

        try {
            const res = await axios.post(`https://us-central1-taxatsource-4a8c0.cloudfunctions.net/api/potential/${canton}`, {
                userPermit: userPermit,
                partnerTaxStatus: partnerTaxStatus,
                maritalStatus: maritalStatus,                
                userCashTas: userCashTas,
                religion: religion,
                partnerCashTas: partnerCashTas,
                otherIncomeTypes: otherIncomeTypes,
                otherIncomeAmount: otherIncomeAmount,
                childCount: childCount,
                interests: interests,
                pillar3: pillar3,
                pillar2: pillar2,
                education: education,
                alimonyPaid: alimonyPaid,
                childcare: childcare,
                support: support,
                health: health,
                disability: disability,
                charity: charity,
                transport: transport,
                workExpenses: workExpenses,
                wealth: wealth,
                workAbroadAmount: workAbroadAmount,
                timeInCh: timeInCh,
                userVariance: userVariance,
                partnerVariance: partnerVariance,
                // investmentIncome: investmentIncome
            });

            await dispatch({ 
                type: SET_POTENTIAL, 
                initialRate: res.data.initialRate, 
                afterDeductionRate: res.data.afterDeductionRate, 
                potential: res.data.potentialAmount, 
                initialMonthlyTaxableIncomeUsed: res.data.initialMonthlyTaxableIncomeUsed,
                afterDeductionMonthlyTaxableIncomeUsed: res.data.afterDeductionMonthlyTaxableIncomeUsed,
                taxedIncome: res.data.taxedIncome,
                taxableIncomeForRateAfterRetreatment: res.data.taxableIncomeForRateAfterRetreatment,
                potentialDeduction: res.data.potentialDeduction,
                disclaimers: res.data.disclaimers
            });
            
        } catch (err) {
            throw err;
        }
    };
};
