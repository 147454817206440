import React, { useState, useEffect, useRef } from 'react';
import { 
    StyleSheet, 
    View, 
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    SafeAreaView,
    Animated,
    Platform
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import TouchableItem from '../../../components/TouchableItem';
import Colors from '../../../constants/Colors';
import DefaultInputMaskedMoney from '../../../components/DefaultInputMaskedMoney';
import DefaultText from '../../../components/DefaultText';
import NavContainer from '../../../components/NavContainer';
import DismissKeyboard from '../../../components/DismissKeyboard';

import * as potentialActions from '../../../store/actions/potential';

const DisabilityCostsScreen = props => {
    const disabilityCostData = useSelector(state => state.potential.disabilityCost);
    const disabilityCostAmountData = useSelector(state => state.potential.disabilityCostAmount);
    const [disabilityCost, setDisabilityCost] = useState(disabilityCostData);
    const [disabilityCostAmount, setDisabilityCostAmount] = useState(disabilityCostAmountData);
    const [disabilityCostAmountValid, setDisabilityCostAmountValid] = useState(disabilityCostAmountData ? true : false);

    const fadeAnim = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 1000,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (disabilityCost === true) {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [disabilityCost])

    const dispatch = useDispatch();

    const inputChangeHandler = async (id, value, valid) => {
        setDisabilityCostAmount(value);
        setDisabilityCostAmountValid(valid);
        dispatch(potentialActions.setDisabilityCostAmount(value));
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    })

    const selectionHandler = async (answer) => {
        setDisabilityCost(answer);
        dispatch(potentialActions.setDisabilityCost(answer));
        if (disabilityCostData === '') {
            if (answer === false) {
                props.navigation.navigate('Donation');
            } 
        }
    };

    const doneHandler = () => {
        if (disabilityCost === false || disabilityCostAmountValid) {
            props.navigation.navigate('Donation');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard>
                    <View style={styles.questionContainer}>
                <DefaultText style={styles.questionText}>Did you pay any cost related to a disability?</DefaultText>
                <View style={styles.answerContainer}>
                    <View style={styles.yesNoContainer}>
                        <TouchableItem
                            onSelectItem={() => selectionHandler(false)}
                            style={disabilityCost === false ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={disabilityCost === false ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                No
                                </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={() => selectionHandler(true)}
                            style={disabilityCost === true ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={disabilityCost === true ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Yes
                            </DefaultText>
                        </TouchableItem>
                        </View>
                    {disabilityCost === true && (
                        <Animated.View 
                        style={[
                            styles.inputContainer,
                            {
                              opacity: fadeAnim // Bind opacity to animated value
                            }
                          ]}>
                        <DefaultInputMaskedMoney
                            focus={!(disabilityCostAmountData > 0)}
                            id='disabilityCost'
                            title="Disability cost:"
                            errorMessage="Please enter a valid amount"
                            placeHolder="50'000"
                            keyboardType='numeric'
                            onInputChange={inputChangeHandler}
                            initialValue={disabilityCostAmount}
                            initiallyValid={disabilityCostAmountData}
                            decimal
                            required
                        />
                        </Animated.View>
                    )}
                </View>

                <NavContainer
                                backShow={true}
                                nextShow={disabilityCost === false || disabilityCostAmountValid === true}
                                nextNav={doneHandler}
                                backNav={() => { props.navigation.goBack() }}
                            />

                </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
        </SafeAreaView>
    );
};

export const disabilityCostsScreenOptions = navigationData => {
    return {
        title: "Disability Related Costs",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    inputContainer: {
        flex: 1,
        paddingTop: 20
    },
    yesNoContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10

    },
    answerContainer: {
        justifyContent: 'space-between',
        // minHeight: 200,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
});

export default DisabilityCostsScreen;