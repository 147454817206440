import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import {
    View,
    Platform,
    ScrollView,
    StyleSheet,
    KeyboardAvoidingView,
    Alert,
    ActivityIndicator,
    TextInput
} from 'react-native';

import DefaultInput from '../components/DefaultInput';
import DefaultText from '../components/DefaultText';
import TouchableItem from '../components/TouchableItem';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import HeaderButton from '../components/HeaderButton';
import * as authActions from '../store/actions/auth';

import Colors from '../constants/Colors';

const FORM_UPDATE = 'FORM_UPDATE';

const formReducer = (state, action) => {
    if (action.type === FORM_UPDATE) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        };
        const updatedValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updatedValidities) {
            updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
        }
        return {
            formIsValid: updatedFormIsValid,
            inputValues: updatedValues,
            inputValidities: updatedValidities
        };
    }
    return state;
};

const AuthScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [isSignup, setIsSignup] = useState(false);
    const [showError, setShowError] = useState(false);

    const dispatch = useDispatch();

    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: {
            email: '',
            password: '',
        },
        inputValidities: {
            email: false,
            password: false,
        },
        formIsValid: false,
    });

    const inputChangeHandler = useCallback((inputId, inputValue, inputValidity) => {
        dispatchFormState({
            type: FORM_UPDATE,
            value: inputValue,
            isValid: inputValidity,
            input: inputId
        });
    }, [dispatchFormState]);

    const authHandler = useCallback(async () => {        
        if (!formState.formIsValid && Platform.OS !== 'web') {
                Alert.alert('Invalid title', 'Please check the error in the form',
                    [{ text: 'Ok' }])
                return;
        }
        setError(null);
        setShowError(false);
        setIsLoading(true);
        try {
            if (!isSignup) {
                await dispatch(authActions.login(
                    formState.inputValues.email,
                    formState.inputValues.password,
                ));
            } else {
                await dispatch(authActions.signup(
                    formState.inputValues.email,
                    formState.inputValues.password,
                ));
            }
        } catch (err) {
            setError(err.message);
            setShowError(true);
            setIsLoading(false);
        }
    }, [dispatch,
        formState,
        // isSignup
    ]);

    const resetPasswordHandler = () => {
        props.navigation.navigate('PasswordReset', { email: formState.inputValues.email });
    };

    useEffect(() => {
        if (error && Platform.OS !== 'web') {
                Alert.alert('An issue has occured', error, [{ text: 'ok' }]);
            }
    }, [error]);

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'android' ? 'padding' : 'padding'}
            enabled={Platform.OS === 'android' ? false : true}
            keyboardVerticalOffset={Platform.OS === 'android' && Platform.Version >= 21 ? 0 : 50}
            style={styles.screen}
        >
            {isLoading ?

                <ActivityIndicator size='large' color={Colors.primaryColor} /> :
                
                <View style={styles.authContainer}>
                    { Platform.OS === 'web' && showError ?

                        <View style={{ alignItems: 'center' }}>
                            <DefaultText style={{ marginBottom: 10, textAlign: 'center', fontFamily: 'lato-bold', fontSize: 15 }}>Something went wrong !</DefaultText>
                            <DefaultText style={{ marginBottom: 20, marginHorizontal: 30 }}>{error}</DefaultText>
                            <TouchableItem
                                style={styles.buttonOk}
                                onSelectItem={() => { setShowError(false) }}>
                                <DefaultText
                                    style={styles.buttonText}
                                >ok</DefaultText>
                            </TouchableItem>
                        </View>

                        :

                        <ScrollView>
                            <TouchableItem style={styles.new} onSelectItem={() => { props.navigation.navigate('TaSinCh') }}>
                                <DefaultText style={styles.newText}>{"New? Get started!"}</DefaultText>
                            </TouchableItem>
                            <DefaultInput
                                id='email'
                                title="E-mail"
                                keybordType="email-address"
                                required
                                email
                                errorMessage="Please enter a valid email address"
                                onInputChange={inputChangeHandler}
                                initialValue={formState.inputValues.email}
                                initiallyValid={formState.inputValidities.email}
                            />
                            <DefaultInput
                                id='password'
                                title="Password"
                                keybordType="default"
                                secureTextEntry
                                minLength={5}
                                required
                                autoCapitalize="none"
                                errorMessage="Please enter a valid password"
                                onInputChange={inputChangeHandler}
                                initialValue={formState.inputValues.password}
                                initiallyValid={formState.inputValidities.password}
                            />
                            <TouchableItem style={styles.button} onSelectItem={authHandler}>
                                <DefaultText style={styles.buttonText}>{"Login"}</DefaultText>
                            </TouchableItem>
                            <TouchableItem style={styles.buttonSwitch} onSelectItem={() => { resetPasswordHandler() }}>
                                <DefaultText style={styles.switchText}>{"Forgot your password ?"}</DefaultText>
                            </TouchableItem>
                        </ScrollView>

                    }
                </View>
                }
        </KeyboardAvoidingView>
    );
};

export const authScreenOptions = navigationData => {
    return {
        title: "Login",
        headerLeft: () =>
                <HeaderButtons HeaderButtonComponent={HeaderButton} >
                    <Item title="Save" iconName='ios-arrow-back' onPress={() => {
                        navigationData.navigation.goBack();
                    }} />
                </HeaderButtons>
    }
};


const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        backgroundColor: Colors.backColor
    },
    // overviewContainer: {
    //     justifyContent: 'center'
    // },  
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        marginHorizontal: 30,
        justifyContent: 'center'
    },
    buttonOk: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginTop: 10,
        width: 200,
        justifyContent: 'center'
    },
    buttonSwitch: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 25,
        marginHorizontal: 30,
        // backgroundColor: Colors.buttonGreen,
        overflow: 'hidden'
    },
    authContainer: {
        width: '100%',
        maxWidth: 500,
        padding: 20,
        borderRadius: 10,
        backgroundColor: 'white'
    },
    new: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 70,
        marginBottom: 20,
        marginHorizontal: 0,
        backgroundColor: Colors.buttonGreen,
        overflow: 'hidden',
        justifyContent: 'center'
    },
    newText: {
        color: Colors.primaryColor,
        fontFamily: 'lato-bold'
    },
    gradient: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    switchText: {
        color: Colors.primaryColor,
        fontFamily: 'lato'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold'
    }
});

export default AuthScreen;