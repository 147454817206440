import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, FlatList, View, Image, Dimensions, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const MoreInAndOutScreen = props => {
    const moreInAndOutData = useSelector(state => state.relevancy.moreInAndOut);
    const [moreInAndOut, setMoreInAndOut] = useState(moreInAndOutData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setMoreInAndOut(answer);
        dispatch(relevancyActions.setMoreInOut(answer));
        if (moreInAndOutData === '') {
            if (answer === 'MoveOut') {
                props.navigation.navigate('MoveOutDate');
            } else {
                props.navigation.navigate('MoveInDate');
            }
        }
    };

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (moreInAndOut === 'MoveOut') {
            props.navigation.navigate('MoveOutDate');
        } else {
            props.navigation.navigate('MoveInDate');
        }
    };

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView ref={sView}>
                <View style={styles.questionContainer}>
                    <DefaultText
                        style={styles.questionText}
                    >
                        Tell us more, where did you move to in 2020?
</DefaultText>
                    <View style={styles.answerContainer}>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'MoveIn')}
                            style={moreInAndOut === 'MoveIn' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                style={moreInAndOut === 'MoveIn' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Moved to Switzerland
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'MoveOut')}
                            style={moreInAndOut === 'MoveOut' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={moreInAndOut === 'MoveOut' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                Moved away from Switzerland
                            </DefaultText>
                        </TouchableItem>
                        <TouchableItem
                            onSelectItem={selectionHandler.bind(this, 'MoveInAndOut')}
                            style={moreInAndOut === 'MoveInAndOut' ? styles.activeAnswer : styles.passiveAnswer}
                        >
                            <DefaultText
                                numberOfLines={1}
                                style={moreInAndOut === 'MoveInAndOut' ? styles.activeAnswerText : styles.passiveAnswerText}
                            >
                                I moved to Switzerland and out of it
                            </DefaultText>
                        </TouchableItem>
                    </View>

                    <NavContainer
                        backShow={true}
                        nextShow={moreInAndOut !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

                </View>
            </ScrollView>
        </SafeAreaView>
    );
};

export const permitScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: Platform.OS === 'web' ? 600 : 0,
        maxWidth: 800
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        padding: 10,
        height: Platform.OS === 'android' ? 200 : 140,
        minHeight: 200,
        maxHeight: 200,
        // maxHeight: Platform.OS === 'android' ? 200 : 150,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        maxHeight: 50,
        minWidth: 140,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        maxHeight: 50,
        minWidth: 140,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width > 350 ? 16 : 13,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: Dimensions.get('window').width > 350 ? 16 : 13,
        color: Colors.darkGreen,
    },
});

export default MoreInAndOutScreen;