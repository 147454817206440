import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, FlatList, View, Image, Dimensions, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import TouchableItem from '../../../components/TouchableItem';
import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const LiveAbroadFullYearScreen = props => {
    const liveAbroadFullData = useSelector(state => state.relevancy.liveAbroadFull);
    const [liveAbroadFull, setLiveAbroadFull] = useState(liveAbroadFullData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setLiveAbroadFull(answer);
        dispatch(relevancyActions.setLiveAbroadFull(answer));
        if (liveAbroadFullData === '') {
            if (answer === false) {
                props.navigation.navigate('MoreInAndOut');
            } else {
                props.navigation.navigate('CheckTaxdoneWebsite');
            }
        }
    };
    
    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (liveAbroadFull === false) {
            props.navigation.navigate('MoreInAndOut');
        } else {
            props.navigation.navigate('CheckTaxdoneWebsite');
        }
    };


    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <View style={styles.questionContainer}>
                <DefaultText
                    style={styles.questionText}>
                    Did you live outside of Switzerland the entire year of 2020?
                </DefaultText>
                <View style={styles.answerContainer}>
                    <TouchableItem 
                    onSelectItem={selectionHandler.bind(this, false)}
                    style={liveAbroadFull === false ? styles.activeAnswer : styles.passiveAnswer}
                    >
                        <DefaultText
                            style={liveAbroadFull === false ? styles.activeAnswerText : styles.passiveAnswerText}
                        >
                            No
                                </DefaultText>
                    </TouchableItem>
                    <TouchableItem 
                    onSelectItem={selectionHandler.bind(this, true)}
                    style={liveAbroadFull === true ? styles.activeAnswer : styles.passiveAnswer}
                    >
                        <DefaultText
                            style={liveAbroadFull === true ? styles.activeAnswerText : styles.passiveAnswerText}
                        >
                            Yes
                            </DefaultText>
                    </TouchableItem>
                </View>

                <NavContainer
                        backShow={true}
                        nextShow={liveAbroadFull !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

            </View>
        </ScrollView>
        </SafeAreaView>
    );
};

export const liveAbroadFullYearScreenOptions = navigationData => {
    return {
        headerLeft: () =>
                null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: Platform.OS === 'web' ? 600 : 0,
        maxWidth: 600,
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 10
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        width: Platform.OS === 'web' ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        width: Platform.OS === 'web' ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
    
});

export default LiveAbroadFullYearScreen;