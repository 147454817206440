//library "formik" in order to easily create forms

import React, { useReducer, useEffect, useRef } from 'react';
// import TextInputMask from 'react-native-text-input-mask';
import {
    View,
    StyleSheet,
    Text,
    Platform,
    Animated
} from 'react-native';

import { TextInputMask } from 'react-native-masked-text'

import DefaultText from './DefaultText';

import Colors from '../constants/Colors';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                touched: true
            }
        case INPUT_BLUR:
            return {
                ...state,
                touched: true
            }
        default:
            return state;
    }
};

const DefaultInputMaskedMoney = props => {
    
    const { onInputChange, id, focus, initialValue, initiallyValid, touched } = props;
    
    const [inputState, dispatchInputState] = useReducer(inputReducer, {
        value: initialValue ? initialValue : 0,
        isValid: initiallyValid,
        touched: touched ? touched : false
    });

    const fadeAnim = useRef(new Animated.Value(0)).current;

    //This allows to avoid re-rendering when other props change, If not dont, needs to add props. in useEffect()

    useEffect(() => {
        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
    }, [inputState, onInputChange, id]);

    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0)
    };

    const fadeIn = () => {
        // Will change fadeAnim value to 1 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: true
        }).start();
    };

    const fadeOut = () => {
        // Will change fadeAnim value to 0 in 5 seconds
        Animated.timing(fadeAnim, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true
        }).start();
    };

    useEffect(() => {
        if (inputState.value !== '') {
            fadeIn();
        } else {
            fadeOut();
        }
    }, [inputState.stringValue]);

    // library for easier validation: validate.js
    const textChangeHandler = (textRaw) => {
        const text = textRaw.replace(/\D/g, '')
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (props.required && text.trim().length === 0) {
            isValid = false;
        }
        if (props.email && !emailRegex.test(text.toLowerCase())) {
            isValid = false;
        }
        if (props.min != null && +text < props.min) {
            isValid = false;
        }
        if (props.max != null && +text > props.max) {
            isValid = false;
        }
        if (props.minLength != null && text.length < props.minLength) {
            isValid = false;
        }
        if (props.decimal && (!isNumber(text) || text === '0')) {
            isValid = false;
        }
        dispatchInputState({
            type: INPUT_CHANGE,
            value: text,
            isValid: isValid
        });
    };

    const lostFocusHandler = () => {
        // dispatchInputState({ type: INPUT_BLUR });
        // fadeOut();
    };

    const focusedOptions =
    {
        precision: 0,
        separator: ',',
        delimiter: "'",
        unit: 'CHF ',
        suffixUnit: ''
    };

    return (
        <View style={styles.inputContainer}>
            { props.isTitle === true && (
                <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>
            )}

            { inputState.value !== '' && (

                <Animated.View
                    style={[
                        styles.inputTitleContainer,
                        {
                            opacity: fadeAnim // Bind opacity to animated value
                        }
                    ]}
                        >

                    <DefaultText style={styles.inputTitle}>{props.title}</DefaultText>

                </Animated.View>

            )}
    
            <TextInputMask
                {...props}
                autoFocus={focus}
                style={Platform.OS === 'web' ? {...styles.inputWeb, outlineWidth: 0} : styles.input}
                onChangeText={textChangeHandler}
                focusable='true'
                value={inputState.value}
                onBlur={lostFocusHandler}
                placeholder={props.title}
                placeholderTextColor={Colors.darkBlue}
                returnKeyType="done"
                selectionColor={Colors.darkBlue}
                // inlineImageLeft={props.imageLeft}
                // mask={maskParams}
                // onFocus={gainFocusHandler}
                type={'money'}
                options={focusedOptions}
            />
            {!inputState.isValid && inputState.touched && (
                <View style={styles.errorContainer}>
                    <Text style={styles.errorText}>{props.errorMessage}</Text>
                </View>
            )}
        </View>
    )
};

const styles = StyleSheet.create({
    inputContainer: {
        borderRadius: 10,
        padding: 10,
        // backgroundColor: 'white',
        marginVertical: 10
    },
    inputTitle: {
        fontFamily: 'lato',
        marginBottom: 2,
        color: Colors.darkBlue
    },
    input: {
        borderBottomColor: Colors.darkBlue,
        color: Colors.darkBlue,
        width: '100%',
        minHeight: Platform.OS === 'web' ? 40 : 30,
        borderBottomWidth: 0.5,
        fontSize: Platform.OS === 'web' ? 20 : 16,
        paddingLeft: 5,
    },
    inputWeb: {
        borderBottomColor: Colors.darkBlue,
        color: Colors.darkBlue,
        width: '100%',
        minHeight: Platform.OS === 'web' ? 40 : 30,
        borderBottomWidth: 0.5,
        fontSize: Platform.OS === 'web' ? 20 : 16,
        paddingLeft: 5,
        // outlineWidth: 0
    },
    errorContainer: {
        marginVertical: 5
    },
    errorText: {
        fontFamily: 'lato',
        fontSize: 13,
        color: 'red'
    }
});

export default DefaultInputMaskedMoney;

