import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, Dimensions, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';
import TouchableItem from '../../components/TouchableItem';
import NavContainer from '../../components/NavContainer';

import * as relevancyActions from '../../store/actions/relevancy';

const TaSinChScreen = props => {
    const taSinChData = useSelector(state => state.relevancy.taSinCh);
    const [taSinCh, setTaSinCh] = useState(taSinChData);

    const dispatch = useDispatch();

    const selectionHandler = async (answer) => {
        setTaSinCh(answer);
        dispatch(relevancyActions.setTasInCh(answer));
        if (taSinChData === '') {
            if (answer === true) {
                props.navigation.navigate('ResultAndSignUp');
            } else {
                props.navigation.navigate('CheckTaxdoneWebsite');
            }
        }
    };
    
    useEffect(() => {
        props.navigation.setOptions({
            title: "Taxed At Source ?",
            headerLeft: () =>
                null
        });
    });

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const doneHandler = () => {
        if (taSinCh === true) {
            props.navigation.navigate('ResultAndSignUp');
        } else {
            props.navigation.navigate('CheckTaxdoneWebsite');
        }
    };


return (
    <SafeAreaView style={styles.screen}>
    <ScrollView ref={sView}>
        <View style={styles.questionContainer}>
            <DefaultText style={styles.questionText}>Have you or your partner been taxed at source in Switzerland in 2020?</DefaultText>
            <View style={styles.answerContainer}>
                <TouchableItem 
                    style={taSinCh === false ? styles.activeAnswer : styles.passiveAnswer}
                    onSelectItem={selectionHandler.bind(this, false)}>
                    <DefaultText style={taSinCh === false ? styles.activeAnswerText : styles.passiveAnswerText}  
                    >
                        No
                    </DefaultText>
                </TouchableItem>
                <TouchableItem 
                    style={taSinCh === true ? styles.activeAnswer : styles.passiveAnswer}
                    onSelectItem={selectionHandler.bind(this, true)}
                    >
                    <DefaultText
                    style={taSinCh === true ? styles.activeAnswerText : styles.passiveAnswerText}                     
                    >
                        Yes
                        </DefaultText>
                </TouchableItem>
            </View>

            <NavContainer
                        backShow={true}
                        nextShow={taSinCh !== ''}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

            </View>
    </ScrollView>
    </SafeAreaView>
);
};

export const taSinChScreenOptions = navigationData => {
    return {
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
        maxWidth: 800
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 10
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        // minWidth: Platform.OS === 'web' ? Math.min((Dimensions.get('window').width - 1000) / 2, 250) : (Dimensions.get('window').width - 100) / 2,
        // maxWidth: (Dimensions.get('window').width - 100) / 2,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        // minWidth: Platform.OS === 'web' ? Math.min((Dimensions.get('window').width - 1000) / 2, 250) : (Dimensions.get('window').width - 100) / 2,
        // maxWidth: (Dimensions.get('window').width - 100) / 2,
        width: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 250 : (Dimensions.get('window').width - 100) / 2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 20,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 16,
        color: Colors.darkGreen,
    },
    
});

export default TaSinChScreen;