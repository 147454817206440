import React from 'react';
import { Share, Platform } from 'react-native';
import Colors from '../../constants/Colors.js';
import TitleLogo from '../../components/TitleLogo';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';
import HeaderButton from '../../components/HeaderButton';

import * as RootNavigation from '../RootNavigation';

const onShare = async () => {
    try {
        let url = 'https://apps.apple.com/ch/app/taxdone/id1538355852?l=it';
        if ( Platform.OS !== 'ios' ) {
            url = 'https://source.taxdone.ch/'
        }

      const result = await Share.share({
        message:
            'Check out the Taxdone app and get a refund of your tax at source !',
        url: url
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  };

export const loggedInDefaultNavOptions = {
    headerStyle: {
        backgroundColor: Colors.backColor,
        // shadowColor: 'transparent'
    },
    headerTitle: () =>
        <TitleLogo />,
    headerLeft: () =>
        <HeaderButtons HeaderButtonComponent={HeaderButton} >
            <Item
                prod="feather"
                iconName='share'
                size={25}
                onPress={onShare} />
        </HeaderButtons>,
    headerRight: () =>
        <HeaderButtons HeaderButtonComponent={HeaderButton} >
            <Item
                prod="material"
                iconName='account'
                size={25}
                onPress={() => {
                    RootNavigation.navigate('MyAccount')
                }} />
        </HeaderButtons>,
    headerTitleAlign: 'center',
    headerTitleStyle: {
        fontFamily: 'lato-bold',
    },
    headerBackTitleStyle: {
        fontFamily: 'lato'
    },
    headerTintColor: Colors.darkGreen,
    headerBackAllowFontScaling: true
};

export const loggedOutDefaultNavOptions = {
    headerStyle: {
        backgroundColor: Colors.backColor,
        // shadowColor: 'transparent'
    },
    headerTitle: () =>
        <TitleLogo />,
    headerLeft: () =>
        null,
    headerTitleAlign: 'center',
    headerTitleStyle: {
        fontFamily: 'lato-bold',
    },
    headerBackTitleStyle: {
        fontFamily: 'lato'
    },
    headerTintColor: Colors.darkGreen,
    headerBackAllowFontScaling: true
};