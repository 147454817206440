import React from 'react';
import { Text, StyleSheet } from 'react-native';

import Colors from '../constants/Colors';

const DefaultText = props => {
  return <Text style={props.style}>{props.children}</Text>;
};

const styles = StyleSheet.create({
  text: {
    fontFamily: 'lato',
    color: Colors.darkBlue
  }
});

export default DefaultText;