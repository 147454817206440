import React, { useState, useReducer, useCallback, useEffect } from 'react';
import {
    StyleSheet,
    View,
    ScrollView,
    Dimensions,
    SafeAreaView,
    Platform
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import TouchableItem from '../../components/TouchableItem';
import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';

const TermsAndConditionsScreen = props => {

    const dispatch = useDispatch();

    return (
        <SafeAreaView style={styles.screen}>
            <ScrollView >
                <View style={styles.mainContainer}>
                    <DefaultText style={styles.mainTitle}>Terms and Conditions</DefaultText>
                    <DefaultText style={styles.subTitle}>What's up?</DefaultText>
                    <DefaultText style={styles.paragraphs}>Welcome to Taxdone! We are located on Minervastrasse 119, 8032 Zurich. These General Terms and Conditions (hereinafter also referred to as "T&C") apply to websites and mobile applications offered and operated by Taxdone, in particular, www.taxdone.ch (hereinafter also referred to as "Service" or "Services").</DefaultText>
                    <DefaultText style={styles.paragraphs}>The Services calculate a user’s tax burden based on the input of the user and current tax legislation. For some users, the Service estimates an expected tax refund, which is a non-binding calculation. This value may deviate from the actual refund, as these values are ultimately determined by the responsible cantonal tax office.</DefaultText>
                    <DefaultText style={styles.paragraphs}>Our Services are programmed to calculate these values accurately. However, we do not make any representation or guarantee that the tax refund or tax owed calculated by the Service is accurate nor when the tax refund will be received by the user.</DefaultText>
                    <DefaultText style={styles.paragraphs}>Should there be any deviation from the final assessment of the tax authorities, we encourage our users to reach out to us and let us know, so that we can improve our Services.</DefaultText>
                    <DefaultText style={styles.subTitle}>1. Access and participation rights</DefaultText>
                    <DefaultText style={styles.paragraphs}>All users who have reached the age of eighteen are allowed to access and participate.</DefaultText>

                    <DefaultText style={styles.subTitle}>2. Services of Taxdone</DefaultText>

                    <DefaultText style={styles.subTitle2}>
                    2a) Content of the services
</DefaultText>

                    <DefaultText style={styles.paragraphs}>
                        Taxdone offers you the opportunity to present the often complex and inscrutable world of taxation in a more transparent way and to take advantage of it for your own tax return. For this purpose, we provide a "tax calculator". However, Taxdone does not offer any consulting and/or brokerage services, in particular, no tax consulting. We provide an information platform to support you in questions of tax law and complete tax forms on your behalf, as per your inputs.
</DefaultText>

<DefaultText style={styles.subTitle2}>
                        2b) User inputs
</DefaultText>

                    <DefaultText style={styles.paragraphs}>
                        Using the Service, you input relevant data to prepare your tax return for submission to the cantonal tax authorities. You are asked to answer predetermined questions and to provide information on your personal situation. The Services uses the data you input to prepare your tax forms.
</DefaultText>

<DefaultText style={styles.subTitle2}>
                        2c) Data protection
</DefaultText>

                    <DefaultText style={styles.paragraphs}>
                        Taxdone respects data protection and has developed a detailed data protection declaration. Take the time to read our privacy policy.
</DefaultText>

<DefaultText style={styles.subTitle2}>
                        2d) Confidentiality of communication on the Services
</DefaultText>

                    <DefaultText style={styles.paragraphs}>                        
                        Content and communication posted by you on the Services are generally anonymous and constitute private and accordingly HTTPS-protected communication. However, we cannot guarantee that malware or other circumstances will not cause third parties to gain access to the information posted. The publication of any content is therefore at your own risk.
</DefaultText>

<DefaultText style={styles.subTitle2}>
                        2e) Third Party Sites, Advertisers
</DefaultText>

                    <DefaultText style={styles.paragraphs}>                        
                        Our services contain no links to websites or other services, products or applications of third parties.
</DefaultText>



                    <DefaultText style={styles.subTitle}>3. Obligations of the user</DefaultText>

                    <DefaultText style={styles.subTitle2}>
                    3a) General rules
</DefaultText>

                    <DefaultText style={styles.paragraphs}>                        
                        You undertake not to violate any applicable legal provisions and any contractual provisions when using the services of Taxdone. In particular, you commit yourself to ensure that any content you share with us does not infringe the rights of third parties, that you comply with the applicable criminal laws and youth protection provisions. You further undertake to comply with the recognized principles of data security and to observe the obligations of the data protection regulations in order to protect the data, to check any e-mails and queries sent to Taxdone for viruses with the greatest possible care and to comply with legal, official and technical regulations.
</DefaultText>

<DefaultText style={styles.subTitle2}>
                        3b) Respect for the copyright protection of contents
</DefaultText>

                    <DefaultText style={styles.paragraphs}>                        
                        The contents published on the services of Taxdone (texts, images, videos, databases, etc.) are protected by copyright. The use is permitted only for private use. Any other use, in particular, the commercial duplication, modification, distribution or storage of information or data, in particular of texts, parts of texts, image and film material, requires the express consent of Taxdone.
</DefaultText>

<DefaultText style={styles.subTitle2}>
                        3c) Fair use of the Services
</DefaultText>

                    <DefaultText style={styles.paragraphs}>
                        You agree not to engage in any of the following activities: - the use of any automated process, including but not limited to agents, robots, scripts or spiders, to access, monitor, collect, copy or otherwise transmit the Service, registration data or other data of users, whether individually or in aggregate; - test or monitor the vulnerability of the Services or violate any application or authentication mechanism available on the Services; - use any mechanism, software or other code that interferes with or is intended to interfere with the proper functioning or use of the Services; - manipulate headers or other means of identification to disguise the origin of any message sent by or through the Services; - to disguise one's identity or to accept someone else's identity.
</DefaultText>


                    <DefaultText style={styles.subTitle}>4. Liability and warranty</DefaultText>

                    <DefaultText style={styles.paragraphs}>
                        We strive for correctness but Taxdone expressly points out that the services and information offered do not represent or replace any consulting service, especially tax consulting. Taxdone assumes no liability for the correctness of the provided contents and transmitted information.
</DefaultText>


                    <DefaultText style={styles.subTitle}>5. Termination</DefaultText>

                    <DefaultText style={styles.paragraphs}>
                        The user agreement runs for an indefinite period of time. However, you can terminate the user agreement at any time with immediate effect. The cancellation can be made in writing or in text form (e.g. by e-mail or fax). Taxdone can terminate this contract properly with a notice period of 14 days in text form, in particular by e-mail.
</DefaultText>


                    <DefaultText style={styles.subTitle}>6. Final provisions</DefaultText>


                    <DefaultText style={styles.subTitle2}>
                    6a) Applicable law and jurisdiction
</DefaultText>

                    <DefaultText style={styles.paragraphs}>                        
                        The usage agreement is subject exclusively to the law of Zurich, Switzerland.
</DefaultText>

<DefaultText style={styles.subTitle2}>
                        6b) Severability clause
</DefaultText>

                    <DefaultText style={styles.paragraphs}>
                        Should individual provisions of these T&C be invalid in whole or in part, the validity of the remaining provisions or parts of such provisions shall remain unaffected. The ineffective or missing provisions shall be replaced by the respective statutory provisions.
</DefaultText>


                </View>
            </ScrollView>
        </SafeAreaView>
    );
};


export const termsAndConditionsScreenOptions = navigationData => {
    return {
        title: "Terms And Conditions",
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    loading: {
        flex: 1,
        backgroundColor: Colors.backColor,
        justifyContent: 'center'
    },
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch'
    },
    mainTitle: {
        fontSize: 16,
        fontFamily: 'lato-bold',
        textAlign: 'center',
        marginBottom: 0,
        color: Colors.darkBlue
    },
    subTitle: {
        fontSize: 14,
        fontFamily: 'lato-bold',
        marginTop: 20,
        marginBottom: 5,
        color: Colors.darkBlue
    },
    paragraphs: {
        fontSize: 12,
        marginBottom: 5,
        color: Colors.darkBlue
    },
    mainContainer: {
        justifyContent: 'space-evenly',
        // alignItems: 'center',
        padding: 10,
        // height: Dimensions.get('window').height * 0.8
        maxWidth: 600,
        minWidth: (Platform.OS === 'web' && Dimensions.get('window').width > 600) ? 600 : 0,
    },
    subTitle2: {
        fontSize: 12,
        fontFamily: 'lato',
        marginTop: 5,
        marginBottom: 0,
        color: Colors.darkBlue
    },
    infoContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    preOrderContainer: {
        height: 100,
        marginBottom: 10,
        justifyContent: 'space-evenly'
    },
    buttonNo: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginTop: 20
    },
    button: {
        alignItems: 'center',
        backgroundColor: Colors.darkGreen,
        borderRadius: 20,
        minHeight: 45,
        marginHorizontal: 30,
        marginBottom: 50
    },
    restartContainer: {
        alignItems: 'center',
        borderRadius: 20,
        minHeight: 30,
        marginHorizontal: 30,
        marginTop: 10,
        flexDirection: 'row'
    },
    buttonText: {
        color: 'white',
        fontFamily: 'lato-bold',
        textAlign: 'center',
        fontSize: 18
    },
    switchText: {
        color: Colors.darkBlue,
        fontSize: 14,
        marginLeft: 3
    },
    successText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        textAlign: 'center',
        padding: 10
    },
    successDesc1: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
    },
    successDesc: {
        fontFamily: 'lato',
        fontSize: 14,
        textAlign: 'center',
        paddingHorizontal: 20,
        paddingBottom: 20
    },
});

export default TermsAndConditionsScreen;