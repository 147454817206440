import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, FlatList, View, Image, Dimensions, ScrollView, SafeAreaView, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Colors from '../../../constants/Colors';
import DefaultText from '../../../components/DefaultText';
import DefaultDatePicker from '../../../components/DefaultDatePicker';
import DefaultInputMaskedDate from '../../../components/DefaultInputMaskedDate';
import NavContainer from '../../../components/NavContainer';

import * as relevancyActions from '../../../store/actions/relevancy';

const MoveOutDateScreen = props => {
    const moreInAndOutData = useSelector(state => state.relevancy.moreInAndOut);
    const dateOutData = useSelector(state => state.relevancy.moveOutDate);
    const dateInData = useSelector(state => state.relevancy.moveInDate);
    const [dateOut, setDateOut] = useState(dateOutData);
    const [dateOutValid, setDateOutValid] = useState(dateOutData !== '');

    useEffect(() => {
        setDateOut(dateOutData);
        setDateOutValid(dateOutData !== '');
    }, [dateOutData]);

    const dispatch = useDispatch();

    const sView = useRef();

    useEffect(() => {
        sView.current.scrollToEnd({ animated: true });
    });

    const selectionHandler = (inputId, inputValue, inputValidity) => {
        setDateOut(inputValue);
        setDateOutValid(inputValidity);
        dispatch(relevancyActions.setMoveOutDate(inputValue));
    };

    const inputChangeHandler = (inputId, inputValue, inputValidity) => {
        if ( inputValidity ) {
            setDateOut(inputValue);
            dispatch(relevancyActions.setMoveOutDate(inputValue));
        } else {
            setDateOut('');
            dispatch(relevancyActions.setMoveOutDate(''));
        }
        setDateOutValid(inputValidity);
    };

    const doneHandler = () => {
            props.navigation.navigate('ResultAndSignUp');
    };

    return (
        <SafeAreaView style={styles.screen}>
        <ScrollView ref={sView}>
            <View style={styles.questionContainer}>
                <DefaultText
                    style={styles.questionText}
                >
                    When did you move out of Switzerland?
                </DefaultText>
                <View style={styles.answerContainer}>
                { Platform.OS === 'web' ? 
                    <DefaultInputMaskedDate
                        focus={!dateOutValid ? true : false}
                        id='dateIn'
                        title="Your entry date to CH (dd/mm/2020):"
                        errorMessage="Please enter a valid date"
                        onInputChange={inputChangeHandler}
                        initialValue={new Date(dateOut)}
                        initiallyValid={dateOutValid}
                        minDate={new Date(dateInData)}
                        maxDate={new Date('2020-12-31')}
                        required
                    />
                    :        
                    <DefaultDatePicker
                        id='date'
                        title='Select a date'
                        errorMessage="Please enter a valid date"
                        onInputChange={selectionHandler}
                        initialValue={dateOutData ? new Date(dateOutData) : new Date('2020-01-02')}
                        required
                        minDate={new Date('2020-01-02')}
                        maxDate={new Date('2020-12-31')}
                    />
                    }
                </View>

                <NavContainer
                        backShow={true}
                        nextShow={dateOutValid === true}
                        nextNav={doneHandler}
                        backNav={() => { props.navigation.goBack() }}
                    />

            </View>
        </ScrollView>
        </SafeAreaView>
    );
};

export const moveOutDateScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
        alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    },
    questionContainer: {        
        padding: 10,
        minHeight: Dimensions.get('window').height * 0.87,
        justifyContent: Platform.OS === 'web' ? 'center' : 'flex-end',
        minWidth: Platform.OS === 'web' ? 600 : 0,
        maxWidth: 800
    },
    questionText: {
        fontFamily: 'lato-bold',
        color: Colors.darkBlue,
        fontSize: 20,
        marginBottom: 50,
        paddingHorizontal: 10
    },
    answerContainer: {
        justifyContent: 'space-between',
        minHeight: 115,
        padding: 10,
    },
    passiveAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        minHeight: 50,
        minWidth: 140,
        alignItems: 'center',
    },
    activeAnswer: {
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: Colors.darkGreen,
        backgroundColor: Colors.buttonGreen,
        minHeight: 50,
        minWidth: 150,
        alignItems: 'center',
    },
    logo: {
        marginBottom: '40%',
        width: 50,
        height: 50
    },
    activeAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 14,
        color: Colors.darkGreen,
    },
    passiveAnswerText: {
        fontFamily: 'lato-bold',
        fontSize: 12,
        color: Colors.darkGreen,
    },
});

export default MoveOutDateScreen;