import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    ScrollView,
    KeyboardAvoidingView,
    TouchableWithoutFeedback,
    Dimensions,
    Keyboard,
    ActivityIndicator,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { HeaderButtons, Item } from 'react-navigation-header-buttons';

import DismissKeyboard from '../../components/DismissKeyboard';
import HeaderButton from '../../components/HeaderButton';
import Colors from '../../constants/Colors';
import DefaultText from '../../components/DefaultText';
import TouchableItem from '../../components/TouchableItem';

const ThankYouScreen = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        props.navigation.setOptions({
            headerRight: () =>
                null
        });
    }, []);

    return (
        <ScrollView style={styles.screen}>
            <KeyboardAvoidingView behavior='position'>
                <DismissKeyboard>
                    <View style={styles.questionContainer}>
                        <DefaultText>THANK YOU</DefaultText>
                    </View>
                </DismissKeyboard>
            </KeyboardAvoidingView>
        </ScrollView>
    );
};

export const thankYouScreenOptions = navigationData => {
    return {
        headerLeft: () =>
            null
    }
};

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: Colors.backColor,
        padding: 10,
    },
    questionContainer: {
        padding: 10,
        flex: 1,
        minHeight: Dimensions.get('window').height * 0.8,
        justifyContent: 'center'
    },
});

export default ThankYouScreen;