import React from 'react';
import {
    TouchableOpacity,
    Platform,
    TouchableNativeFeedback,
    View,
    StyleSheet
} from 'react-native';
import TouchableItem from './TouchableItem';
import DefaultText from './DefaultText';
import DefaultIcon from './DefaultIcon';

import Colors from '../constants/Colors';

const NextButton = props => {
    return (
        <TouchableItem
            onSelectItem={props.onClick}
            style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', height: 50, width: 70 }}>
            <DefaultText style={{ color: Colors.darkBlue, fontFamily: 'lato-bold', marginRight: 10 }}
            >
                Next
                </DefaultText>
            <DefaultIcon
                name='ios-arrow-forward'
                size={30}
                color={Colors.darkBlue}
            />
        </TouchableItem>
    )
};

const styles = StyleSheet.create({
});

export default NextButton;

